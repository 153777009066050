.container.is-someone-editing {
  padding: 8px;
  border: 1px solid rgb(var(--color-sheet-accent));
  border-radius: 10px;
}

.container ::placeholder {
  color: rgb(var(--color-sheet-text));
}

.container ::-ms-input-placeholder {
  color: rgb(var(--color-sheet-text));
}

.two-columns {
  grid-column-start: 1;
  grid-column-end: -1;
}

.meta {
  height: 19px;
  margin-bottom: 5px;
  padding-left: 16px;
  position: relative;
}

.label {
  color: rgb(var(--color-sheet-text));
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toggle {
  position: absolute;
  top: 3px;
  left: -5px;
}

.editing {
  margin-top: 5px;
  padding-left: 16px;
  color: rgb(var(--color-sheet-accent));
  font-weight: bold;
}

.description {
  margin-top: 5px;
  padding-left: 16px;
}

/*
min-height based on tallest element which is currently Counter
*/
.element {
  min-height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auto-height .element {
  min-height: auto;
}

.element > * {
  width: 100%;
}
