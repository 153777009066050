.grid-container {
  grid-column-start: 1;
  grid-column-end: -1;
}

.container {
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 20px;
  position: relative;
  background: rgb(0 0 0 / 0.25);
  border: 2px solid rgb(var(--color-sheet-text) / 0.5);
  border-radius: 14px;
  transition: border-color 0.4s ease-in-out, padding-top 0.3s;
}

.draggable-container:hover .container:not(.is-collapsed),
.container:not(.is-collapsed):hover {
  padding-top: 40px;
  border-color: rgb(var(--color-sheet-text));
}

.container.is-collapsed {
  height: 44px;
  overflow: hidden;
}

.elements {
  display: grid;
  align-items: start;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 18px;
  row-gap: 24px;
}

.builder-container .elements {
  min-height: 180px;
}

.collapse-button {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 9px;
  right: 15px;
  z-index: 2;
  font-size: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.draggable-container:hover .collapse-button,
.container.is-collapsed .collapse-button,
.container:hover .collapse-button {
  opacity: 1;
}

.collapse-button svg {
  width: 100%;
  height: 100%;
}

.collapse-button path,
.collapse-button circle {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s ease-in-out;
}

.label {
  padding-left: 16px;
  padding-right: 37px;
  font-size: 14px;
  font-weight: bold;
  color: rgb(var(--color-sheet-text));
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.draggable-container {
  margin-bottom: -16px;
  position: relative;
  transition: margin-bottom 0.3s;
}

.draggable-container:not(.read-only):hover {
  margin-bottom: 24px;
}

.drag-handle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 15px;
  left: 5px;
  z-index: 12;
  color: var(--color-white);
  font-size: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.builder-container .drag-handle {
  top: 50%;
  left: -4px;
  transform: translateY(-50%);
}

.drag-handle svg {
  width: 100%;
  height: 100%;
}

.drag-handle path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.4s ease-in-out;
}

.drag-handle:hover path {
  fill: var(--color-white) !important;
}

.builder-container:hover .drag-handle,
.draggable-container:not(.read-only):hover .drag-handle,
.drag-handle:focus {
  opacity: 1;
}

.collection-group {
  position: relative;
  z-index: 11;
}

.actions {
  margin-top: 10px;
  position: relative;
  z-index: 1;
  text-align: center;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: translateY(-30px);
}

.draggable-container:not(.read-only):hover .actions {
  opacity: 1;
  transform: translateY(0);
}

.group-action-button {
  min-height: 30px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.group-action-button svg {
  height: 14px !important;
  margin-right: 5px;
}

.group-action-button path {
  fill: rgb(var(--color-sheet-text));
  transition: fill 0.15s ease-in-out;
}

.action-button + .action-button {
  margin-left: 12px;
}

.action-button svg {
  height: 16px;
}

.action-button.delete-button svg {
  height: 15px;
}

.action-button:hover path,
.action-button:focus path {
  fill: var(--color-white) !important;
}

.action-button-content {
  display: flex;
  align-items: center;
}

.builder-container {
  padding: 12px 10px;
  position: relative;
  background: rgb(0 0 0 / 0.25);
  border: 2px solid rgb(var(--color-sheet-text) / 0.5);
  border-radius: 14px;
  color: rgb(var(--color-sheet-text));
  font-size: 16px;
  font-family: var(--font-europa);
  line-height: 1;
  transition: border-color 0.4s ease-in-out;
}

.builder-container.noHover {
  pointer-events: none;
}

.element-type {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.4;
  transition: padding 0.3s, color 0.2s;
}

.builder-container:hover {
  border-color: rgb(var(--color-sheet-text));
}

.builder-container:hover .element-type,
.drag-handle:focus + .element-type {
  padding-left: 20px;
  color: var(--color-white);
}

.drag-handle:hover path,
.drag-handle:focus path,
.collapse-button:hover path,
.collapse-button:hover circle,
.settings-button:hover span:last-child path {
  fill: var(--color-white);
}

.builder-container .headerArea path,
.builder-container .headerArea circle,
.builder-container .footerArea path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.builder-container .headerArea {
  margin-bottom: 10px;
  position: relative;
}

.builder-container .headerActions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.4s ease-in-out;
}

.builder-container .headerActions > * {
  margin-left: 12px;
}

.builder-container .headerActions > *:first-child {
  margin-left: 0;
}

.builder-container:hover .headerActions {
  opacity: 1;
}

.builder-container:hover .headerActions svg,
.builder-container .settings-button span:last-child {
  width: 18px;
  height: 18px;
}

.builder-container .footerArea {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  pointer-events: none;
  justify-content: flex-end;
}

.builder-container:not(.is-collapsed) .footerArea {
  transition: opacity 0.4s ease-in-out;
}

.builder-container:not(.is-collapsed):hover .footerArea {
  opacity: 1;
  pointer-events: auto;
}

.builder-container:not(.is-collapsed).one-column.has-alignment:hover .footerArea {
  transform: translateY(-77px);
}
