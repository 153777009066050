.container {
  width: 100%;
  height: 46px;
  position: relative;
}

.background-container {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.gradient,
.background {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.gradient {
  z-index: 1;
}

.background {
  padding: 0 2px;
  z-index: 2;
  transition: opacity 0.15s;
}

.is-selected .background {
  opacity: 0.3;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 2;
}

.value,
.input {
  width: 100%;
  height: 100%;
  padding: 0 6px;
  position: relative;
  color: var(--color-white);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.input {
  background: none;
  border: none;
  outline: none;
}

.edit-button {
  position: absolute;
  top: -5px;
  right: -10px;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
}

.is-hovering .edit-button,
.is-editing .edit-button,
.content:hover + .edit-button {
  opacity: 1;
  pointer-events: auto;
}

.locked-icon {
  position: absolute;
  bottom: -5px;
  right: -10px;
}
