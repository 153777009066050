.container {
  --tablepage-video-index: 1;
  --tablepage-sheet-index: calc(var(--tablepage-video-index) + 1);
  --tablepage-diceroller-index: calc(var(--tablepage-sheet-index) + 1);
  --tablepage-sheet-offset: 45px;
  --tablepage-hide-sheet-offset: calc(var(--sheet-width) - var(--tablepage-sheet-offset));
  --tablepage-hide-sheet-avatar-offset: calc(var(--tablepage-hide-sheet-offset) - 5px);

  height: 100%;
  padding-top: calc(var(--layout-rainbowbar-height) + var(--layout-room-controls-height));
  padding-bottom: var(--layout-room-tabs-height);
  display: flex;
  overflow: hidden;
  flex-flow: column;

  &.tallContainer {
    padding: 0;
  }

  @media (--md) {
    padding-top: var(--layout-rainbowbar-height);
    padding-bottom: 0;
  }
}

.table {
  display: flex;
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.tableColumn {
  display: none;
  flex-direction: column;
  position: relative;

  &.isActive {
    display: flex;
  }

  > div {
    flex-grow: 1;
  }

  @media (--md) {
    display: flex;
  }
}

.sheet {
  max-width: var(--sheet-width);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(var(--color-theme-base));
  transition: transform 0.3s ease-in-out;

  .hideSheet & {
    @media (--md) {
      transform: translateX(calc(var(--sheet-width) * -1 + var(--sheet-collapsed-offset)));
    }
  }
}

.panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  .showPanel & {
    transform: translateX(0);
  }

  @media (--md) {
    width: var(--play-panel-width);
    left: auto;
    transform: translateX(calc(var(--play-panel-width)));
  }
}

.video {
  flex-grow: 1;
  overflow: hidden;
  z-index: var(--tablepage-video-index);

  .hideSheet & {
    @media (--md) {
      margin-left: var(--sheet-collapsed-offset);
    }
  }

  .tocOpen:not(.hideSheet) & {
    @media (--md) {
      margin-left: calc(var(--sheet-width) + var(--sheet-toc-width));
    }
  }

  .showPanel & {
    @media (--md) {
      margin-right: var(--play-panel-width);
    }
  }

  @media (--md) {
    margin-left: var(--sheet-width);
    transition: margin 0.3s ease-in-out;
  }
}

.diceRoller {
  z-index: var(--tablepage-diceroller-index);
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-dark-text);
}

.buttonContainer {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  display: flex;
  align-items: center;
}

.joinButton {
  min-width: 105px;
  margin-right: 9px;
}

.deviceButtonContainer {
  margin-right: 9px;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-light-grey);
  box-shadow: none !important;
}

.deviceButtonContainer.isCamera {
  min-width: 114px;
}

.deviceButtonContainer.isMic {
  min-width: 91px;
}

.deviceButtonContainer:first-child {
  margin-right: 18px;
}

.deviceButton {
  color: var(--color-text-light-grey) !important;

  svg {
    height: 9px;
    margin-right: 9px;
  }

  path,
  rect {
    fill: var(--color-text-light-grey);
    stroke-opacity: 0;
  }
}

.isPublishing {
  color: var(--color-dark-text) !important;

  path,
  rect {
    fill: var(--color-teal);
  }
}

.roomControls {
  padding-right: 0 !important;
  position: fixed;
  top: var(--layout-rainbowbar-height);
  left: 0;
  right: 0;
  z-index: 10 !important;

  @media (--md) {
    display: none;
  }
}

.navTabs {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(var(--color-theme-button));

  button {
    flex: 1;
  }

  @media (--md) {
    display: none;
  }
}

.navButton {
  height: var(--layout-room-tabs-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(var(--color-theme-button));
  color: rgb(var(--color-theme-text));
  font-size: 12px;

  svg {
    height: 18px;
    margin-bottom: 6px;

    * {
      fill: rgb(var(--color-theme-text));
    }
  }

  &.isActive {
    background: rgb(var(--color-theme-accent));
    color: white;

    svg * {
      fill: white;
    }
  }
}

.unreadIndicatorContainer {
  position: relative;
}

.unreadIndicator {
  width: 8px;
  height: 8px;
  position: absolute;
  right: -4px;
  top: -4px;
  background: var(--color-white);
  border-radius: 9px;
}

.isNewRoomFTE {
  display: flex;
  flex-grow: 1;
  justify-content: center;

  .roomFTEWrapper {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    flex-grow: 1;
  }
  .artOverlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0/0.4);
  }
}
