.container {
  background: var(--color-dashboard-white);

  @media (--md) {
    border-radius: 24px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  border-bottom: solid 2px var(--color-dashboard-light-grey);
  color: var(--color-dashboard-light);
  font-size: 18px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  @media (--md) {
    border-radius: 24px 24px 0 0;
    flex-direction: row;
    justify-content: space-between;
    min-height: 76px;
    padding: 20px 30px;
  }
}

.header-section {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--md) {
    flex-direction: row;
    gap: 10px;
  }
}

.header-title > *:nth-child(2) {
  margin-left: 12px;
}

.title {
  color: var(--color-dashboard-mid);
  font-family: var(--font-alphapipe);
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.subtitle {
  font-size: 18px;
}

.content {
  min-height: 76px;
  padding: 12px;

  @media (--md) {
    padding: 24px;
  }
}
