.watermark {
  align-items: center;
  bottom: 12px;
  display: none;
  left: 20px;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  transition: all 0.15s ease-in-out;
  width: 26px;
  z-index: 2;

  &:focus,
  &:hover {
    opacity: 1;
    width: 190px;
  }

  .logo {
    fill: var(--color-white);
    width: 88px;
  }

  .text {
    display: flex;
    color: var(--color-white);
    flex-direction: column;
    font-size: 10px;
    letter-spacing: 2px;
    margin-left: 12px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .version {
    color: rgb(var(--color-theme-text));
    margin-top: 4px;
    text-decoration: none !important;
  }

  @media (--md) {
    display: flex;
  }
}
