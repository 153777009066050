.createRoomForm,
.updateRoomForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;

  .label {
    margin-left: 18px;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 16px;
    color: rgb(var(--color-theme-text));
  }

  .labelContainer {
    display: inline-block;
    margin-bottom: 20px;
    width: 90%;

    @media (--md) {
      width: 275px;
    }
  }

  .inputWrapper {
    border: 0;
    height: 38px;
    border-radius: 8px;
    position: relative;

    .inputError {
      padding-right: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      display: block;
      font-size: 12px;
      font-weight: 700;
      color: var(--color-red);
      line-height: 40px;
    }

    input {
      font-size: 14px;
      color: var(--color-dark-main);
      width: 100%;
      height: 100%;
      padding: 0 20px;
      position: relative;
      z-index: 1;
      background: var(--color-white);
      border: none;
      border-radius: inherit;
      color: var(--color-dark-text);
      outline: none;
    }
  }

  .createButton {
    margin-top: 20px;
    width: 255px;
    height: 40px;
    flex: none;
    background: rgb(var(--color-theme-accent));

    .icon {
      display: inline-flex;
      svg {
        border-radius: 50%;
        padding: 4px;
        background-color: rgb(0, 0, 0, 0.2);
        height: 24px;
        width: 24px;
      }
    }
  }

  .updateRoomButton {
    margin-top: 20px;
    width: 90%;
    height: 40px;
    flex: none;

    @media (--md) {
      width: 255px;
    }
  }
}
