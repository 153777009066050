.container {
  padding: 10px 16px 9px;
  border-radius: 10px;
  background: rgb(0 0 0 / 0.3);
}

.input,
.markdown {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: inherit;
  color: var(--color-white);
  font-size: 18px;
  line-height: 1.4;
}

.input {
  background: none;
  border: none;
  outline: none;
  resize: none;
}

.markdown {
  min-height: 50px;
}

.markdown.is-empty {
  color: rgb(var(--color-sheet-text));
  filter: saturate(0.98);
}
