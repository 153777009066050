.highlight {
  color: var(--color-red);
}

.updateModalHeading {
  margin-bottom: 18px;
  font-size: 14px;
  text-align: center;
}

.updateModalList {
  margin: 0 auto 18px;
  color: var(--color-teal);
  font-weight: bold;
  text-align: center;
}

.updateModalCopy {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}
