.container {
  --tabsbar-tab-width: 120px;

  padding: 4px;
  display: inline-flex;
  position: relative;
  border-radius: 10px;
}

.container.dark,
.container.theme {
  background: rgba(0, 0, 0, 0.4);
}

.container.light {
  background: var(--color-dashboard-light-grey);
}

.background {
  width: var(--tabsbar-tab-width);
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  border-radius: 8px;
  transition: transform 0.15s ease-in-out;
}

.container.dark .background {
  background-color: var(--color-primary-main);
}

.container.light .background {
  background: var(--color-dashboard-light);
}

.container.theme .background {
  background-color: rgb(var(--color-theme-accent));
}

.container label {
  width: var(--tabsbar-tab-width);
  display: block;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 24px;
  cursor: pointer;
}

.container span {
  transition: color 0.15s ease-in-out, filter 0.15s ease-in-out;
  margin-left: 2.24px;
}

.container.dark span {
  color: var(--color-dark-accent);
}

.container.light span {
  color: var(--color-dashboard-main);
}

.container.theme span {
  color: rgb(var(--color-theme-text));
}

.container.dark label:hover span,
.container.theme label:hover span {
  color: var(--color-white);
}

.container.light label:hover input[type='radio']:not(:checked) + span {
  filter: brightness(0.7);
}

.container.dark input[type='radio']:checked + span,
.container.light input[type='radio']:checked + span,
.container.theme input[type='radio']:checked + span {
  color: var(--color-white);
}

.container input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}
