@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}

.file-input-container {
  --fileinput-gradient-index: 1;
  --fileinput-placeholderimage-index: calc(var(--fileinput-gradient-index) + 1);
  --fileinput-placeholderoverlay-index: calc(var(--fileinput-placeholderimage-index) + 1);
  --fileinput-label-index: calc(var(--fileinput-placeholderoverlay-index) + 1);
}

.input-container,
.file-input-container,
.file-container,
.radio-container,
.select-container {
  position: relative;
}

.input-container,
.file-container {
  border: 2px solid var(--color-light-grey);
}

.input-container.is-dark {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: var(--box-shadow);
}

.input-container,
.select-container {
  height: 42px;
  display: block;
  border-radius: 21px;

  &.theme {
    border-radius: 8px;
    border: 0;
    height: 34px;

    .input {
      background: rgb(0 0 0 / 0.3);
      color: rgb(var(--color-theme-text));
      padding: 0 12px 0 36px;
      transition: all 0.15s ease-in-out;

      &::placeholder {
        color: rbg(var(--color-theme-text));
      }

      &:active,
      &:focus,
      &:hover {
        background: rgb(var(--color-theme-button));
        color: rbg(var(--color-white));
      }
    }
  }
}

.input-container.is-valid,
.file-input-container.is-valid .file-container {
  border-color: var(--color-green);
}

.input-container.is-invalid,
.select-container.is-invalid .select,
.file-input-container.is-invalid .file-container {
  border-color: var(--color-red);
}

.simpleInputContainer {
  height: 48px;
  border-radius: 10px;
}

.file-input-container.has-colors .file-container {
  border: none;
}

.input {
  width: 100%;
  height: 100%;
  padding: 0 18px;
  position: relative;
  z-index: 1;
  background: var(--color-white);
  border: none;
  border-radius: inherit;
  color: var(--color-dark-text);
  font-size: 18px;
  outline: none; /* TODO: make accessible */
}

.inputLabel {
  color: var(--color-dashboard-main);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 28px;
  text-align: left;
  margin-left: 14px;
}

.simpleInput {
  font-size: 16px;
  font-weight: 700;
  background: var(--color-dashboard-white);
  color: var(--color-dashboard-main);
  border: solid 2px rgba(0, 0, 0, 0);
  border-radius: 8px;
  min-height: 44px;
  padding: 2px 14px;
}

.passwordInput {
  padding-right: 40px;
}

.simpleInput:hover,
.simpleInput:active,
.simpleInput:focus,
.simpleInput:focus-visible {
  color: var(--color-dashboard-mid);
  outline: none;
}

.passwordVisibilityToggle {
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
}

.input-container.is-dark .input {
  background: var(--color-dark-grey);
  color: var(--color-light-text);
}

.input::placeholder {
  color: var(--color-text-light-grey);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  -moz-appearance: textfield;
}

.input-error {
  padding-right: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-red);
  line-height: 40px;
}

.passwordVisibilityToggle + .input-error {
  padding-right: 43px;
}

.simpleInputContainer .input-error {
  top: -35px;
}

.input-error.is-file-error {
  padding-right: 0;
  position: static;
  text-align: center;
}

.file-input-container.is-asset-image {
  text-align: center;
}

.file-container {
  background: var(--color-white);
  border-radius: 50%;
}

.is-asset-image .file-container {
  height: 0 !important;
  opacity: 0;
}

.file-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--fileinput-gradient-index);
  border-radius: inherit;
}

.file-placeholder-image,
.file-placeholder-image-overlay,
.file-input-container.has-colors .file-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.file-placeholder-image {
  z-index: var(--fileinput-placeholderimage-index);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
}

.file-placeholder-image-overlay {
  z-index: var(--fileinput-placeholderoverlay-index);
  background: var(--color-black);
  border-radius: inherit;
  opacity: 0.6;
}

.file-label {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: var(--fileinput-label-index);
  border-radius: inherit;
  cursor: pointer;
}

.file-input-container.has-colors .file-label {
  width: auto;
  height: auto;
}

.file-input,
.file-placeholder-container,
.file-preview {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
}

.file-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
}

.file-placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
}

.file-placeholder {
  margin-bottom: 9px;
  padding: 0 9px;
  display: block;
  color: var(--color-dark-text);
  font-size: 14px;
  line-height: 1.4;
  opacity: 0.5;
}

.file-input-container.is-dark .file-placeholder {
  color: var(--color-light-text);
  opacity: 1;
}

.file-upload-icon {
  width: 7px;
  opacity: 0.5;
  transform: rotateZ(180deg);
}

.file-input-container.is-dark .file-upload-icon {
  opacity: 1;
}

.file-upload-icon path {
  fill: var(--color-dark-text);
  stroke-opacity: 0;
}

.file-input-container.is-dark .file-upload-icon path {
  fill: var(--color-light-text);
}

.file-preview {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  overflow: hidden;
}

.file-preview img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.file-preview svg {
  width: 60px;
}

.file-preview path {
  fill: var(--color-text-light-grey);
}

.file-asset-image {
  margin-bottom: 0 !important;
  display: inline-block;
  cursor: pointer;
}

.file-name {
  margin-top: 18px;
  text-align: center;
}

.radio-container {
  vertical-align: middle;
  cursor: pointer;
}

.radio {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.radio-label {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: var(--color-text-light-grey);
}

.radio-container.is-checked .radio-label {
  color: var(--color-dark-text);
}

.radio-button {
  width: 9px;
  height: 9px;
  margin-right: 9px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--color-text-light-grey);
}

.radio-container.is-checked .radio-button {
  background: var(--color-teal);
  border: none;
}

.select select:disabled {
  background: var(--color-dashboard-white);
  color: var(--color-dashboard-main);
  opacity: 0.5;
  pointer-events: none;
}

.select select:disabled + svg {
  display: none;
}

.select-arrow-icon {
  width: 12px;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-arrow-icon path {
  fill: var(--color-dark-text);
  stroke-opacity: 0;
}

.dropdownInput {
  display: block;
  position: relative;
}

.dropdownInput select {
  appearance: none;
  background: var(--color-dashboard-white);
  border: 0;
  border-radius: 8px;
  color: var(--color-dashboard-main);
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  outline: none;
  overflow: hidden !important;
  padding: 0 32px 0 12px;
  text-overflow: ellipsis;
  text-transform: capitalize;
  transition: all 0.15s ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.dropdownInput.small select {
  height: 32px;
  padding: 0 32px 0 12px;
  border-radius: 8px;
  font-size: 16px;
}

.dropdownInput.theme select {
  background: rgb(0 0 0 / 0.3);
  color: rgb(var(--color-theme-text));
}

.dropdownInput.dark select {
  background: var(--color-dark-300);
  border: none;
  color: var(--color-light-200);
}

.dropdownInput.dark .select-arrow-icon path {
  fill: var(--color-light-200);
}

.dropdownInput:active select,
.dropdownInput:focus select,
.dropdownInput:hover select {
  background: var(--color-dashboard-light-grey);
  color: var(--color-light-dark);
}

.dropdownInput.theme:active select,
.dropdownInput.theme:focus select,
.dropdownInput.theme:hover select {
  background: rgb(var(--color-theme-button));
  color: var(--color-white);
}

.dropdownInput.dark:active select,
.dropdownInput.dark:focus select,
.dropdownInput.dark:hover select {
  background: var(--color-dark-300);
  color: var(--color-light-100);
}

.dropdownInput svg {
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  width: 12px;
}

.dropdownInput.small svg {
  right: 10px;
  width: 10px;
}

.dropdownInput svg path {
  fill: var(--color-dashboard-main);
  transition: fill 0.15s ease-in-out;
}

.dropdownInput.theme svg path {
  fill: rgb(var(--color-theme-text));
  transition: fill 0.15s ease-in-out;
}

.dropdownInput:active svg path,
.dropdownInput:focus svg path,
.dropdownInput:hover svg path {
  fill: var(--color-light-dark);
}

.dropdownInput.theme:active svg path,
.dropdownInput.theme:focus svg path,
.dropdownInput.theme:hover svg path {
  fill: var(--color-white);
}

.dropdownInput.dark:active svg path,
.dropdownInput.dark:focus svg path,
.dropdownInput.dark:hover svg path {
  fill: var(--color-white);
}

.isDisabled {
  cursor: not-allowed;
}
