.content {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  gap: 24px;
  min-height: 60px;
  width: 100%;
}

.content.isLoading {
  overflow: visible;
}

.isSmall {
  flex-flow: column nowrap;
  gap: 16px;
  padding: 0 20px 20px;
}
