@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
position: fixed;
z-index: 50;
width: 384px;
height: 108px;
display: flex;
align-items: center;
justify-items: center;
bottom: 10px;
right: 10px;
animation: appear 0.75s linear;
}

.panelWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--color-dark-200);
  border-radius: 16px;
  border-width: 2px;
  border-color: rgb(0 0 0 / 0.05);
}
.title {
  font-family: bc-alphapipe, ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  color: var(--color-light-100);
  font-size: 22px;
  text-transform: uppercase;
}
.panel {
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  color: var(--color-light-200);
  font-size: 16px;
  line-height: 24px;
  strong,
  a {
    color: var(--color-light-100);
  }
}

.menu {
  display: block;
  margin-left: 4px;
  pointer-events: auto;
}

.popper {
  z-index: 9999;
}

.test {
  border: 1px solid red;
  height: 50px;
  width: 100px;
}

.closeButton {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 20;
  border-radius: 10px;
  background-color: var(--color-dark-300);
  opacity: .3;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.closeButton:hover {
  opacity: 1;
}