.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 400px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  margin-top: 36px;
  font-size: 18px;
}
