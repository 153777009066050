:root {
  --tablemediatools-container-index: 1;
  --tablemediatools-background-index: calc(var(--tablemediatools-container-index) + 1);
  --tablemediatools-gradient-index: calc(var(--tablemediatools-background-index) + 1);
  --tablemediatools-content-index: calc(var(--tablemediatools-gradient-index) + 1);
}

.container {
  background: var(--color-grey);
  border-bottom-right-radius: 63px;
  border-bottom-left-radius: 63px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--tablemediatools-container-index);
  background: linear-gradient(
    180deg,
    var(--color-red),
    var(--color-orange),
    var(--color-green),
    var(--color-teal),
    var(--color-purple),
    var(--color-pink)
  );
  content: '';
}

.background {
  position: absolute;
  top: 0px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: var(--tablemediatools-background-index);
  background: var(--color-grey);
  border-radius: inherit;
}

.gradient {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  right: 2px;
  z-index: var(--tablemediatools-gradient-index);
  background: var(--dark-panel-gradient);
  border-radius: inherit;
}

.content {
  height: 100%;
  padding: 16px 56px 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  justify-content: center;
  position: relative;
  z-index: var(--tablemediatools-content-index);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;

  span {
    font-size: 14px;
    font-weight: normal;
  }

  button > * {
    margin-bottom: 4px;
  }

  &.draw-container {
    flex-direction: row;
    .colors {
      padding-bottom: 8px;
    }
  }
}

.button-container:last-child {
  margin-bottom: 0;
}

.button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: var(--color-light-text);
  opacity: 0.2;
  transition: 0.2s;
}

.button:hover,
.button-container.is-active .button {
  opacity: 1;
}

.button svg {
  width: 23px;
  height: 23px;
  position: relative;
  z-index: 2;
}

.button path,
.button rect {
  fill: var(--color-light-text);
  stroke-opacity: 0;
}

.button-glow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -8px;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  opacity: 0;
  transition: 0.2s;
}

.button-container.is-active .button-glow,
.draw-container.is-active .button-glow {
  opacity: 1;
}

.zoom-button svg {
  width: 15px;
  height: 15px;
}

.draw-container:hover .button,
.draw-container.is-active .button,
.draw-container:hover span:not(.button-glow),
.draw-container.is-active span:not(.button-glow) {
  opacity: 1;
}

.zoom-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  svg {
    margin-top: 4px;
    margin-bottom: 8px;
  }
}

.zoom-container:hover ~ span {
  opacity: 1;
}

.zoom-container ~ span {
  opacity: 0.2;
  transition: 0.2s;
}

.colors {
  margin-left: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.color {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 0.2;
  transition: 0.2s;
}

.color:hover,
.color.is-active {
  opacity: 1;
}

.color.is-red {
  background: var(--color-red);
}

.color.is-green {
  background: var(--color-green);
}

.color.is-teal {
  background: var(--color-teal);
}

.color.is-orange {
  background: var(--color-orange);
}
