.avatar {
  margin: 0 auto 9px;
}

.name {
  margin-bottom: 18px;
  font-size: 14px;
  color: var(--color-red);
  text-align: center;
}

.form {
  width: 334px;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 18px;
  display: block;
}

.label {
  margin-bottom: 9px;
  display: block;
  font-size: 14px;
  text-align: center;
}

.buttons {
  margin-top: 18px;
  text-align: center;
}

.buttons > *:first-child {
  margin-right: 9px;
}
