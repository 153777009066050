.container {
  padding: 12px;
  display: none;
  position: relative;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
  pointer-events: none;
}

.container-enter {
  display: block;
  opacity: 0;
}

.container-enter-active {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.container-enter-done {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.container-exit {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.container-exit-active {
  display: block;
  opacity: 0;
}

.item {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  display: block;
  position: relative;
  border-radius: 6px;
  color: var(--color-dashboard-main);
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  line-height: 40px;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  transition: background-color 0.1s ease-out, color 0.05s ease-out;

  &:not(.noHover):hover,
  &:not(.noHover):focus {
    background-color: var(--color-secondary-main);
    color: var(--color-white);
  }

  &.dropdown {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
  }
}

label.item {
  padding-right: 58px;
  cursor: pointer;
}

.switch {
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.dropdownMenu {
  position: relative;
}

.dropdownButton {
  padding: 8px;
  background: rgb(var(--color-theme-button));
  border-radius: 6px;
  color: rgb(var(--color-theme-text));
  font-weight: bold;
  transition: filter 0.15s ease-in-out;

  &:hover,
  &:focus {
    filter: brightness(1.2);
  }
}

.dropdownList {
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: calc(100% + 12px);
  z-index: 9999;
  background: rgb(var(--color-theme-button));
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
}

.dropdownItem {
  width: 100%;
  height: 50px;
  padding: 0 12px;
  position: relative;
  border-radius: 6px;
  text-align: left;
  transition: background-color 0.15s ease-in-out;

  &:hover,
  &:focus {
    background-color: rgb(var(--color-theme-accent));

    .dropdownDescription {
      color: rgb(255 255 255 / 0.6);
    }
  }
}

.dropdownLabel {
  margin-bottom: 3px;
  padding-right: 30px;
  display: block;
  color: var(--color-white);
  font-size: 14px;
  font-weight: bold;
}

.dropdownDescription {
  padding-right: 30px;
  color: rgb(var(--color-theme-text));
  display: block;
  font-size: 12px;
  font-weight: bold;
  transition: color 0.15s ease-in-out;
}

.dropdownCheck {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  position: absolute;
  top: 50%;
  right: 12px;
  background: rgb(255 255 255 / 0.3);
  border-radius: 50%;
  color: var(--color-white);
  transform: translateY(-50%);

  svg {
    fill: var(--color-white);
  }
}

.portal {
  position: fixed;
  z-index: 9999;
}

.theme {
  background: rgb(var(--color-theme-base));

  .item {
    color: rgb(var(--color-theme-text));

    .switch {
      border-color: rgb(var(--color-theme-text));

      span {
        background: rgb(var(--color-theme-text));
      }

      &:global(.is-active) {
        background: rgb(var(--color-theme-accent));
        border-color: rgb(var(--color-theme-accent));
      }

      &:global(.is-active) > span {
        background: var(--color-white);
        opacity: 0.8;
      }
    }

    &:not(.noHover):active,
    &:not(.noHover):focus,
    &:not(.noHover):hover {
      background: rgb(var(--color-theme-accent));
      color: var(--color-white);
    }

    &:active,
    &:focus,
    &:hover {
      .switch {
        border-color: rgb(var(--color-theme-accent));

        span {
          background: rgb(var(--color-theme-accent)) !important;
        }

        &:global(.is-active) {
          border-color: var(--color-white);
        }

        &:global(.is-active) > span {
          background: var(--color-white) !important;
          opacity: 1;
        }
      }
    }
  }
}
