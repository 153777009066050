.sidebar-heading {
  margin-bottom: 4px;
  color: var(--color-dashboard-light);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sidebar-link {
  height: 40px;
  padding: 0 8px 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  color: var(--color-dashboard-main);
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
}

.sidebar-link:hover,
.sidebar-link:focus {
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0px 0 rgb(0 0 0 / 5%);
}

.sidebar-link.is-active {
  color: var(--color-black);
}

.sidebar-link-label,
.sidebar-link-stat,
.sidebar-link-button {
  height: 24px;
  display: block;
  line-height: 24px;
}

.sidebar-link-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-link-stat {
  padding: 0 6px;
  background: var(--color-dashboard-light);
  border-radius: 8px;
  font-size: 14px;
  color: var(--color-white);
}

.sidebar-link:hover .sidebar-link-stat,
.sidebar-link:focus .sidebar-link-stat {
  display: none;
}

.sidebar-link-button {
  padding: 0 10px;
  display: none;
  background: var(--color-main-accent);
  border-radius: 8px;
  color: var(--color-white);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  text-indent: 2px;
  text-transform: uppercase;
  transition: filter 0.15s ease-in-out;
}

.sidebar-link-button:hover {
  filter: brightness(1.1);
}

.sidebar-link:hover .sidebar-link-button,
.sidebar-link:focus .sidebar-link-button {
  display: block;
}

.content {
  padding: 0;

  @media (--md) {
    padding: 20px;
  }

  @media (--lg) {
    padding: 40px;
  }

  &.hasSidebar {
    @media (--md) {
      padding: 20px;
    }

    @media (--xl) {
      padding: 40px;
    }
  }
}

.content.has-sheet {
  @media (--md) {
    padding-left: calc(var(--sheet-width) - var(--layout-sidebar-width) + 40px);
    transition: padding-left 0.3s ease-in-out;
  }

  &.hasSidebar {
    @media (--md) {
      padding-left: calc(var(--sheet-width) - var(--layout-sidebar-width) + 20px);
    }

    @media (--xl) {
      padding-left: calc(var(--sheet-width) - var(--layout-sidebar-width) + 40px);
    }
  }
}

.content.has-sheet.is-showing-toc {
  padding-left: calc(var(--sheet-width) + var(--sheet-toc-width) - var(--layout-sidebar-width) + 40px);
}

.content > *:nth-child(2) {
  margin-top: 40px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.empty-header {
  color: var(--color-dashboard-dark);
  font-size: 26px;
  margin: 0 auto;
  text-align: center;
}

.empty-header + .empty-body {
  margin-top: 8px;
}

.empty-body {
  color: var(--color-dashboard-main);
  font-size: 22px;
  line-height: 30px;
  max-width: 480px;
  text-align: center;
}

.empty-body + .empty-cta {
  margin-top: 24px;
}

.cards {
  display: grid;
  gap: 16px;

  @media (--md) {
    grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
  }
}

.assets {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.asset {
  display: none;
}

.sheet {
  width: 100%;
  max-width: var(--sheet-width);
  position: fixed;
  top: var(--layout-header-height);
  bottom: 0;
  left: 0;
  z-index: 5;
}

.storage {
  @media (--md) {
    margin-right: 18px;
  }

  @media (--lg) {
    margin-right: 0;
  }
}

.filter-bar {
  margin-bottom: 24px;
}

.filter-assets-all .asset {
  display: block;
}

.filter-assets-apps .asset.is-app {
  display: block;
}

.filter-assets-pdfs .asset.is-pdf {
  display: block;
}

.filter-assets-maps .asset.is-map {
  display: block;
}

.filter-assets-tokens .asset.is-token {
  display: block;
}

.filter-assets-images .asset.is-image,
.filter-assets-images .asset.is-gif {
  display: block;
}

.filter-assets-audio .asset.is-audio {
  display: block;
}

.template {
  height: 48px;
  padding: 0 133px 0 18px;
  display: flex;
  align-items: center;
  position: relative;
  background: var(--color-white);
  border: 2px solid var(--color-light-grey);
  border-radius: 24px;
  color: var(--color-dark-text);
}

.template-content {
  margin-top: -4px;
}

.template-name,
.template-saved-name {
  margin-bottom: 2px;
  color: var(--color-red);
  font-size: 14px;
  font-weight: bold;
}

.template-saved-name {
  color: var(--color-teal);
}

.template-meta .red {
  color: var(--color-red);
}

.template-meta .divider {
  margin: 0 9px;
}

.template-button {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
}

.template-actions {
  margin-top: 9px;
  text-align: center;
}

.template-action-button {
  margin: 0 9px;
}

.template-action-button svg {
  width: 9px;
  height: 9px;
  margin-left: 9px;
}

.template-action-button.is-duplicate svg {
  transform: rotateZ(-90deg);
}

.template-action-button path,
.template-action-button rect {
  fill: var(--color-red);
  stroke-opacity: 0;
}

.gamesHeading {
  margin-bottom: 10px;
  color: var(--color-dashboard-mid);
  font-size: 20px;
  letter-spacing: 2.8px;
}

.gamesMsg {
  color: var(--color-dashboard-mid);
  margin: 0 auto 26px;
  max-width: 700px;
  padding: 0 20px;
  text-align: center;

  &::before {
    width: 240px;
    height: 3px;
    margin: 0 auto 30px;
    display: block;
    background: var(--color-dashboard-light-grey);
    border-radius: 2px;
    content: '';
  }

  h4 {
    text-indent: 2.8px;
  }

  p {
    font-size: 18px;
  }
}

.games {
  @media (--md) {
    margin: 26px 6px 60px;
  }
}

.gamesList {
  margin: 40px 0 60px;
}

.game {
  width: 240px;
  height: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  outline: none;
}

.game::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(180deg, var(--color-red), var(--color-purple));
  border-radius: 8px;
  opacity: 0;
  content: '';
  transition: opacity 0.3s ease-in-out;
}

.game:hover::before,
.game:focus::before {
  opacity: 1;
}

.gameArt {
  width: 90%;
  height: 80%;
  object-fit: contain;
  transition: all 0.15s ease-in-out;
}

.game:hover .gameArt,
.game:focus .gameArt {
  height: 50%;
  filter: brightness(2);
  transform: translateY(-16px);
}

.gameStartPlaying {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  bottom: 0;
  color: var(--color-white);
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 24px;
  opacity: 0;
  position: absolute;
  text-transform: uppercase;
  transition: background-color 0.15s ease-in-out, opacity 0.1s 0.15s ease-in-out, transform 0.15s ease-in-out;
  width: 160px;
  z-index: 1;
}

.gameStartPlaying:hover,
.gameStartPlaying:focus {
  background-color: rgba(0, 0, 0, 0.3);
}

.game:hover .gameStartPlaying,
.game:focus .gameStartPlaying {
  opacity: 1;
  transform: translateY(-8px);
}

.searchEmpty {
  padding: 8px;
  text-align: center;
  color: var(--color-dashboard-dark);
  font-size: 26px;
  font-weight: bold;
}

.partnerCardsContainer {
  display: grid;
  gap: 10px;
}

.partnerCards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @media (--md) {
    grid-template-columns: 1fr 1fr 1fr;

    &.isAffiliate {
      &:first-child {
        grid-template-columns: 1fr 1fr;
      }

      &:last-child {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
  }
}

.partnerCard {
  padding: 14px;
  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0px 0 rgb(0 0 0 / 5%);
  color: var(--color-light-dark);
  font-size: 16px;

  &.isShopify {
    grid-column: span 2;
  }

  h3 {
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 5px;
  }
}

.partnerCard a {
  color: var(--color-purple);
  text-decoration: none;
}

.partnerCard a:hover,
.partnerCard a:focus {
  text-decoration: underline;
}

.partnerButton {
  margin-top: 20px;
}

.shopInput {
  margin-top: 20px;
}

.shopifyContainer {
  margin-top: 20px;

  h4 {
    color: var(--color-dashboard-light);
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.shopifyContent {
  margin-top: 20px;
}

.shopifyTableInfo {
  ul,
  ol {
    line-height: 1.4;
  }

  li {
    margin: 5px 0;
  }

  button {
    margin-bottom: 16px;
  }
}

.partnerTable {
  width: 100%;

  th {
    padding: 10px;
    background: var(--color-dashboard-light);
    color: var(--color-dark-text);

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      width: 30%;
    }
  }

  tr:nth-child(even):not(:last-child) td {
    background: var(--color-dashboard-light-grey);
  }

  td {
    padding: 5px 10px;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      text-align: right;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.shopifyProductSelect {
  select {
    text-transform: none;
  }
}

.shopifyConnection {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;

  > * {
    flex: 1;
  }
}

.shopifyDiscountInput {
  max-width: 80px;
  position: relative;

  label {
    font-size: 12px;
  }

  span {
    height: 34px;
  }

  input {
    height: 30px;
    min-height: auto;
    padding-left: 10px;
    padding-right: 24px;
    text-align: right;
  }
}

.shopifyPercent {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  color: var(--color-dashboard-main);
  font-size: 16px;
  font-weight: bold;
  transform: translateY(-50%);
}

.shopifyError {
  margin-top: 20px;
  color: var(--color-red);
  font-size: 18px;
  text-align: center;
}

.shopifyInstallSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
    color: var(--color-light-dark);
    font-size: 20px;
  }

  a {
    margin-top: 30px;
    line-height: 34px;
  }
}

.affiliateStat {
  margin: 5px 0 20px;
  font-size: 48px;
  font-weight: bold;
}

.affiliateCopy {
  font-size: 10px;

  ul {
    padding-left: 20px;
  }

  li {
    line-height: 1.4;
  }
}

.affiliateError {
  margin-bottom: 30px;
  color: var(--color-red);
  font-size: 18px;
}

.createTempalteButton {
  display: none;

  @media (--lg) {
    display: block;
  }
}

.mobileAssetButton,
.mobileTableButton {
  @media (--lg) {
    display: none;
  }
}

.mobileAssetButton {
  margin-top: 10px;

  @media (--md) {
    margin-top: 0;
  }
}

.orgInfo {
  margin-bottom: 60px;
  color: var(--color-dashboard-dark);
  font-size: 18px;
  text-align: center;

  h1 {
    display: inline-block;

    &.hasLogo {
      height: 100px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      width: 100%;
    }

    &:not(.hasLogo) {
      background-image: linear-gradient(170deg, var(--color-red), var(--color-purple));
      background-clip: text;
      color: transparent;
    }
  }

  .gamesMsg {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.orgButton {
  display: inline-block;
  line-height: 34px;
  text-decoration: none;
}

.allGamesBacker {
  .allArt,
  p {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.withRedeemCode {
  font-size: 16px;

  .gamesMsg {
    padding: 0;
    text-align: left;
  }
}

.redeemInputContainer {
  width: 100%;
  max-width: var(--screen-sm);
  margin: 0 auto 40px;
}

.redeemError {
  margin: 10px auto 0;
  padding-right: 12px;
  color: var(--color-red);
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.redeemLabel {
  margin: 0 auto;
  display: block;
  color: var(--color-dashboard-dark);
  font-size: 18px;

  span {
    padding-left: 12px;
  }
}

.redeemInput {
  width: 100%;
  min-height: 44px;
  margin-top: 10px;
  padding: 2px 14px;
  display: block;
  border: 2px solid var(--color-dashboard-light-grey);
  border-radius: 8px;
  color: var(--color-dark-text);
  font-size: 16px;
  font-weight: bold;
  outline: none;
  line-height: 1.4;
  transition: all 0.15s ease-in-out;

  &::placeholder {
    color: var(--color-text-grey);
  }

  &.isValid {
    border-color: var(--color-green);
  }

  &.hasError {
    border-color: var(--color-red);
  }
}

.redeemProductList {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  color: var(--color-dark-text);
}

.redeemProduct {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.redeemProductTitle {
  margin-bottom: 20px;

  div {
    font-size: 14px;
    color: var(--color-dashboard-dark);
  }
}

.redeemButton {
  display: block;
  margin: 0 auto;
}

.redeemSuccess {
  width: 100%;
  max-width: var(--screen-sm);
  margin: 0 auto 40px;
  text-align: center;

  p {
    margin-bottom: 20px;
    color: var(--color-dashboard-main);
    font-size: 24px;
    font-weight: bold;
  }
}

.redeemStartNewButton {
  margin-left: 7px !important;
  margin-right: 7px !important;
}
