.form {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 0 auto;
}

.form p {
  margin-bottom: 18px;
}

.input-container {
  margin-bottom: 18px;
  display: block;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: 6px;
  display: block;
  color: var(--color-dashboard-mid);
  font-size: 18px;
}

.error {
  margin-bottom: 18px;
  color: var(--color-red);
}

.error a {
  color: var(--color-teal);
  font-weight: bold;
  text-decoration: none;
}

.error a:hover,
.error a:focus {
  text-decoration: underline;
}

.content {
  justify-content: center;
  padding-bottom: 12px;
}

.content, .content > * {
  font-size: 18px;
  max-width: 520px;
  line-height: 24px;
  margin: 0 auto 12px;
  text-align: center;
}

.playkit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.playkit > img {
  max-height: 98px;
  height: auto;
  width: auto;
}
