.heading {
  position: sticky;
  top: 0;
  z-index: 10;
  border-radius: 10px;
  color: var(--color-white);
  text-align: center;
}

.heading::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  background: rgb(var(--color-sheet-button));
  border-radius: inherit;
  transition: background-color 0.15s;
  content: '';
}

.heading:not(.is-dragging):hover::before {
  background-color: rgb(var(--color-sheet-accent));
}

.heading h3 {
  padding: 6px 43px;
  display: inline-block;
  font-family: var(--font-alphapipe);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.24px;
}

.sectionArrows {
  width: 14px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 9px;
  z-index: 2;
  font-size: 0;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.15s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.collapse-button svg {
  width: 100%;
  height: 100%;
}

.collapse-button path,
.collapse-button circle,
.deleteButton svg {
  fill: var(--color-white);
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.heading:not(.is-dragging):hover .sectionArrows {
  opacity: 1;
}

.collapse-button {
  width: 22px;
  height: 22px;
  z-index: 2;
  font-size: 0;
  transition: opacity 0.15s;
}

.collapse-button,
.deleteButton {
  opacity: 0;
}

.is-collapsed .collapse-button,
.heading:not(.is-dragging):hover .collapse-button,
.heading:not(.is-dragging):hover .deleteButton {
  opacity: 1;
}

.is-collapsed .heading:not(:hover) .collapse-button path {
  fill: rgb(var(--color-sheet-text));
}

.content {
  width: 100%;
  padding: 12px 10px;
  position: relative;
  z-index: 9;
}

.is-collapsed .content,
.is-dragging .content {
  height: 0;
  overflow: hidden;
}

.content-inner {
  width: 100%;
  padding: 12px 0;
  display: grid;
  align-items: flex-start;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 30px;
  row-gap: 24px;
}

.is-dragging .content-inner {
  opacity: 0;
}

.deleteButton svg {
  height: 15px;
}

.headerActions {
  display: flex;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  flex-direction: row;
}

.headerActions .deleteButton {
  margin-left: 12px;
}
