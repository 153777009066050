.background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.75);
}

.container {
  max-height: 90%;
  max-width: 800px;
  min-height: 100px;
  display: flex;
  background: var(--color-white);
  border-radius: 20px;
  color: var(--color-black);
  font-size: 16px;

  @media (--md) {
    font-size: 18px;
    min-width: 520px;
    width: 80%;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0;
  position: relative;
  border-radius: inherit;

  @media (--sm) {
    padding: 0 50px;
  }
}

.closeButton {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  transition: background 0.15s ease-in-out;
}

.closeButton:hover,
.closeButton:focus {
  background: var(--color-light-light);
}

.closeButton svg {
  height: 12px;
  position: relative;
}

.closeButton path {
  fill: var(--color-light-dark);
  stroke-opacity: 0;
}

.header {
  margin-bottom: 12px;
  padding: 16px 25px 0;
  text-align: center;

  @media (--sm) {
    margin-bottom: 25px;
    padding: 25px 25px 0;
  }
}

.header:last-child {
  margin-bottom: 0;
}

.title {
  max-width: 520px;
  margin: 0 auto;
  color: var(--color-red);
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.subtitle {
  max-width: 520px;
  margin: 12px auto 0;
  line-height: 1.4;
}

.subtitle em {
  color: var(--color-red);
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.hr {
  flex: none;
  width: 240px;
  height: 3px;
  margin: 0 auto;
  background: var(--color-light-light);
  border: none;
  border-radius: 2px;
}

.body.isScrollable::before {
  width: 100%;
  height: 12px;
  background: linear-gradient(to bottom, rgb(255 255 255), rgb(255 255 255 / 0));
  position: absolute;
  top: 3px;
  left: 0;
  content: '';
  pointer-events: none;
}

.body.isScrollable::after {
  width: 100%;
  height: 12px;
  background: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 0));
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  pointer-events: none;
}

.scrollable {
  overflow-x: auto;
  overflow-y: auto;
  padding: 25px 0;
}

.actions {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 25px 0;
}

.actions > * {
  flex: 1;
  max-width: 300px;
  min-width: 150px;
}

.linkModalInputs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.linkModalDropdown {
  height: 34px;
  flex: 1 1;
}

.linkModalDice {
  width: 50%;
}

.linkModalInput {
  width: 100%;
  height: 34px;
  padding: 0 12px;
  display: block;
  background: var(--color-dark-300);
  border: none;
  border-radius: 6px;
  color: var(--color-light-200);
  font-size: 14px;
  line-height: 34px;

  &::placeholder {
    font-size: 14px;
    color: var(--color-light-200);
    opacity: 0.5;
  }
}

.headerImage {
  margin: 25px auto 0;
  max-width: 520px;
}

/* DARK MODALS (NEW DESIGN) */

.containerDark {
  width: 100%;
  max-width: 768px;
}

.copy {
  max-width: 576px;
  margin: 0 auto 28px;
  font-size: 18px;
  color: var(--color-light-200);
  text-align: center;

  @media (--sm) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
