.container {
  --sheet-toc-index: 1;
  --sheet-sheet-index: calc(var(--sheet-toc-index) + 1);
  --sheet-toc-button-index: calc(var(--sheet-sheet-index) + 1);
  transition: transform 0.3s ease-in-out;
}

.container.is-collapsed {
  transform: translateX(calc(var(--sheet-width) * -1 + var(--sheet-collapsed-offset)));
}

.toc-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: var(--sheet-toc-button-index);
  cursor: pointer;
}

.toc {
  z-index: var(--sheet-toc-index);
}

.sheet {
  width: 100%;
  max-width: var(--sheet-width);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: var(--sheet-sheet-index);
  background: rgb(var(--color-sheet-base));
  transition: transform 0.3s ease-in-out;
}

.sheet::after {
  position: absolute;
  inset: 0;
  z-index: 1;
  background: url('../../images/Noise.png') repeat;
  mix-blend-mode: soft-light;
  opacity: 0.06;
  content: '';
}

.container:not(.is-collapsed).is-showing-toc .sheet {
  transform: translateX(var(--sheet-toc-width));
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.header {
  padding: 5px;
  position: relative;
  z-index: 2;

  @media (--md) {
    padding: 40px 31px 31px 22px;
  }

  @media (--lg) {
    padding-bottom: 16px;
  }
}

.header.ddbSheet {
  padding: 0;
}

.ddbHeader {
  margin-top: 20px;
  text-align: center;
}

.ddbHeader h2 {
  font-size: 16px;
}

.ddbHeader div {
  color: var(--color-light-main);
  width: 240px;
  margin: 0 auto;
  font-weight: bold;
}

.ddbButton {
  height: 34px;
  line-height: 34px;
  text-decoration: none;
  margin: 0 auto;
  position: relative;
  width: 245px;
}

.header-meta {
  min-height: 50px;
  padding-left: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--md) {
    min-height: 146px;
    padding-left: 151px;
  }
}

.header-meta-content {
  padding-right: 45px;

  &.headerOptions {
    padding-right: 0;
  }

  @media (--md) {
    padding-left: 16px;
    padding-right: 19px;
  }
}

.avatar {
  position: absolute !important;
  top: 7px;
  left: 50px;
  z-index: 2;
  transition: all 0.3s ease-in-out;

  @media (--md) {
    top: 40px;
    left: 22px;
  }
}

.is-collapsed .avatar {
  transform: translate(412px, -24px);
}

.name-container {
  min-height: 32px;
  padding-right: 14px;
  position: relative;
  display: flex;
  align-items: center;
}

.can-edit .name-container {
  cursor: pointer;
}

.name,
.name-input {
  color: var(--color-white);
  font-size: 20px;
  text-transform: none;
  letter-spacing: 2.8px;
}

.name {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (--md) {
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}

.name-input {
  width: 100%;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-weight: bold;
  line-height: 1.4;
  outline: none;
  resize: none;
}

.edit-button {
  width: 14px !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.is-editing-name .edit-button,
.can-edit .name-container:hover .edit-button {
  opacity: 1;
}

.dot {
  margin-right: 9px;
}

.status,
.last-updated {
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 2.24px;
}

.status {
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (--md) {
    margin: 10px 0 5px;
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}

.last-updated {
  margin-bottom: 15px;
  padding-left: 19px;
}

.publish-button {
  width: calc(100% - 10px);
  margin-left: 10px;
}

.settings,
.toggle-panel,
.template-settings {
  position: absolute !important;
  right: 12px;
}

.settings {
  top: 10px;
}

.template-settings {
  top: 48px;
}

.is-collapsed .settings {
  opacity: 0;
}

.toggle-panel {
  top: 11px;
}

.is-collapsed .toggle-panel {
  transform: translateX(-6px);
}

.is-collapsed-active {
  transform: rotate(180deg);
}

.preview-actions {
  margin: 5px 0;
  padding-left: 16px;
  padding-right: 19px;
  display: flex;
  gap: 10px;

  @media (--md) {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.header-sheets {
  --headersheet-background: rgb(var(--color-sheet-text));
  height: 16px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 18px;
  left: 61px;
  right: 59px;
  z-index: 3;
}

.header-sheet {
  max-width: 48px;
  min-width: 20px;
  height: 100%;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background: rgb(var(--color-sheet-button));
}

.header-sheet:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.header-sheets:not(.has-add) .header-sheet:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.header-sheet-button {
  width: 100%;
  height: 100%;
  display: block;
}

.header-sheet-button-background,
.header-sheet-button-gradient {
  height: 6px;
  display: block;
  position: absolute;
  top: 50%;
  left: 4px;
  right: 4px;
  border-radius: 2px;
  transform: translateY(-50%);
}

.header-sheet-button-background {
  background: rgb(var(--color-sheet-text));
  z-index: 1;
}

.header-sheet-button-gradient {
  background: var(--headersheet-background);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s;
}

.header-sheet.is-active .header-sheet-button-gradient {
  opacity: 1;
}

.header-sheet-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transform: scale(0, 0);
  transform-origin: 25px 0;
  transition: all 0.3s ease-in-out;
}

.header-sheet:hover .header-sheet-tooltip,
.header-sheet:focus .header-sheet-tooltip {
  opacity: 1;
  transform: scale(1, 1);
}

.header-sheet-tooltip-avatar {
  position: absolute;
  top: 6px;
  left: 0;
}

.header-sheet-tooltip-avatar:before {
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  left: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid rgb(var(--color-sheet-accent));
  transform: translateX(-50%);
  content: '';
}

.header-sheet-tooltip-name {
  padding: 0 10px 0 12px;
  position: absolute;
  top: 31px;
  left: 46px;
  background: rgb(var(--color-sheet-accent));
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.66px;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 24px;
  transform: translateY(-50%);
}

.header-add-sheet {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  position: relative;
  top: -2px;
}

.header-add-sheet-background {
  width: 8px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: -4px;
  z-index: 1;
  background: rgb(var(--color-sheet-button));
  transform: translateY(-50%);
}

.header-add-sheet-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background: rgb(var(--color-sheet-button));
  border-radius: 50%;
  transition: background-color 0.15s;
}

.header-add-sheet-button:hover,
.header-add-sheet-button:focus {
  background-color: rgb(var(--color-sheet-accent));
}

.header-add-sheet-button svg {
  height: 12px;
}

.header-add-sheet-button path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.header-add-sheet-button:hover path {
  fill: var(--color-white);
}

.body {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  transition: opacity 0.2s;
}

.body.ddbSheetArticle {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.blocker {
  height: 45px;
  position: absolute;
  width: 120px;
  background: #090809;
}

.hiddenBlocker {
  height: 45px;
  position: absolute;
  top: 1px;
  width: 100%;
}

.hiddenIframe {
  visibility: hidden;
}

.ddbContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  position: relative;
}

.ddbIframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ddbButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.is-collapsed .body {
  opacity: 0;
}

.sections {
  padding: 0 20px;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(0 0 0 / 0.1);
}

.sections::-webkit-scrollbar {
  width: var(--layout-scrollbar-width);
}

.sections::-webkit-scrollbar-corner {
  background: rgb(0 0 0 / 0.1);
}

.sections::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgb(0 0 0 / 0.1);
}

.sections::-webkit-scrollbar-track:hover {
  background: rgb(var(--color-sheet-button));
}

.sections::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(var(--color-sheet-text) / 0.3);
}

.sections::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-sheet-text));
}

.privacyToggle {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 12px;
}

.privacyToggle label {
  display: flex;
  align-items: center;
}

.privacyToggle label > span:first-of-type {
  margin-bottom: 0;
  margin-right: 4px;
}
