.form {
}

.input {
  margin-bottom: 16px;
}

.file-input {
  margin-bottom: 18px;
}

.file-input > div:first-child {
  margin: 0 auto;
}

.radio-input:last-child {
  margin-left: 18px;
}

.options {
  margin-bottom: 12px;
  text-align: center;
}

.options p {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1;
}

.actions {
  max-width: 290px;
  margin: 18px auto 0;
  text-align: center;
}

.actions > * {
  width: 100%;
}

.help {
  margin-bottom: 18px;
  font-size: 12px;
}

.help a {
  color: var(--color-red);
  text-decoration: none;
}

.help a:hover,
.help a:focus {
  text-decoration: underline;
}

.library {
  margin-top: 18px;
}

.library p {
  margin-bottom: 14px;
}

.library-button {
  width: 100%;
}

.library-button button {
  padding-left: 18px;
  padding-right: 18px;
}

.get-more-link {
  margin: -18px 0 18px;
  text-align: center;
}

.get-more-link a {
  color: var(--color-teal);
  font-weight: bold;
  text-decoration: none;
}

.get-more-link a:hover,
.get-more-link a:focus {
  text-decoration: underline;
}
