.container {
  --tablesheet-gradient-index: 1;
  --tablesheet-slant-index: calc(var(--tablesheet-gradient-index) + 1);
  --tablesheet-content-index: calc(var(--tablesheet-slant-index) + 1);
  --tablesheet-actions-index: calc(var(--tablesheet-content-index) + 1);

  display: flex;
  flex-direction: column;
  position: relative;
  background: rgb(var(--color-theme-base));
}

.noise {
  position: absolute;
  inset: 0;
  z-index: 2;
  background: url('../../images/Noise.png') repeat;
  mix-blend-mode: soft-light;
  opacity: 0.06;
  content: '';
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: var(--tablesheet-content-index);
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 27px;
  right: 9px;
  z-index: var(--tablesheet-actions-index);
}

.hide-sheet .actions {
  top: 48px;
}

.tableSheet {
  > div:first-of-type:not(:only-child) > div:first-of-type {
    margin-top: 48px;
  }

  > div:nth-of-type(2n) {
    margin-top: var(--layout-room-controls-height);
  }

  > div:last-child {
    @media (--md) {
      top: var(--layout-room-controls-height);
    }
  }

  > button:first-child {
    @media (--md) {
      top: calc(var(--layout-room-controls-height) + 10px);
    }
  }
}

.is-showing-sheets .actions {
  z-index: var(--tablesheet-slant-index);
}

.action-edit {
  margin-top: 2px;
  transition: transform 0.2s ease-out;
}

.hide-sheet .action-edit {
  opacity: 0;
}

.toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-indent: var(--heading-letter-spacing);
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button svg {
  width: 9px;
  height: 9px;
}

.action-button path {
  fill: var(--color-light-text);
  stroke-opacity: 0;
}

.action-button span {
  width: 0;
  display: block;
  white-space: nowrap;
}

.arrows-icon {
  transform: rotateZ(90deg);
}

.hide-sheet .arrows-icon {
  transform: rotateZ(-90deg);
}

.sheet.hide-sheet {
  transform: none;
}

.modal-avatar {
  margin: 0 auto 9px;
}

.modal-name {
  margin-bottom: 18px;
  font-size: 14px;
  color: var(--color-red);
  text-align: center;
}

.modal-actions {
  margin-top: 18px;
  text-align: center;
}

.modal-actions > *:first-child {
  margin-right: 9px;
}

.empty-container {
  padding: 54px 36px 36px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.empty-container p {
  margin-bottom: 36px;
  font-size: 16px;
}

.toggle-panel.is-collapsed {
  transform: translateX(-6px);
}

.is-collapsed-active {
  transform: rotate(180deg);
}

.hide {
  opacity: 0;
}
