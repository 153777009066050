.container {
  position: relative;
  display: inline-block;
}

.container span,
.label {
  display: block;
}

.label {
  margin-bottom: 8px;
  color: var(--color-light-text);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  opacity: 0.3;
  transition: 0.4s;
}

.theme .label {
  color: rgb(var(--color-theme-text));
  opacity: 1;
}

.is-checked .label {
  opacity: 1;
}

.theme.is-checked .label,
.theme:hover .label {
  color: var(--color-white);
}

.theme:hover .switch {
  border-color: rgb(var(--color-theme-text) / 1);
}

.theme.is-checked .switch:before,
.theme:hover.is-checked .switch:before {
  background: var(--color-white);
}

.switch-container {
  width: 38px;
  height: 22px;
  position: relative;
  /* background: var(--color-dashboard-main); */
  border-radius: 27px;
}

.theme .switch-container {
  background: rgb(var(--color-theme-button));
}

.switch,
.switch-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: inherit;
}

.switch {
  z-index: 2;
  border: 2px solid var(--color-dashboard-main);
  cursor: pointer;
  transition: all 0.15s;
}

.theme .switch {
  border-color: rgb(var(--color-theme-text) / 0.5);
}

.switch:before {
  height: 14px;
  width: 14px;
  position: absolute;
  left: 0;
  top: 2px;
  background: var(--color-dashboard-main);
  border-radius: 50%;
  transform: translateX(3px);
  transition: all 0.15s;
  content: '';
}

.theme .switch:before {
  background: rgb(var(--color-theme-text) / 0.5);
}

.theme:hover .switch:before {
  background: rgb(var(--color-theme-text) / 1);
}

.switch-gradient {
  z-index: 1;
  background: var(--switch-background);
  opacity: 0;
  transition: all 0.15s;
}

.theme .switch-gradient {
  background: rgb(var(--color-theme-accent));
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
}

.input:checked + .switch {
  border-color: transparent;
}

.input:checked + .switch + .switch-gradient {
  opacity: 1;
}

/* TODO: Update this for accessibilty */
.input:focus + .switch + .switch-gradient {
}

.input:checked + .switch:before {
  background: var(--color-white);
  transform: translateX(18px);
}
