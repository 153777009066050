.container {
  position: relative;
  border-radius: 50%;
}

.container.squircle {
  border-radius: 30%;
}

.container.rectangle {
  border-radius: 8px;
  aspect-ratio: 16/9;
}

.gradient {
  position: absolute;
  inset: 0;
  z-index: 1;
  border-radius: inherit;
}

.content {
  position: absolute;
  z-index: 2;
  border-radius: inherit;
  overflow: hidden;
}

.background,
.avatar,
.input-container,
.label,
.input,
.placeholder,
.placeholder-background,
.loading-container,
.loading-background {
  width: 100%;
  height: 100%;
  position: absolute;
}

.background {
  z-index: 1;
  background: var(--color-grey);
}

.avatar {
  z-index: 2;
  object-fit: cover;
}

.input-container {
  z-index: 3;
  opacity: 0;
  transition: opacity 0.2s;
}

.input-container:hover,
.input-container:focus {
  opacity: 1;
}

.label {
  z-index: 1;
  cursor: pointer;
}

.input {
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}

.placeholder {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-background,
.loading-background {
  z-index: 1;
  background: var(--color-black);
  border-radius: inherit;
  opacity: 0.8;
}

.placeholder-content,
.loading-content {
  position: relative;
  z-index: 2;
  text-align: center;
}

.placeholder-label {
  margin-bottom: 9px;
  padding: 0 18px;
  display: block;
  color: var(--color-light-text);
  font-size: 16px;
}

.placeholder-icon {
  height: 14px;
  display: inline-block;
}

.placeholder-icon path,
.delete-button path {
  fill: var(--color-light-text);
  stroke-opacity: 0;
}

.loading-container {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 2;
  opacity: 0.6;
  transform: translateX(-50%);
  transition: opacity 0.2s;
}

.delete-button:hover,
.delete-button:focus {
  opacity: 1;
}

.delete-button svg {
  height: 14px;
}
