.container {
  --card-front-index: 1;
  --card-back-index: calc(var(--card-front-index) + 1);
  --card-overlay-index: calc(var(--card-back-index) + 1);

  width: 90px;
  height: 126px;
  margin: 0 9px 18px;
  position: relative;
  border-radius: 9px;
  perspective: 1000px;
  cursor: pointer;
}

.is-tarot {
  height: 156px;
}

.pile-container {
  margin-right: 18px;
  display: inline-block;
  font-size: 10px;
  text-align: center;
}

.is-outline {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.is-pile {
  height: 90px;
  width: 126px;
  margin: 0 0 7px 0;
}

.is-pile.is-tarot {
  height: 90px;
  width: 156px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
  transform-style: preserve-3d;
  transition: transform 0.4s;
}

.is-flipped .card {
  transform: rotate3d(0, 1, 0, 180deg);
}

.is-rotated .card {
  transform: rotate3d(0, 0, 1, 180deg);
}

.is-flipped.is-rotated .card {
  transform: rotate3d(1, 0, 0, 180deg);
}

.is-red .card {
  color: var(--color-red);
}

.is-black .card {
  color: var(--color-black);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  backface-visibility: hidden;
}

.card-front {
  z-index: var(--card-front-index);
  background: var(--color-white);
  overflow: hidden;
}

.has-loaded .card-front {
  transform: rotateY(180deg);
}

.card-back {
  z-index: var(--card-back-index);
  background: url(../../images/cards/RoleCardBack.jpg) no-repeat center center;
  background-size: cover;
}

.is-pile .card-back {
  width: 88px;
  height: 124px;
  top: 100%;
  border-radius: 8px;
  transform: rotateZ(-90deg);
  transform-origin: 0% 0%;
}

.is-pile.is-tarot .card-back {
  width: 88px;
  height: 154px;
}

.card-back-icon {
  width: 50%;
  padding-top: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: url(../../images/RoleLogoIcon.png) no-repeat center center;
  background-size: contain;
  transform: translate(-50%, -50%);
}

.card-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: var(--card-overlay-index);
  background: rgba(0, 0, 0, 0.45);
  border-radius: inherit;
  pointer-events: none;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  position: absolute;
  font-family: var(--font-alphapipe);
  font-size: 14px;
  font-weight: bold;
}

.name.is-top {
  top: 9px;
  left: 9px;
}

.name.is-bottom {
  bottom: 9px;
  right: 9px;
}

.icons-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 22px 22px;
}

.icon {
  width: 14px;
  height: 14px;
  margin: 4px;
  display: block;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.is-red .icon path,
.is-red .icon polygon,
.is-red .icon polyline,
.is-red .icon line,
.is-red .icon rect {
  fill: var(--color-red);
  stroke: var(--color-red);
}

.is-black .icon path,
.is-black .icon polygon,
.is-black .icon polyline,
.is-black .icon line,
.is-black .icon rect {
  fill: var(--color-black);
  stroke: var(--color-black);
}

.actions {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 70%,
    rgba(0, 0, 0, 0.7) 100%
  );
  border-radius: inherit;
  font-family: var(--font-alphapipe);
  font-weight: bold;
  font-size: 8px;
  opacity: 0;
  transition: opacity 0.2s;
}

.container:hover .actions,
.container:focus .actions {
  opacity: 1;
}

.action-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.rotate-button {
  bottom: 0;
  left: 0;
}

.discard-button {
  top: 0;
  right: 0;
}
