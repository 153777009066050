.container {
  width: 100%;
  max-width: 768px;
}

.copy {
  max-width: 576px;
  margin: 0 auto 28px;
  font-size: 18px;
  color: var(--color-light-200);
  text-align: center;

  @media (--sm) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.copyExtra {
  font-size: 14px;
  opacity: 0.7;
}

.form {
  display: flex;
  gap: 32px;
  justify-content: center;
}

.inputContainer {
  width: 100%;
  max-width: 212px;
  line-height: 1;
}

.inputLabel {
  padding: 0 3px;
  margin-bottom: 9px;
  display: block;
  color: var(--color-light-200);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.44px;
}

.input {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  display: block;
  background: var(--color-dark-300);
  border: 1px solid transparent;
  border-radius: 6px;
  color: var(--color-light-200);
  font-size: 14px;
  line-height: 36px;

  &::placeholder {
    font-size: 14px;
    color: var(--color-light-200);
    opacity: 0.5;
  }

  .hasError & {
    border-color: var(--color-red);
  }
}

.inputError {
  margin-top: 9px;
  padding: 0 3px;
  display: block;
  color: var(--color-red);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.44px;
  line-height: 1.4;
}
