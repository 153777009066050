.content {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatar {
  box-shadow: none;
  transition: filter 0.15s ease-in-out;
}

.content:hover .avatar {
  filter: brightness(1.1);
}

.arrow {
  width: 30px;
  height: 30px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--color-dashboard-white);
  border-radius: 50%;
  transition: border 0.15s ease-in-out, background 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.arrow:hover {
  background: var(--color-dashboard-white);
}

.is-active .arrow {
  transform: rotate(180deg);
}

.arrow-icon {
  width: 10px;
  height: 6px;
}

.arrow-icon path {
  fill: var(--color-dashboard-main);
  stroke-opacity: 0;
}
