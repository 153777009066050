.container {
  display: flex;
}

.container.left {
  justify-content: flex-start;
}

.container.center {
  justify-content: center;
}

.container.right {
  justify-content: flex-end;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.container.fill .image {
  max-width: none;
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 0.3);
  border-radius: 10px;
}

.image-icon {
  height: 48px;
  width: 48px;
}

.image-icon path,
.trash-button path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.builder-container:hover .image-icon path,
.trash-button:hover path,
.trash-button:focus path {
  fill: var(--color-white);
}

.builder-container {
  position: relative;
}

.builder-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.trash-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 2;
  background: rgb(0 0 0 / 0.3);
  border-radius: 10px;
}

.trash-button svg {
  height: 15px;
}
