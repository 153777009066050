.wrapper {
  width: 120px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .arrowLeft,
  .arrowRight {
    height: 16px;
    width: 16px;
  }

  .arrowLeft {
    transform: rotate(90deg);
  }

  .arrowRight {
    transform: rotate(-90deg);
  }

  div {
    isolation: isolate;
    position: relative;
    display: flex;
    align-items: center;
    width: calc((32px * 3) + 4px); /* 1/2 of 8px gap + 32px * 3  */
    height: 8px;

    input[type='range'] {
      appearance: none !important;
      height: 100%;
      width: 100%;
      background-color: transparent;
      border-radius: 4px;

      &::-webkit-slider-thumb {
        appearance: none !important;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        border: 0px solid transparent;
        background-color: rgb(var(--color-theme-accent));
        margin-top: -2px;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        border: 0px solid transparent;
        background-color: rgb(var(--color-theme-accent));
        margin-top: -2px;
        cursor: pointer;
      }

      &::-webkit-slider-runnable-track {
        border-radius: 4px;
        background-color: rgb(var(--color-theme-button));
        height: 8px;
      }

      &::-moz-range-track {
        border-radius: 4px;
        background-color: rgb(var(--color-theme-button));
        height: 8px;
      }
    }

    &::before {
      background-color: rgb(var(--color-theme-accent) / 0.5);
      border-radius: 8px;
      bottom: 0;
      content: '';
      display: block;
      height: 8px;
      left: 0;
      margin-right: 4px;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: min(calc(var(--volume-width-percent) * 100%), calc(100% - 6px));
      z-index: 1;
    }
  }
}