.container {
  --assettray-gradient-index: 1;
  --assettray-content-index: calc(var(--assettray-gradient-index) + 1);
  --assettray-image-index: 1;
  --assettray-image-overlay-index: calc(var(--assettray-image-index) + 1);
  --assettray-menu-index: calc(var(--assettray-image-overlay-index) + 1);
  height: 100%;
  width: 100%;
}

.header {
  padding: 12px 16px;
  width: 100%;
  z-index: 1;
}

.heading {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .title {
    font-family: var(--font-alphapipe);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .buttons {
    display: flex;
  }
}

.filter {
  align-items: center;
  display: flex;
  flex-flow: row;
  gap: 12px;
  justify-content: space-between;
  margin-top: 6px;
  width: 100%;
}

.filter,
.search {
  display: none;
  width: 100%;

  &.active {
    display: flex;
  }
}

.select {
  width: 100%;
}

.order-button {
  flex: none;
}

.filter-button {
  border-radius: 10px 0 0 10px;
}

.searchIcon {
  color: transparent !important;
  path {
    fill: none;
    stroke: rgb(var(--color-theme-text));
    stroke-opacity: 1;
  }
}

.search-button {
  border-radius: 0 10px 10px 0;
}

.search-button,
.search {
  &:active,
  &:focus,
  &:hover,
  span {
    .searchIcon {
      path {
        fill: none !important;
        stroke: var(--color-white);
      }
    }
  }
}

.search {
  position: relative;

  .searchField {
    margin-top: 6px;
    position: relative;
    width: 100%;

    input {
      font-size: 16px;
    }
  }

  &:focus-within .searchIcon path {
    stroke: var(--color-white);
  }

  .searchIcon {
    left: 8px;
    pointer-events: none;
    position: absolute;
    top: 11px;
    z-index: 2;
  }

  .clearButton {
    position: absolute;
    right: 0;
    top: 7px;

    &.hidden {
      display: none;
    }
  }
}

.actions {
  margin: 0 20%;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 18px;
}

.upload-button {
  width: 100%;
}

.playPanelCard {
  background: rgb(var(--color-theme-button));
  color: rgb(var(--color-theme-text));
  padding: 2px;
  transition: all 0.15s ease-in-out;

  div:first-of-type {
    border-radius: 10px;
  }

  div:nth-of-type(2n) {
    div:first-of-type {
      span {
        background: rgb(0 0 0 / 0.3);
        color: rgb(var(--color-theme-text));
      }
    }
  }

  div > img {
    border-radius: inherit;
    object-fit: cover;
    object-position: top center;
    overflow: hidden;
    width: 150px;
  }

  h3 {
    color: rgb(var(--color-theme-text));
  }

  button {
    span:first-child {
      background: rgb(var(--color-theme-button));
    }
  }

  @media (hover: hover) {
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background: rgb(var(--color-theme-accent));
      box-shadow: none;
      color: var(--color-white);

      h3 {
        color: var(--color-white);
      }
    }

    button {
      &:active,
      &:focus,
      &:hover {
        span {
          &:first-child {
            background: rgb(var(--color-theme-accent));
          }

          &:last-child svg {
            path,
            ellipse,
            polygon,
            circle {
              fill: var(--color-white);
            }
          }
        }
      }
    }
  }
}

.content {
  height: 100%;
  position: relative;
  width: 100%;

  &.uploadButtonContent {
    padding-bottom: 68px;

    .assets {
      height: calc(100% - 98px);
    }
  }

  @media (--md) {
    &:after,
    &:before {
      background: linear-gradient(transparent, rgb(var(--color-theme-base)));
      bottom: 0;
      content: '';
      height: 40px;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 354px;
      z-index: 2;
    }

    &:after {
      background: url('../../images/Noise.png'), rgb(var(--color-theme-base));
      background-blend-mode: soft-light;
      mask: linear-gradient(transparent, black);
      opacity: 0.3;
      z-index: 1;
    }

    &.uploadButtonContent {
      &:after,
      &:before {
        bottom: 70px;
      }
    }
  }
}

.assets {
  align-content: start;
  display: grid;
  gap: 16px;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr;
  height: calc(100% - 48px);
  overflow: hidden auto;
  padding: 2px 16px 24px;
  position: relative;

  .empty {
    display: block;

    &.hidden {
      display: none;
    }
  }
}

.asset {
  display: none;
  max-width: 100%;
}

.asset > div > div:first-of-type {
  max-height: 150px;
}

.asset.isToken > div > div:first-of-type {
  background: var(--color-white);

  img {
    object-fit: contain;
    object-position: center;
  }
}

.asset.isAudio > div > div:first-of-type {
  background: rgb(var(--color-theme-accent) / 0.5);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-bar {
  margin-bottom: 24px;
}

.filter-assets-all .asset {
  display: block;
}

.filter-assets-game .asset.is-game {
  display: block;
}

.filter-assets-mine .asset.is-mine {
  display: block;
}

.filter-assets-others .asset.is-others {
  display: block;
}

.asset img {
  max-width: 100%;
}

.long-assets {
  display: flex;
  flex-flow: column;
  gap: 12px;

  .asset > div {
    flex-direction: row;

    > div:first-of-type {
      display: block;
      height: 100px;
      min-width: 100px;
      width: 100px;
    }

    > div:last-of-type {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      min-width: calc(100% - 100px);
    }

    h3 {
      overflow-wrap: wrap;
    }
  }
}

.searchResults {
  .asset {
    display: none;

    &.search-result {
      display: block;
    }
  }
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -35%;
  padding: 0 60px;
  position: absolute;
  top: 50%;
  z-index: 0;
}

.empty-header {
  color: rgb(var(--color-theme-text));
  font-size: 26px;
  text-align: center;
}

.empty-header + .empty-body {
  margin-top: 8px;
}

.empty-body {
  color: rgb(var(--color-theme-text));
  font-size: 22px;
  line-height: 30px;
  max-width: 440px;
  text-align: center;
}

.empty-body + .empty-cta {
  margin-top: 24px;
}

.obrButton {
  flex-shrink: 0;
}
