.container {
  position: absolute;
  z-index: var(--tableuser-menu-index);
  top: 16px;
  right: 16px;
  overflow: hidden;

  display: none;

  &.hidden {
    display: none !important;
  }

  :global(.is-small-tiles) & {
    display: inherit;
  }
}

.slidingContainer {
  --translate-amount: 100%;
  display: flex;
  transition: transform 300ms ease-in-out;

  .collapsed & {
    transform: translateX(var(--translate-amount));
  }
}

.actions {
  transition: opacity 150ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  margin-right: 4px;
  background-color: rgb(var(--color-theme-base) / 0.75);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container:hover & {
    opacity: 1;
    pointer-events: auto;
  }
}

.collapsedIcon {
  transition: opacity 150ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  .collapsed & {
    opacity: 1;
    pointer-events: auto;
  }
}

.content {
  background-color: rgb(var(--color-theme-base) / 0.75);
  border-radius: 14px;
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 700;

  padding: 6px 8px;
  gap: 6px;

  :global(.is-large-tiles) & {
    padding: 8px;
    font-size: 18px;
    gap: 8px;
  }
}

.equation {
  display: flex;
  align-items: center;
  justif-content: center;

  gap: 6px;

  :global(.is-large-tiles) & {
    gap: 8px;
  }
}

.die,
.target,
.buff {
  display: flex;
  align-items: center;
  text-align: center;
}

.die {
  --die-color: rgb(var(--color-theme-text));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dieIcon {
  height: 16px;
  width: 16px;
  color: var(--die-color);

  path {
    fill: var(--die-color);
  }

  :global(.is-medium-tiles) & {
    height: 18px;
    width: 18px;
  }

  :global(.is-large-tiles) & {
    height: 20px;
    width: 20px;
  }
}

.total {
  display: none;
  position: relative;
  align-items: center;
  padding: 1.5px;

  .showTotal & {
    display: flex;
  }

  .value {
    position: relative;
    text-align: center;
    background-color: rgb(var(--color-theme-base) / 0.9);
    border-radius: 6.5px; /* 8px border radius background - 1.5px padding */
    min-width: 3ch;
  }

  .gradientBorder {
    position: absolute;
    inset: 0;
    border-radius: 8px;
  }
}

.target .value {
  border-radius: 6px;
  background-color: var(--color-text-grey);
  min-width: 3ch;
}

.total .value,
.target .value {
  padding: 2px 4px;

  :global(.is-medium-tiles) & {
    padding: 3px 6px;
  }

  :global(.is-large-tiles) & {
    padding: 4px 8px;
  }
}

.operator,
.vs {
  color: var(--color-text-grey);
  padding-bottom: 2px;

  &.vs {
    margin-left: 6px;
  }

  :global(.is-large-tiles) &.vs {
    margin-left: 8px;
  }
}
