.container {
  height: 100%;
  padding-top: var(--layout-header-height);
  display: flex;
  flex-flow: column;
  background: var(--color-dark-dark);
}

.content {
  flex-grow: 1;
  position: relative;
  z-index: 11;
}

.template {
  width: 100%;
  max-width: var(--sheet-width);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.builder-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--sheet-width);
  background: var(--color-dark-dark);
  transition: left 0.3s ease-in-out;
  /* create a stacking context for this container */
  isolation: isolate;
}

.is-toc-open .builder-container {
  left: calc(var(--sheet-toc-width) + var(--sheet-width));
}

.builder,
.library {
  width: calc(var(--sheet-builder-width) + 77px);
  padding-top: 26px;
  padding-bottom: 26px;
  position: absolute;
  top: 0;
  bottom: 0;
  background: var(--color-dark-dark);
  overflow-y: auto;
}

.builder {
  padding-left: 67px;
  padding-right: 10px;
  left: 0;
}

.library {
  padding-left: 57px;
  padding-right: 20px;
  left: calc(var(--sheet-builder-width) + 77px);
}

.add-section-button {
  width: 100%;
  margin-bottom: 20px;
}

.add-section-button:last-child {
  margin-bottom: 0;
}

.modalPrivacyMessage {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 650px;
}

.modalPrivacyMessage label {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modalPrivacyMessage label span:first-child {
  color: var(--color-dark-grey);
  margin-right: -3px;
}
