.form {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.inputContainer {
  margin-bottom: 18px;
  display: block;
}

.label {
  margin-bottom: 14px;
  display: block;
  font-size: 14px;
  text-align: center;
}

.title {
  margin-bottom: 7px;
  color: var(--color-red);
  font-size: 14px;
}

.playkit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.playkit > img {
  max-height: 80px;
  height: auto;
  width: auto;
}

.description {
  margin-bottom: 18px;
}

.description strong {
  color: var(--color-red);
}
