.edit-button,
.locked-icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  color: var(--color-white);
  border-radius: 50%;
  border: 2px solid rgb(var(--color-sheet-button));
}

.edit-button svg,
.locked-icon svg {
  width: 14px;
  height: 14px;
}

.edit-button path,
.locked-icon path,
.locked-icon rect {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.2s;
}

.edit-button:hover path {
  fill: var(--color-white);
}

/* Spacer Styles */

.spacerContainer {
  overflow: hidden;
  width: 125px;
  height: 70px;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0.2) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-size: cover;
  opacity: 0.5;
}

.spacerContainer.clock {
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0.3) 95%,
    rgba(255, 255, 255, 0) 100%
  );
}

.spacerContainer.pool {
  mask-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0.3) 95%,
    rgba(255, 255, 255, 0) 100%
  );
}

.spacerMask {
  width: 354px;
  height: 70px;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 15%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0.2) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-size: cover;
}

.clock .spacerMask {
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 70%
  );
}

.pool .spacerMask {
  mask-image: radial-gradient(ellipse at left, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 80%);
}

.spacer {
  width: 100%;
  height: 100%;
}

.spacer:before {
  content: '';
  width: 354px;
  height: 30px;
  position: absolute;
  z-index: 1;
}

.buff .spacer {
  mask-image: repeating-linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1) 2px,
    rgba(0, 0, 0, 0) 2px,
    rgba(0, 0, 0, 0) 5px
  );
}
.buff .spacer:before {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-top: 20px;
  opacity: 0.8;
}
.buff .spacer:after {
  content: '';
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 354px;
  height: 6px;
  position: absolute;
  margin-top: 32px;
  z-index: 1;
}

.clock .spacer {
  mask-image: repeating-linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1) 2px,
    rgba(0, 0, 0, 0) 2px,
    rgba(0, 0, 0, 0) 5px
  );
}
.clock .spacer:before {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.pool .spacer {
  mask-image: repeating-linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1) 2px,
    rgba(0, 0, 0, 0) 2px,
    rgba(0, 0, 0, 0) 5px
  );
}

.pool .spacer:before {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
