.header {
  margin-bottom: 9px;
}

.header.is-shuffling {
  text-align: center;
}

.select-container {
  margin-right: 18px;
  display: inline-block;
}

.select-label {
  margin-bottom: 9px;
  display: block;
  color: var(--color-light-text);
  font-size: 10px;
  opacity: 0.3;
}

.select-input {
  width: 126px;
  height: 18px;
  padding: 0 18px 0 9px;
  position: relative;
  background: var(--color-dark-grey) url(../../images/DownArrow.png) no-repeat;
  background-position: right 9px center;
  background-size: 7px 4px;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var(--box-shadow);
  color: var(--color-light-text);
  font-size: 10px;
  font-weight: bold;
  appearance: none;
  outline: none;
}

.piles {
  margin-bottom: 18px;
  padding-top: 3px;
}

.cards {
  max-height: 207px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.shuffle-content {
  padding-top: 9px;
  text-align: center;
}

.shuffle-heading {
  margin-bottom: 18px;
}

.shuffle-label {
  margin-right: 18px;
  display: inline-block;
  font-size: 12px;
}

.shuffle-label:last-child {
  margin-right: 0;
}

.shuffle-label input,
.shuffle-label span {
  display: inline-block;
  vertical-align: middle;
}

.shuffle-label input {
  margin-right: 9px;
}

.shuffle-description {
  margin-top: 36px;
}

.actions {
  margin-top: 18px;
  text-align: center;
}

.actions > * {
  margin: 0 5px;
}

.modalHeader {
  text-align: center;
  margin: 24px 0;
}

.modalTitle {
  max-width: 520px;
  margin: 0 auto;
  color: var(--color-red);
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.modalContent {
  min-height: 400px;
  max-height: 800px;
  margin: 0 -20px;
  padding: 24px 16px;
  border-top: solid 2px var(--color-dashboard-light-grey);
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: var(--color-dashboard-mid);
  overflow-y: auto;

  @media (--md) {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
