.container {
  text-align: center;
}

.user {
  margin: 0 auto 18px;
  white-space: nowrap;
}

.user span,
.user-dot {
  vertical-align: middle;
}

.user span {
  font-size: 18px;
  font-weight: bold;
}

.user-dot {
  width: 18px;
  height: 18px;
  margin-right: 9px;
}

.assets {
  margin-top: 9px;
}

.assets li {
  margin-bottom: 9px;
  font-size: 12px;
}

.buttons {
  margin-top: 18px;
  text-align: center;
}

.buttons > *:first-child {
  margin-right: 9px;
}
