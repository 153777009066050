.container {
  margin: -8px 0 -10px;
  display: flex;
  flex: 1;
  justify-content: center;
}

.container hr {
  height: 3px;
  flex-grow: 1;
  background: rgb(var(--color-sheet-button));
  border-radius: 2px;
  border: none;
  text-align: center;
  overflow: visible;
}
