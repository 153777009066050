.container {
  width: var(--sheet-toc-width);
  padding-bottom: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.background {
  position: fixed;
  inset: 0;
  z-index: 0;
  background: rgb(var(--color-sheet-base));
  filter: brightness(0.7);
  content: '';
  margin-top: var(--layout-header-height);
  width: var(--sheet-toc-width);
}

.meta {
  padding: 60px 18px 18px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.heading {
  margin-bottom: 8px;
  font-family: var(--font-alphapipe);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
}

.author {
  color: rgb(var(--color-sheet-text));
  font-size: 12px;
}

.nav {
  margin-top: 1px;
  position: relative;
  z-index: 1;
}

.nav-item {
  position: relative;
}

.is-dragging {
  opacity: 0;
}

.nav-button {
  width: 100%;
  padding: 10px 14px 10px 36px;
  display: block;
  border-left: 4px solid transparent;
  color: rgb(var(--color-sheet-text));
  font-size: 17px;
  text-align: left;
  line-height: 22px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.container:not(.is-dragging) .nav-button:hover,
.container:not(.is-dragging) .nav-button:focus,
.container:not(.is-dragging) .nav-item:hover .nav-button,
.container:not(.is-dragging) .nav-item:focus .nav-button,
.is-active .nav-button {
  background-color: rgb(var(--color-sheet-button));
  border-color: rgb(var(--color-sheet-accent));
  color: var(--color-white);
}

.drag-handle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 14px;
  z-index: 2;
  color: var(--color-white);
  font-size: 0;
  opacity: 0;
  transform: translate(0, -50%);
  transition: all 0.15s;
}

.drag-handle svg {
  width: 100%;
  height: 100%;
}

.drag-handle path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.drag-handle:hover path {
  fill: var(--color-white);
}

.drag-handle:hover,
.drag-handle:focus,
.container:not(.is-dragging) .nav-item:hover .drag-handle,
.container:not(.is-dragging) .nav-item:focus .drag-handle {
  opacity: 1;
}

:global(.is-touching) .drag-handle,
.read-only .drag-handle {
  display: none;
}
