:root {
  --tablemedia-content-index: 1;
  --tablemedia-buttons-index: calc(var(--tablemedia-content-index) + 1);
  --tablemedia-buttonbg-index: 0;
  --tablemedia-buttonfg-index: calc(var(--tablemedia-buttonbg-index) + 1);
}

.container {
  position: absolute;
  inset: 0;
  background-color: var(--color-black);
  user-select: none;

  &.isObr {
    top: 8px;
    bottom: 64px;
    left: 8px;
    right: 8px;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
    overflow: hidden;

    &.isFocusMode {
      left: 8px;
    }

    &.isDisconnected {
      left: 8px;
      bottom: 180px;
    }

    @media (--md) {
      left: 153px;
      right: 82px;
    }
  }
}

.content {
  position: absolute;
  inset: 0;
  z-index: var(--tablemedia-content-index);
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* ========================================
 BUTTONS & TOOLS
======================================== */

.buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  padding: 16px;
  margin-bottom: 56px;
  z-index: var(--tablemedia-buttons-index);
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;

  &.lobbyOffset {
    bottom: 48px;

    &.lobbyMusicOffset {
      bottom: 80px;
    }
  }

  button {
    pointer-events: auto;
  }
}

.tools {
  z-index: var(--tablemedia-buttons-index);
}

/* ========================================
 MEDIA PREVIEW
======================================== */

.preview-container {
  .buttons {
    opacity: 0;
    left: 0;
    top: 0;
    align-items: center;
    padding: 8px;
    margin-bottom: 0;

    .closeButton {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  &:hover .buttons {
    opacity: 0.75;
  }
}

.thumbnail > img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.previewLabel {
  padding: 6px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: calc(var(--tableuser-tile-index) + 1);
  background: var(--color-black);
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: bold;
}

.maximizeButton {
  position: absolute;
  bottom: 12px;
  right: 12px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
  transition: filter 0.15s ease-out;

  &:hover span:first-child {
    filter: brightness(1.1);
  }
}

/* ========================================
 MEDIA APP
======================================== */

iframe {
  border: none;
}
