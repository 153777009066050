.container {
  width: 100%;
  height: 42px;
  position: relative;
}

.select {
  width: 100%;
  height: 100%;
  padding: 0 45px 0 16px;
  background: rgb(0 0 0 / 0.3);
  border: none;
  border-radius: 10px;
  color: var(--color-white);
  font-size: 18px;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.container:hover .select {
  background: rgb(var(--color-sheet-button));
}

.select-arrow-icon {
  width: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-arrow-icon path {
  fill: rgb(var(--color-sheet-text));
  stroke-opacity: 0;
  transition: fill 0.15s ease-in-out;
}

.container:hover .select-arrow-icon path {
  fill: var(--color-white);
}

.builder-dropdown {
  margin-bottom: 12px;
}

.builder-input {
  width: 100%;
  padding: 10px 16px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 10px;
  color: var(--color-white);
  font-size: 18px;
  line-height: 1.4;
  outline: none;
  resize: none;
}
