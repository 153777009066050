.container {
  width: 100%;
  padding: 5px 12px 10px;
  position: relative;
  background: rgb(var(--color-theme-button) / 0.3);
  border: 2px solid rgb(var(--color-theme-button) / 0.3);
  border-radius: 10px;
  color: rgb(var(--color-theme-text));
  font-size: 16px;
  font-family: var(--font-europa);
  line-height: 1;
  transition: border-color 0.4s ease-in-out;
}

.container.noHover {
  pointer-events: none;
}

.container.two-columns {
  grid-column-start: 1;
  grid-column-end: -1;
}

.container.is-divider {
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.container:hover {
  border-color: rgb(var(--color-theme-text));
}

.container ::placeholder {
  color: rgb(var(--color-theme-text));
}

.container ::-ms-input-placeholder {
  color: rgb(var(--color-theme-text));
}

.content {
  transition: padding-bottom 0.3s;
}

.container:hover .content {
  padding-bottom: 42px;
}

.container.one-column.has-alignment:hover .content {
  padding-bottom: 77px;
}

.container.is-collapsed {
  height: 40px;
  overflow: hidden;
}

/*
min-height based on tallest element which is currently Counter
*/
.element {
  min-height: 76px;
  display: flex;
  align-items: center;
}

.auto-height .element {
  min-height: auto;
}

.element > * {
  width: 100%;
}

.divider-element {
  flex: 1;
  transition: padding 0.3s;
}

.container:hover .divider-element {
  padding-right: 22px;
}

.divider-element > * {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.header path,
.header circle,
.footer path {
  fill: rgb(var(--color-theme-text));
  stroke-opacity: 0;
  transition: fill 0.15s;
}

.header {
  margin-bottom: 10px;
  position: relative;
}

.is-divider .header {
  margin-bottom: 0;
  margin-right: 10px;
}

.element-type {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.4;
  transition: padding 0.3s, color 0.15s;
}

.container:hover .element-type,
.drag-handle:focus + .element-type {
  padding-left: 16px;
  color: var(--color-white);
}

.drag-handle:hover path,
.drag-handle:focus path,
.collapse-button:hover path,
.collapse-button:hover circle,
.settings-button:hover span:last-child path,
.action-button:hover path,
.alignment-button.is-active path,
.one-column .column-button:last-child path,
.two-columns .column-button:first-child path {
  fill: var(--color-white);
}

.drag-handle {
  position: absolute;
  top: 50%;
  left: -4px;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.4s ease-in-out;
}

.drag-handle svg {
  width: 14px;
  width: 14px;
}

.container:hover .drag-handle,
.drag-handle:focus {
  opacity: 1;
}

.header-actions {
  display: flex;
  align-items: center;
  height: 25px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.header-actions > * {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.header-actions > * {
  margin-left: 12px;
}

.header-actions > *:first-child {
  margin-left: 0;
}

.container:hover .header-actions > * {
  opacity: 1;
}

.collapse-button svg {
  width: 22px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left: 12px;
  right: 12px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-40px);
}

.container:not(.is-collapsed) .footer {
  transition: opacity ease-in-out 0.2s;
}

.container:not(.is-collapsed):hover .footer {
  opacity: 1;
  pointer-events: auto;
}

.container:not(.is-collapsed).one-column.has-alignment:hover .footer {
  transform: translateY(-77px);
}

.actions {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: rgb(0 0 0 / 0.3);
  border-radius: 10px;
}

.alignment {
  width: 105px;
}

.alignment.has-justify {
  width: 140px;
}

.columns {
  width: 60px;
}

.other,
.single {
  width: 30px;
}

.alignment,
.other {
  margin-right: 4px;
}

.one-column .alignment {
  margin-bottom: 7px;
}

.spacer {
  flex: 1;
}

.action-button {
  height: 100%;
  padding-top: 2px;
}

.alignment-button svg {
  width: 18px;
}

.column-button svg {
  height: 14px;
}

.clone-button svg {
  height: 16px;
}

.delete-button svg {
  height: 15px;
}

.remove-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  background: rgb(var(--color-theme-text));
  border-radius: 50%;
  opacity: 0;
  transition: opacity ease-in-out 0.2s, background-color ease-in-out 0.2s;
}

.remove-button:hover {
  background-color: rgb(var(--color-theme-accent));
}

.container:hover .remove-button {
  opacity: 1;
}

.remove-button svg {
  height: 12px;
}

.remove-button path {
  fill: var(--color-white);
  stroke-opacity: 0;
}

.toggle-and-label-container,
.description-container {
  padding-left: 14px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.toggle-and-label-container {
  margin-bottom: 8px;
  position: relative;
}

.description-container {
  margin-top: 8px;
}

.container:hover .toggle-and-label-container,
.toggle-and-label-container.has-label,
.container:hover .description-container,
.description-container.has-description {
  opacity: 1;
}

.is-editing-label .toggle-and-label-container,
.is-editing-description .description-container {
  width: 100%;
}

.label-input-container,
.description-input-container {
  min-height: 18px;
  padding-right: 24px;
  position: relative;
}

.label-input-container {
  overflow: hidden;
}

.edit-label-button,
.edit-description-button {
  position: absolute;
  top: -4px;
  right: 0;
  background: none !important;
  border: none !important;
}

.markdown-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.label-input-container,
.label-input,
.add-label-button {
  color: rgb(var(--color-theme-text));
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 1.4;
  transition: color 0.2s ease-in-out;
}

.is-editing-label .label-input,
.toggle-and-label-container:hover .label-input-container,
.toggle-and-label-container:hover .label-input,
.toggle-and-label-container:hover .add-label-button {
  color: var(--color-white);
}

.description-input-container,
.description-input,
.add-description-button {
  color: rgb(var(--color-theme-text));
  font-size: 14px;
  line-height: 1.4;
  transition: color 0.2s ease-in-out;
}

.is-editing-description .description-input,
.description-container:hover .description-input-container,
.description-container:hover .description-input,
.description-container:hover .add-description-button,
.description-container:hover .description-markdown {
  color: var(--color-white);
}

.label-input-container svg,
.add-label-button svg,
.description-input-container svg,
.add-description-button svg {
  width: 14px;
  margin-left: 10px;
}

.markdown-icon {
  width: auto !important;
  height: 12px;
}

.label-input-container path,
.add-label-button path,
.description-input-container path,
.add-description-button path,
.markdown-icon path {
  fill: rgb(var(--color-theme-text));
  stroke-opacity: 0;
  transition: fill 0.2s ease-in-out;
}

.is-editing-label .label-input-container path,
.toggle-and-label-container:hover .label-input-container path,
.toggle-and-label-container:hover .add-label-button path,
.is-editing-description .description-container path,
.description-container:hover path {
  fill: var(--color-white);
}

.label-input,
.description-input {
  width: 100%;
  padding: 0;
  display: block;
  background: none;
  border: none;
  outline: none;
  resize: none;
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.label,
.description {
  cursor: pointer;
}

.description {
  word-break: break-word;
}

.add-label-button,
.add-description-button {
  width: 100%;
  display: flex;
  align-items: center;
}

.add-label-button svg,
.add-description-button svg {
  margin-left: auto;
}

.linkedIcon {
  height: 26px;
  width: 30px;
  margin-right: -24px;
  padding: 4px 6px;
  background: rgb(var(--color-theme-button));
  border: 2px solid rgb(var(--color-theme-text) / 0.3);
  border-radius: 10px;
  opacity: 1 !important;
}

.iconToggle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 3px;
  left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-black);
  border: 2px solid rgb(var(--color-sheet-text) / 0.5);
  border-radius: 50%;
}

.iconToggle::after {
  width: 6px;
  height: 6px;
  background: rgb(var(--color-sheet-button));
  border-radius: 50%;
  content: '';
}

.iconToggle.isText {
  top: 15px;
}
