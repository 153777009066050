.network-quality {
  height: 14px;
  display: flex;
  align-items: flex-end;
}

.network-quality-bar {
  width: 3px;
  margin-right: 2px;
  background-color: var(--color-dark-accent);
  border-radius: 2px;
}

.network-quality-bar:nth-child(1) {
  height: 6px;
}

.network-quality-bar:nth-child(2) {
  height: 8px;
}

.network-quality-bar:nth-child(3) {
  height: 10px;
}

.network-quality-bar:nth-child(4) {
  height: 12px;
}

.network-quality-bar:nth-child(5) {
  height: 14px;
}

.network-quality-0 .network-quality-bar:nth-child(1) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-0 .network-quality-bar:nth-child(2) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-0 .network-quality-bar:nth-child(3) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-0 .network-quality-bar:nth-child(4) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-0 .network-quality-bar:nth-child(5) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-1 .network-quality-bar:nth-child(2) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-1 .network-quality-bar:nth-child(3) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-1 .network-quality-bar:nth-child(4) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-1 .network-quality-bar:nth-child(5) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-2 .network-quality-bar:nth-child(3) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-2 .network-quality-bar:nth-child(4) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-2 .network-quality-bar:nth-child(5) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-3 .network-quality-bar:nth-child(4) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-3 .network-quality-bar:nth-child(5) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-4 .network-quality-bar:nth-child(5) {
  background-color: var(--color-text-dark-grey);
}

.network-quality-5 {
}
