.container {
  height: 54px;
  display: flex;
  align-items: center;
  position: relative;
}

.field {
  padding-right: 27px;
}

.field input {
  padding-right: 43px;
}

.buff {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
