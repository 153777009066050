.container {
  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0px 0 rgb(0 0 0 / 5%);
  color: var(--color-light-dark);
  font-weight: bold;
  position: relative;
  transition: box-shadow 0.15s ease-in-out;

  &.force-border {
    box-shadow: 0px 0px 0 2px var(--color-light-main);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 0px 0 2px var(--color-light-main);
    }
  }
}

.deleteButton {
  padding: 5px;
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  @media (hover: hover) {
    .container:hover &,
    &:focus {
      opacity: 1;
    }
  }
}

.clickableCard {
  cursor: pointer;
}
