.container {
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 48px;
}

.num-container {
  color: var(--color-white);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.num-container span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 20px;
}

.num-container span.isFate {
  align-items: baseline;
  font-weight: 400;
  font-size: 36px;
}

.num-container > div {
  background: var(--color-dark-grey);
  transition: background-color 0.2s ease-in-out;
}

.num-container.has-rolled > div {
  background: var(--color-black);
}

.icon {
  --die-color: rgb(var(--color-theme-text));
  appearance: none;
  width: 20px;
  height: 20px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &.has-rolled {
    opacity: 1;
  }
}

.dieIcon,
.removeIcon {
  display: block;
  position: absolute !important;
  color: var(--die-color);
  transition: opacity 0.2s ease-in-out;

  path {
    fill: var(--die-color);
    stroke-opacity: 0;
  }
}

.dieIcon {
  width: 100% !important;
  height: 100% !important;
  font-size: 8px !important;
  opacity: 1;

  .icon:hover & {
    opacity: 0;
  }
}

.removeIcon {
  width: 80%;
  height: 80%;
  opacity: 0;

  .icon:hover & {
    opacity: 1;
  }
}
