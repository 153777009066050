.container {
  height: 100%;
  position: relative;
  flex-shrink: 0;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;

  button {
    border-radius: 0;
  }
}

.popper {
  margin-top: 4px;
  z-index: 9999;
}

.menu {
  display: block;
  pointer-events: auto;
}
