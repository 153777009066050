.container {
  max-width: 300px;
  margin: 0 auto;

  @media (--md) {
    max-width: 500px;
  }

  .label {
    font-weight: bolder;
    font-size: 14px;
    display: block;
    text-align: center;
  }

  p.copy {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .backgroundArtContainer {
    margin-bottom: 18px;
  }

  .themePreviewContainer {
    position: relative;
    margin-bottom: 20px;
  }

  .themePreview {
    border: 1px solid black;
    border-radius: 4px;
    aspect-ratio: 16/9;
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    .icon {
      width: 5px;
      height: 5px;
      background: rgb(var(--color-theme-text));
    }

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .panel {
      padding: 25px 10px 10px;
      height: calc(100% + 2px);
      background: rgb(var(--color-theme-base));
      position: absolute;
      top: -1px;
      z-index: 2;
      width: 100px;
      border: 1px solid black;
    }

    .leftPanel {
      left: -20px;
      border-radius: 4px 0 0 4px;
      overflow: hidden;

      @media (--md) {
        left: -90px;
      }
    }

    .menuContainer {
      width: 100%;
      background: rgb(0, 0, 0, 0.2);
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2px 2px 0 0;
      display: flex;
      justify-content: flex-end;
      padding: 5px;

      .text {
        background: white;
        height: 8px;
        flex-grow: 1;
        margin-right: 25px;
      }

      .filters {
        background: rgb(var(--color-theme-accent));
        height: 8px;
        width: 10px;
        border-radius: 4px;
      }

      .menus {
        height: 10px;
        width: 22px;
        background: rgb(var(--color-theme-button));
        margin-right: 5px;
        border-radius: 2px;
        justify-content: space-between;
      }

      .backButton {
        height: 10px;
        width: 10px;
        background: rgb(var(--color-theme-button));
        border-radius: 2px;
      }
    }

    .sheetMeta {
      display: flex;
      width: 100%;
      height: 15px;
      justify-content: space-between;

      & > div {
        background: rgb(var(--color-theme-button));
        height: 10px;
        width: 10px;
        border-radius: 2px;
      }
    }

    .avatar {
      height: 50px;
      width: 50px;
      border-radius: 8px;
      border: 2px solid teal;
      margin-bottom: 10px;
      background: white;
    }

    .sectionHeader {
      height: 10px;
      width: 100%;
      background: rgb(var(--color-theme-button));
      border-radius: 4px;
      margin-bottom: 10px;
    }

    .textArea {
      width: 100%;
      height: 30px;
      border: 1px solid rgb(var(--color-theme-text));
      position: relative;
      padding: 5px;
      margin-bottom: 10px;
      border-radius: 8px;

      .eyecon {
        height: 5px;
        width: 8px;
        background: rgb(var(--color-theme-text));
        position: absolute;
        top: 5px;
        right: 5px;
      }

      .sheetText {
        height: 8px;
        width: 30%;
        background: rgb(var(--color-theme-text));
        position: absolute;
        top: 10px;
      }
    }

    .stepper {
      display: flex;
      background: rgb(0, 0, 0, 0.2);
      border-radius: 12px;
      align-items: center;

      .stepperPlus,
      .stepperMinus {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: rgb(var(--color-theme-accent));
      }
      .stepperPlus {
        background: rgb(var(--color-theme-accent));
      }
      .steps {
        height: 10px;
        flex-grow: 1;
        background: rgb(0, 0, 0, 0.7);
        margin: 0 4px;
        border-radius: 12px;
      }
    }

    .panelButtons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 5px;
      position: absolute;
      left: -35px;

      .panelButton {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: rgb(var(--color-theme-button));
        margin-bottom: 5px;

        &:last-child {
          border-radius: 4px;

          > .icon {
            background: rgb(var(--color-theme-accent));
          }
        }
      }

      .userAvatar {
        height: 20px;
        width: 20px;
        background: white;
        border: 2px solid rgb(var(--color-theme-button));
        border-radius: 50%;
        margin-bottom: 5px;
      }
    }

    .rightPanel {
      right: -20px;
      align-content: start;
      display: grid;
      gap: 8px;
      grid-auto-flow: row dense;
      grid-template-columns: 1fr 1fr;
      border-radius: 0 4px 4px 0;

      @media (--md) {
        right: -90px;
      }
    }

    .card {
      aspect-ratio: 9/16;
      width: 100%;
      background: rgb(var(--color-theme-button));
      border-radius: 4px;
      padding: 2px;

      .asset {
        width: 100%;
        height: 50%;
        background: white;
        border-radius: 4px;
        margin-bottom: 5px;
      }

      .text {
        height: 10px;
        margin: 0 5px 5px 5px;
        background: rgb(var(--color-theme-text));
      }

      .text:last-child {
        width: 50%;
      }

      &:nth-child(3) {
        display: none;
      }

      @media (--md) {
        &:nth-child(3) {
          display: block;
        }
      }
    }
  }

  .swatches {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .theme,
  .userColors {
    margin-bottom: 40px;
    position: relative;
  }

  .theme > .label {
    margin-bottom: 16px;
  }

  .themeColorsHeader,
  .userColorsHeader {
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .themeSwatch {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;

    .clearButton {
      position: absolute;
      top: 15px;
      right: -5px;
      z-index: 4;
      opacity: 0;
      pointer-events: none;
      width: 20px;
      height: 20px;
      background: var(--color-black);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid var(--color-white);

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: var(--color-white);
          stroke-opacity: 0;
        }
      }
    }

    &:hover .clearButton {
      opacity: 1;
      pointer-events: auto;
    }
  }

  input[type='color'] {
    -webkit-appearance: none;
    border: none;
    width: 50px;
    height: 50px;
    padding: 0;
    cursor: pointer;
    margin: 5px auto;
  }

  input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type='color']::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }

  input[type='color']:focus {
    outline: none !important;
    border: 0px;
    box-shadow: 0;
  }

  .userColorsContainer {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 18px;

    .swatches {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--md) {
      grid-template-columns: 1fr 1fr;
      justify-items: start;
    }
  }

  .colorSpread {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .colorRow {
    display: flex;
    gap: 10px;
  }

  .color {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .addColorButton {
    border: 1px solid black;
  }

  .removeColorButton {
    height: 10px;
  }
}
