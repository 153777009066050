.container {
  --panel-gradient-index: 1;
  --panel-content-index: calc(var(--panel-gradient-index) + 1);

  padding: 18px;
  position: relative;
  background: var(--color-white);
  border-radius: 18px;
  color: var(--color-dark-text);
}

.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--panel-gradient-index);
  background: var(--light-panel-gradient);
  border-radius: inherit;
}

.content {
  position: relative;
  z-index: var(--panel-content-index);
}
