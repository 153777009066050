.container {
  padding-top: calc(var(--layout-header-height) + 40px);
}

.panel {
  max-width: 450px;
  margin: 0 auto;
}

.heading {
  text-align: center;
}

.form {
  margin-top: 18px;
}

.input,
.message {
  margin-bottom: 18px;
}

.message {
  margin-top: -12px;
  padding: 0 18px;
  font-size: 14px;
  text-align: center;
}

.file-input {
  width: 144px;
  margin: 0 auto 18px;
}

.error {
  margin-bottom: 18px;
  color: var(--color-red);
}

.success {
  margin-bottom: 9px;
  padding: 0 18px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.button {
  width: 100%;
}
