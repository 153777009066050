.container {
  width: 38px;
  height: 22px;
  display: inline-block;
  position: relative;
  border: 2px solid var(--color-light-main);
  border-radius: 11px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

label:hover > .container,
.container:hover,
.container:focus {
  border-color: var(--color-primary-main);
}

.container.is-active {
  background: var(--color-primary-main);
  border-color: var(--color-primary-main);
}

.knob {
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  background: var(--color-light-main);
  border-radius: 50%;
  transform: translateX(3px);
  transition: all 0.15s ease-in-out;
}

label:hover > .container:not(.is-active) .knob,
.container:not(.is-active):hover .knob,
.container:not(.is-active):focus .knob {
  background: var(--color-primary-main);
}

.is-active .knob {
  background-color: var(--color-white);
  transform: translateX(18px);
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
}
