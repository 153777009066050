.roomControls {
  width: 100%;
  height: var(--layout-room-controls-height);
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: var(--tablesheet-actions-index);
  background: linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), rgb(var(--color-theme-base));
  background-blend-mode: multiply;
  transition: width 0.3s ease-in-out;

  &.wide {
    width: calc(100% + var(--sheet-toc-width));
  }
}

.title {
  margin-right: 10px;
  overflow: hidden;

  h3 {
    color: var(--color-white);
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h5 {
    color: rgb(var(--color-theme-text));
    font-size: 14px;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
}

.inviteButton {
  width: 36px;
  border-radius: 0 10px 10px 0;
}

.togglePanelButton {
  margin-left: 16px;
  display: none;

  @media (--md) {
    display: flex;
  }
}

.isCollapsedActive {
  transform: rotate(180deg);
}

.userMenu {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  z-index: 5;
}
