.container {
  height: 100%;
  padding-top: var(--layout-header-height);
  display: flex;
  flex-flow: column;
}

.spinner {
  margin-top: 40px;
}

.content {
  flex-grow: 1;
  position: relative;
}

.preview {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.info-container {
  height: 100%;
  padding-left: var(--sheet-width);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-dark-grey);
  transition: padding-left 0.3s ease-in-out;
}

.info-container.toc-open {
  padding-left: calc(var(--sheet-width) + var(--sheet-toc-width));
}

.info {
  padding: 0 18px;
  max-width: 546px;
  font-size: 16px;
  text-align: center;
}

.info-heading {
  font-size: 18px;
  margin-bottom: 14px;
}

.info-copy {
  margin-bottom: 36px;
  color: var(--color-text-light-grey);
}

.info-button {
  width: 293px;
  margin-bottom: 18px;
}

.product {
  margin: 30px auto 20px;
}

.report-button {
  width: 50px;
  margin: 0 auto;
  display: block;
  color: rgb(var(--color-theme-text));
  font-size: 12px;
  text-decoration: none;
}

.report-button:hover,
.report-button:focus {
  text-decoration: underline;
}
