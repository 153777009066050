.content {
  align-items: center;
  background: transparent;
  color: rgb(var(--color-theme-text));
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 100%;
  justify-content: space-between;
  padding: 6px 12px;

  @media (hover: hover) {
    &:hover .avatar {
      background: rgb(var(--color-theme-accent));
      border-radius: 30%;
      filter: brightness(1.1);
    }
  }

  &.isActive {
    background: linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), rgb(var(--color-theme-base));
    background-blend-mode: multiply;
    border-radius: 10px 10px 0 0;
    margin-left: -162px;
    width: 240px;

    &:hover .avatar,
    .avatar {
      background: transparent;
      filter: brightness(1.1);
    }

    .message {
      display: block;
    }
  }
}

.avatar {
  background: rgb(var(--color-theme-button));
  box-shadow: none;
  transition: all 0.15s ease-in-out;
}

.message {
  display: none;
  padding-left: 12px;

  .username {
    color: var(--color-white);
    font-size: 16px;
    font-weight: bold;
  }
}

.popper {
  z-index: 9999;
}

.userMenu {
  border-radius: 0 0 10px 10px;
  display: block;
  position: relative;
  pointer-events: auto;
  width: 240px;

  &.adminMenu {
    background: rgb(var(--color-accent-main));
  }
}
