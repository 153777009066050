.container {
  display: flex;
  justify-content: center;
  position: relative;
}

.filterBar {
  display: none;

  @media (--lg) {
    display: flex;
  }
}

.filterDropdown {
  display: block;
  min-width: 150px;

  select {
    background: var(--color-dashboard-light-grey);
  }

  @media (--lg) {
    display: none;
  }
}

.spacer {
  visibility: hidden;
}

.orderButton {
  position: absolute;
  top: 0;
  right: 0;
}
