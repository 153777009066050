.container {
  position: relative;
}

.menu {
  display: block;
  margin-left: 4px;
  pointer-events: auto;
}

.popper {
  z-index: 9999;
}
