.nav {
  height: 100%;
  display: flex;
  gap: 0 10px;
  min-width: fit-content;
  flex-direction: column;
  align-items: start;

  a {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    color: var(--color-dashboard-main);
    font-weight: bold;
    text-decoration: none;
    box-shadow: inset 0 0 0 0 var(--color-red);
    line-height: 40px;
    transition: box-shadow 0.15s ease-in-out;
    font-size: 16px;
    min-width: fit-content;

    @media (--lg) {
      line-height: 74px;
      font-size: 18px;
      text-align: center;
    }
  }

  @media (--lg) {
    flex-direction: row;
    justify-content: start;
    gap: 0 30px;
  }
}

.navContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.nav a:hover,
.nav a:focus {
  color: var(--color-red);

  @media (--lg) {
    box-shadow: inset 0 -4px 0 0 var(--color-red);
  }
}

.nav a.isActive {
  color: var(--color-red);
}

.nav .materialsParentLink {
  display: none;
  @media (--lg) {
    display: initial;
  }
}

.nav .materialsSubLink {
  @media (--lg) {
    display: none;
  }
}

.tableButton {
  height: 34px;
  display: none;
  margin-left: 10px;

  @media (--lg) {
    display: initial;
  }
}

.container {
  padding-top: var(--layout-header-height);
}

.sidebar {
  width: var(--layout-sidebar-width);
  position: fixed;
  top: var(--layout-header-height);
  bottom: 0;
  left: 0;
  background: var(--color-dashboard-white);
  color: var(--color-dashboard-main);
  font-size: 18px;
  display: none;

  @media (--lg) {
    display: initial;
  }
}

.sidebar-header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-white);
  border-bottom: solid 2px var(--color-dashboard-light-grey);
}

.sidebar-heading {
  margin: 10px 0;
  color: var(--color-dashboard-mid);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.sidebar-heading span {
  display: block;
}

.sidebar-heading span:last-child {
  color: var(--color-dashboard-light);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-indent: 2px;
}

.sidebar-content {
  padding: 20px;
}

.searchBar {
  margin-left: auto;

  @media (--sm) {
    margin-left: 0;
  }

  @media (--lg) {
    margin-left: auto;
  }
}

.loadingSpinner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.banner {
  padding: 0;

  @media (--xl) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .show-sidebar & {
    @media (--lg) {
      margin-left: var(--layout-sidebar-width);
    }
  }
}

.createModal {
  text-align: center;

  .header {
    line-height: 1.4;
    margin: 0 24px 12px;
    max-width: 800px;
  }

  .title {
    margin: 0 auto 12px;
    color: var(--color-red);
    font-family: var(--font-alphapipe);
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .modalContent {
    height: 100%;
    overflow: auto;
    padding: 0 12px;

    .createOptions {
      display: flex;
      flex-flow: column;
      justify-content: center;
      gap: 24px;
      margin: 12px 0;

      .option {
        align-items: center;
        background: var(--color-dashboard-white);
        border-radius: 12px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding: 12px;
        width: 100%;

        img {
          max-height: 120px;
          max-width: 45%;
          padding-right: 16px;
        }

        .createText {
          max-width: 50%;
        }

        p {
          font-size: 16px;
          margin: 6px 0 12px;
        }

        button {
          margin: 12px 0;
        }
      }
    }

    .createFooter {
      align-items: center;
      display: flex;
      font-size: 16px;
      gap: 16px;
      justify-content: center;
      margin: 24px 0;
      text-align: left;

      h4 {
        font-size: 20px;
      }

      a {
        color: var(--color-red);
        text-decoration: none;
        transition: color 0.15s ease-in-out;

        &:focus,
        &:hover {
          color: var(--color-purple);
          text-decoration: underline;
        }
      }
    }
  }

  @media (--sm) {
    .header {
      margin: 24px auto 12px;
    }

    .modalContent {
      .createOptions {
        flex-flow: row;
        margin: 24px 0;

        .option {
          flex-flow: column;
          justify-content: end;
          padding: 24px 24px 12px;
          width: 33%;

          img {
            max-height: 160px;
            max-width: 100%;
            padding: 0 0 16px;
          }

          .createText {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (--lg) {
  .show-sidebar .content {
    padding-left: var(--layout-sidebar-width);
  }
}
