.container {
  --background-hover: rgb(var(--color-sheet-accent));
  width: var(--sheet-collapsed-offset);
  position: absolute;
  top: 84px;
  bottom: 0;
  left: calc(var(--sheet-width) - var(--sheet-collapsed-offset));
  z-index: 3;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.container.is-active {
  opacity: 1;
  pointer-events: auto;
}

.nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow-y: auto;
}

.button {
  height: 30px;
  padding: 0 24px;
  display: block;
  position: relative;
  color: var(--color-white);
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  white-space: nowrap;
  line-height: 22px;
  transition: margin 0.3s;
}

.button-content {
  min-width: 12px;
  min-height: 12px;
  display: block;
  position: relative;
}

.button-size {
  padding: 0 10px;
  display: none;
  opacity: 0;
}

.button:hover .button-size,
.button.is-active .button-size {
  display: block;
}

.button-background {
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  border-radius: 11px;
  background: rgb(var(--color-sheet-button));
  transform: translateY(-50%);
}

.button:hover .button-background,
.button.is-active .button-background {
  height: 100%;
  width: 100%;
}

.button:hover .button-background {
  background: var(--background-hover);
}

.button.is-active .button-background {
  background: rgb(var(--color-sheet-accent));
}

.button-label {
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 2;
  opacity: 0;
  transform: translateY(-50%) scale(0, 0);
  transform-origin: center left;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.button:hover .button-label,
.button.is-active .button-label {
  opacity: 1;
  transform: translateY(-50%) scale(1, 1);
}
