.container {
  background: rgb(0 0 0 / 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  inset: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: var(--layout-scrollbar-width);
  }

  &::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0.1);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgb(0 0 0 / 0.1);
  }

  &::-webkit-scrollbar-track:hover {
    background: rgb(var(--color-sheet-button));
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(var(--color-sheet-text) / 0.3);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(var(--color-sheet-text));
  }

  .upsell {
    width: 100%;
    padding-bottom: 20px;

    .header {
      text-align: center;
      margin-bottom: 30px;

      img {
        max-height: 280px;
        max-width: 300px;
        margin: 20px auto;
        -webkit-filter: drop-shadow(0 0 28px rgba(0, 0, 0, 0.25)) drop-shadow(0 0 28px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(0 0 28px rgba(0, 0, 0, 0.25)) drop-shadow(0 0 28px rgba(0, 0, 0, 0.5));
      }

      h3 {
        font-size: 32px;
        line-height: 1;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      span {
        font-size: 18px;
      }
    }

    hr {
      flex: none;
      max-width: 450px;
      height: 3px;
      margin: 0 20px 30px;
      background: rgb(var(--color-theme-button));
      border: none;
      border-radius: 2px;

      @media only screen and (min-width: 450px) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .createRoom {
      max-width: 700px;
      margin: 0 auto;

      h3 {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 20px;
        text-align: center;
      }

      p {
        font-size: 18px;
        margin: 0 40px 40px 40px;
      }
    }

    .previewLobbyUpsell {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
      gap: 20px;
      max-width: 850px;
      margin: 20px auto 40px;
      position: relative;

      .upsellCopy {
        margin: 0 auto;
        padding: 0 12px;

        h4 {
          font-size: 24px;
        }
        ul {
          font-size: 20px;
          list-style: none;
          margin-bottom: 6px;
          padding-left: 0;

          li {
            align-items: center;
            display: flex;
            gap: 12px;
            margin-bottom: 16px;
            line-height: 1.2;

            svg {
              height: 20px;
              width: 20px;
            }

            svg > * {
              fill: rgb(var(--color-theme-text));
              stroke: rgb(var(--color-theme-text));
            }
          }
        }
      }
    }
  }
}

.warning {
  background-color: rgb(var(--color-theme-button));
  border-radius: 8px;
  color: rgb(var(--color-white));
  font-size: 18px;
  padding: 8px 16px;
}

@media only screen and (min-height: 1000px) {
  .container {
    justify-content: center;
  }
}
