.progress-bar {
  height: 22px;
  padding: 3px;
  margin-bottom: 12px;
  position: relative;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.progress-bar-fill {
  height: 100%;
  border-radius: 2px;
  cursor: pointer;
}
