.video-container {
  width: 320px;
  padding-top: calc(320px * 9 / 16);
  margin: 16px auto;
  position: relative;
  border-radius: 10px;

  @media (--md) {
    width: 480px;
    padding-top: calc(480px * 9 / 16);
  }
}

.video-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-grey);
  border-radius: inherit;
}

.video-off-icon {
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-off-icon path {
  fill: var(--color-text-grey);
  stroke-opacity: 0;
}

.video {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: inherit;
  object-fit: cover;
  transform: scale(-1, 1);
}

.sound-settings {
  width: 320px;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--md) {
    width: auto;
  }
}

.input-container {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;

  .select {
    width: min(350px, calc(100%-20px));
    height: 34px;
  }

  @media (--md) {
    flex-direction: row;
    align-items: center;
  }
}

.input-label {
  width: 115px;
  margin-right: 12px;
  font-weight: bold;
  white-space: nowrap;

  .levels-field & {
    display: none;

    @media (--md) {
      display: inline;
    }
  }
}

.levels-field {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.levels-container {
  width: 320px;
  background: var(--color-light-grey);
  border-radius: 6px;
  height: 12px;
  overflow: hidden;
  mask-image: url('/src/images/icons/MicLevel.svg');
  mask-repeat: repeat-x;
  position: relative;

  @media (--md) {
    width: 350px;
  }
}

.levels {
  background: linear-gradient(90deg, var(--color-teal), var(--color-green));
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  height: 100%;
  transition: clip-path 0.04s;
  width: 100%;
}

.error {
  max-width: 400px;
  margin: 0 auto 18px;
  color: var(--color-red);
  font-size: 14px;
  text-align: center;
}
