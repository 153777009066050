.container {
  width: 100%;
  max-width: 768px;
}

.icon {
  margin-top: -2px;
  display: block;
  font-size: 28px;
  line-height: 1;
}

.content {
  font-size: 18px;
  color: var(--color-light-200);

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  strong,
  a {
    color: white;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
