.container {
  height: 42px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 21px;
}

.add-button,
.subtract-button {
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--color-sheet-accent));
  border-radius: 50%;
  transition: all 0.15s;
}

.add-button:hover:not(:disabled),
.add-button:focus:not(:disabled),
.subtract-button:hover:not(:disabled),
.subtract-button:focus:not(:disabled) {
  filter: brightness(1.2);
}

.add-button:disabled,
.subtract-button:disabled {
  opacity: 0.2;
}

.add-button svg,
.subtract-button svg {
  height: 16px;
}

.add-button path,
.subtract-button path {
  fill: var(--color-white);
  stroke-opacity: 0;
}

.add-button {
  margin-left: 4px;
}

.subtract-button {
  margin-right: 4px;
}

.input {
  width: 100%;
  height: 32px;
  margin: 0 4px;
  padding: 0 12px;
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  border: none;
  color: var(--color-white);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  outline: none;
}

.input.is-locked {
  background: none;
  color: rgb(var(--color-sheet-text));
}

.is-compact .input {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  background: none;
}

.slash {
  margin: 0 4px;
  color: rgb(var(--color-sheet-text));
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  filter: brightness(0.7);
}
