.panel {
  background: rgb(var(--color-theme-base));
  bottom: 0;
  box-shadow: -1px 1px 3px rgb(0 0 0 / 0.35);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media (--md) {
    width: 360px;
  }
}

.panel::before {
  background: url('../../images/Noise.png') repeat;
  content: '';
  inset: 0;
  mix-blend-mode: soft-light;
  opacity: 0.06;
  position: absolute;
}

.content {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 10;
}

.buttons {
  align-items: center;
  display: none;
  flex-direction: column;
  gap: 12px;
  left: -86px;
  position: absolute;
  top: 12px;

  &.preview {
    left: -68px;
    top: 16px;
  }

  @media (--md) {
    display: flex;
  }
}

.buttons button {
  box-shadow: 0 0 14px 0 rgba(0 0 0 / 25%);
  transition: all 0.15s ease-in-out;

  &:focus-visible,
  &:hover {
    border-radius: 14px;

    span:nth-child(2) {
      zoom: 1.2;

      svg path {
        fill: var(--color-white) !important;
      }
    }

    .is-active-icon {
      display: none !important;
    }

    .is-active-hover-icon {
      display: block !important;
    }

    &.obr-button {
      &:before {
        opacity: 1;
      }
    }

    &.obr-button.is-active-tab {
      &:before {
        background-color: rgb(var(--color-theme-accent));
      }
    }

    .obr-icon {
      width: 100%;

      &.is-color {
        display: none;
      }

      &.is-white {
        display: block;
      }
    }
  }

  .obr-icon {
    width: 100%;
    position: relative;

    &.is-white {
      display: none;
    }
  }

  &.obr-button {
    &:before {
      width: 100%;
      height: 150%;
      position: absolute;
      bottom: 50%;
      left: 0;
      background: var(--color-orange);
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      content: '';
      opacity: 0;
      transition: opacity 0.15s, background-color 0.15s;
    }
  }

  .obr-active-icons {
    .is-active-icon {
      display: block;
    }

    .is-active-hover-icon {
      display: none;
    }
  }
}

.combo-buttons {
  background: rgb(var(--color-theme-button));
  border-radius: 40px;
  box-shadow: 0 0 14px 0 rgba(0 0 0 / 25%);
  outline: none;

  button {
    box-shadow: none;
  }

  &.is-obr-active {
    background: var(--color-orange);
  }
}

.title {
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  padding: 15px 20px;
  text-transform: uppercase;
}

.chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.unread-indicator {
  background: var(--color-white);
  border-radius: 9px;
  color: var(--color-light-text);
  height: 8px;
  position: absolute;
  right: -3px;
  top: -1px;
  width: 8px;
}

.store-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.store-panel {
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
  overflow: auto;
  padding: 10px 14px 24px;
}

.hidden {
  display: none;
}

.assetsMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.assetTrayMobile {
  position: relative;
  flex-grow: 1;
  height: unset;

  > div:first-of-type {
    position: absolute;
    inset: 0;
  }
}
