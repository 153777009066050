.content {
  position: relative;
}

.toggle {
  position: absolute;
  top: 5px;
  left: -5px;
}

.has-heading .toggle {
  top: 6px;
}

.container.left,
.builder-input.left {
  text-align: left;
}

.container.center,
.builder-input.center {
  text-align: center;
}

.container.right,
.builder-input.right {
  text-align: right;
}

.container.has-toggle {
  padding-left: 16px;
}

.heading {
  color: var(--color-white);
  font-size: 20px;
  font-weight: bold;
}

.paragraph {
  color: rgb(var(--color-sheet-text));
  font-size: 18px;
}

.builder-input-container {
  position: relative;
}

.builder-input-container:first-child {
  margin-bottom: 12px;
}

.builder-input {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  outline: none;
  resize: none;
}

.builder-border {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(var(--color-sheet-text) / 0.3);
  border-radius: 1px;
}
