@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  animation: fadeOut 4s;
}

.glow {
  width: 480px;
  height: 480px;
  position: absolute;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
}

.content {
  width: 122px;
  height: 122px;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  background: var(--color-alert-red);
  border-radius: 50%;
  color: var(--color-text-light);
}

.content svg {
  width: 50px;
  height: 50px;
  margin: 0 auto 9px;
  display: block;
}

.content path {
  fill: var(--color-light-text);
  stroke-opacity: 0;
}

.content span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--heading-letter-spacing);
  text-indent: var(--heading-letter-spacing);
}
