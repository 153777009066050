@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  animation: spin 1.5s linear infinite, fadeInOut 2s linear infinite;
}

.icon path {
  fill: var(--loadingspinner-color);
  stroke-opacity: 0;
}
