.panel {
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0 0 rgb(0 0 0 / 5%);
  margin-bottom: 20px;
  padding: 12px 18px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--sm) {
    padding: 18px;
  }
}

.content {
  padding: 0;

  @media (--sm) {
    padding: 20px;
  }

  @media (--md) {
    padding: 40px;
  }
}

.titleBar {
  background: var(--color-white);
  padding: 24px 18px;
}

.order {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media (--sm) {
    flex-flow: row wrap;
  }
}

.orderInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  order: 1;
  text-align: left;

  @media (--sm) {
    text-align: right;
  }
}

.amountTotal,
.originalTotal {
  font-size: 24px;
  color: var(--color-dashboard-mid);
  margin-left: auto;

  &:is(.originalTotal) {
    font-size: 16px;
    margin-right: 4px;
    background: linear-gradient(
      to bottom left,
      transparent 0%,
      transparent calc(50% - 2px),
      var(--color-dashboard-mid) 50%,
      transparent calc(50% + 2px),
      transparent 100%
    );
  }
}

.amountTotal {
  margin: 12px 0 -6px;
  order: 3;
  text-align: right;
  width: 100%;

  @media (--sm) {
    margin-top: 0;
  }
}

.date {
  color: var(--color-dashboard-main);
  font-size: 14px;
  font-weight: bold;
}

.orderNumber {
  color: var(--color-dashboard-dark);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.body {
  display: flex;
  gap: 18px;
  order: 2;

  img {
    height: auto;
    max-width: 120px;
    object-fit: contain;
  }

  .coverPlaceholder {
    background-color: var(--color-dashboard-light);
    height: 170px;
    width: 120px;
  }

  .coverArt {
    display: none;

    @media (--sm) {
      display: block;
    }
  }

  @media (--sm) {
    flex: 0 1 calc(100% - 170px);
    order: 1;
  }
}

.productList {
  color: var(--color-dashboard-mid);
  display: flex;
  gap: 10px;
  flex-flow: column;
  font-size: 18px;
  font-weight: bold;
  list-style: none;
  margin: 0;
  padding: 0;
}

.emptyCta {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
