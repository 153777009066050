.container {
  --button-background: var(--color-red);
  --button-border: 0 solid var(--color-red);
  --button-color: var(--color-white);
  --button-hover-background: var(--button-background);
  --button-hover-border: var(--button-border);
  --button-hover-color: var(--button-color);
  --button-height: 34px;
  --button-font-size: 14px;
  --button-border-radius: 10px;
  --icon-background: rgba(0, 0, 0, 0.2);
  --icon-color: var(--color-white);
  --icon-size: 14px;
  --button-padding: 20px;
  --button-cursor: pointer;

  min-height: var(--button-height);
  padding: 0 var(--button-padding);
  position: relative;
  background: var(--button-background);
  border-radius: var(--button-border-radius);
  border: var(--button-border);
  color: var(--button-color);
  cursor: var(--button-cursor);
  font-size: var(--button-font-size);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.96px;
  text-decoration: none;
  text-indent: 1.96px;
  transition: background 0.15s ease-in-out, border 0.15s ease-in-out, color 0.15s ease-in-out, filter 0.15s ease-in-out;

  &.knockout {
    background: linear-gradient(var(--color-black), var(--color-black)) padding-box, var(--button-background) border-box;
    border: 2px solid transparent;
    color: var(--button-color);
    filter: none;
  }

  @media (hover: hover) {
    &:focus,
    &:hover {
      background: var(--button-hover-background);
      border: var(--button-hover-border);
      color: var(--button-hover-color);
    }

    &.has-hover-effect:focus,
    &.has-hover-effect:hover {
      filter: brightness(1.1);
    }

    &.knockout:focus,
    &.knockout:hover {
      background: var(--button-background) border-box;
      border: 2px solid transparent;
      color: var(--button-hover-color);
      filter: none;
    }
  }
}

.content {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s;
  white-space: nowrap;

  .is-loading & {
    opacity: 0;
  }
}

.is-link {
  --button-height: 20px;
  background: none;
  color: var(--button-color);
  min-height: auto;
  padding: 0 12px;
  text-decoration: none;

  @media (hover: hover) {
    &:focus,
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-container {
  position: relative;
}

.icon-content {
  padding-left: calc(var(--button-padding) * 1.5);
  white-space: nowrap;
}

.icon {
  align-items: center;
  background: var(--icon-background);
  border-radius: 50%;
  display: flex;
  height: calc(var(--icon-size) * 2);
  justify-content: center;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(var(--icon-size) * 2);

  svg {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  svg * {
    fill: var(--icon-color);
    stroke-opacity: 0;
  }

  @media (hover: hover) {
    .container:hover & svg *,
    .container:focus & svg * {
      fill: var(--button-hover-color);
    }
  }
}
