.container {
  max-width: 1280px;
  min-height: 140px;
  margin: 10px;
  background: var(--color-primary-dark);
  color: var(--color-primary-accent);
  border-radius: 24px;
  padding: 20px;
  position: relative;

  &::before {
    position: absolute;
    inset: 0;
    z-index: 0;
    border-radius: inherit;
    background: linear-gradient(180deg, var(--color-primary-main), transparent);
    opacity: 0.3;
    content: '';
  }

  @media (--sm) {
    margin: 20px 20px 0;
  }

  @media (--lg) {
    margin: 40px 40px 0;
    padding: 30px 50px;
  }

  @media (--xl) {
    margin-left: auto;
    margin-right: auto;
  }
}

.logo {
  max-width: 148px;
  max-height: 60px;

  @media (--lg) {
    max-height: 140px;
  }
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (--lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.bannerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copy {
  max-width: 850px;
  margin-bottom: 30px;
  font-size: 16px;
  text-align: center;

  @media (--lg) {
    font-size: 24px;
  }
}

.bannerTitle {
  margin-bottom: 10px;
  color: var(--color-light-light);
  font-size: 20px;

  @media (--lg) {
    font-size: 36px;
  }
}

.button {
  display: block;
  line-height: 32px;
}

.diceIcons {
  width: 156px;
  height: 182px;
  display: none;
  flex-shrink: 0;
  position: relative;
  fill: var(--color-primary-accent);

  .d4 {
    width: 30px;
    position: absolute;
    left: 30px;
    top: 10px;
  }

  .d10 {
    width: 36px;
    position: absolute;
    right: 30px;
    top: 60px;
  }

  .d6 {
    width: 22px;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  .d20 {
    width: 40px;
    position: absolute;
    left: 0;
    bottom: 40px;
  }

  @media (--lg) {
    display: block;
  }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
}
