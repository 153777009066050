.header {
  text-align: center;
  margin: 24px 0;
}

.title {
  max-width: 520px;
  margin: 0 auto;
  color: var(--color-red);
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.subtitle {
  max-width: 520px;
  padding: 0 16px;
  margin: 2px auto 0;
  line-height: 1.4;

  @media (--md) {
    padding: 0;
  }
}

.content {
  min-height: 400px;
  max-height: 800px;
  margin: 0 -20px;
  padding: 24px 16px;
  border-top: solid 2px var(--color-dashboard-light-grey);
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: var(--color-dashboard-white);
  overflow-y: auto;

  @media (--md) {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.asset {
  display: none;
}

.filter-bar {
  margin-bottom: 24px;
}

.filter-assets-all .asset {
  display: block;
}

.filter-assets-apps .asset.is-app {
  display: block;
}

.filter-assets-pdfs .asset.is-pdf {
  display: block;
}

.filter-assets-maps .asset.is-map {
  display: block;
}

.filter-assets-tokens .asset.is-token {
  display: block;
}

.filter-assets-images .asset.is-image,
.filter-assets-images .asset.is-gif {
  display: block;
}

.filter-assets-audio .asset.is-audio {
  display: block;
}

.assets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;

  @media (--md) {
    justify-content: start;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.empty-header {
  color: var(--color-dashboard-dark);
  font-size: 26px;
  text-align: center;
}

.empty-header + .empty-body {
  margin-top: 8px;
}

.empty-body {
  color: var(--color-dashboard-main);
  font-size: 22px;
  line-height: 30px;
  max-width: 440px;
  text-align: center;
}

.empty-body + .empty-cta {
  margin-top: 24px;
}
