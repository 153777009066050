.container {
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.25px;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    stroke-opacity: 0;
  }
}

.number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
