.container {
  width: 100%;
}

.input {
  width: 100%;
  height: 42px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 10px;
  color: var(--color-white);
  font-size: 18px;
  outline: none;
}
