.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.assetTray {
  position: relative;
  flex-grow: 1;
  height: unset;

  > div:first-of-type {
    position: absolute;
    inset: 0;
  }
}

.player {
  margin: 16px 0;

  .trackLabel {
    display: block;
    text-align: center;
    font-size: 16px;
  }
}

.controls {
  position: relative;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .arrowLeft,
  .arrowRight {
    height: 16px;
    width: 16px;
  }

  .arrowLeft {
    transform: rotate(90deg);
  }

  .arrowRight {
    transform: rotate(-90deg);
  }

  .volumeSlider {
    isolation: isolate;
    position: relative;
    display: flex;
    align-items: center;
    width: calc((32px * 3) + 4px); /* 1/2 of 8px gap + 32px * 3  */
    margin-left: -68px; /* 32px * 2 + 1/2 of 8px gap */
    height: 8px;

    input[type='range'] {
      appearance: none !important;
      height: 100%;
      width: 100%;
      background-color: transparent;
      border-radius: 4px;

      &::-webkit-slider-thumb {
        appearance: none !important;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        border: 0px solid transparent;
        background-color: rgb(var(--color-theme-accent));
        margin-top: -2px;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        border: 0px solid transparent;
        background-color: rgb(var(--color-theme-accent));
        margin-top: -2px;
        cursor: pointer;
      }

      &::-webkit-slider-runnable-track {
        border-radius: 4px;
        background-color: rgb(var(--color-theme-button));
        height: 8px;
      }

      &::-moz-range-track {
        border-radius: 4px;
        background-color: rgb(var(--color-theme-button));
        height: 8px;
      }
    }

    &::before {
      background-color: rgb(var(--color-theme-accent) / 0.5);
      border-radius: 8px;
      bottom: 0;
      content: '';
      display: block;
      height: 8px;
      left: 0;
      margin-right: 4px;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: min(calc(var(--volume-width-percent) * 100%), calc(100% - 6px));
      z-index: 1;
    }
  }
}

.seekBar {
  border-radius: 4px;
  margin: 8px 16px;
  height: 8px;
  cursor: pointer;
  background-color: rgb(var(--color-theme-button));
  overflow: hidden;

  .progress {
    height: 100%;
    background-color: rgb(var(--color-theme-accent) / 0.5);
  }
}

.trackPosition {
  text-align: center;
}
