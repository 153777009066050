.container {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
}

.content {
  overflow: auto;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;

  &.isShowingSettings {
    opacity: 0;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 16px;
}

.title {
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.privacyToggle {
  align-items: center;
  display: flex;
  gap: 6px;

  span {
    margin: 0;
  }
}

.border {
  background: rgb(var(--color-theme-text) / 0.3);
  border: none;
  height: 2px;
  margin: 10px 20px;
  width: calc(100% - 40px);
}

.bar {
  width: 100%;
  padding: 0 18px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(var(--color-theme-base));
}

.barSpacer {
  flex: 1 1;
}

.barButton {
  --color-dice-button: rgb(var(--color-theme-text));
  width: 70px;
  aspect-ratio: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  background: rgba(0 0 0 / 0.3);
  border: 2px solid transparent;
  border-radius: 12px;
  color: rgb(var(--color-theme-text));
  transition-property: background-color, border-color, color, opacity;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  &:not(.isSettings):hover,
  &:not(.isSettings):focus {
    background: rgba(0 0 0 / 0.6);
    border-color: var(--color-dice-button);
    color: var(--color-dice-button);
  }

  &.isSettings {
    background: none;
  }

  .isDisabled & {
    opacity: 0.5;
  }
}

.barButtonIcon {
  width: 30px;
  height: 30px;
  color: var(--color-dice-button);

  path {
    fill: var(--color-dice-button);
    stroke-opacity: 0;
  }

  .isLongName & {
    width: 24px;
    height: 24px;
  }

  .isLongName.isLargeNumber & {
    font-size: 9px;
  }
}

.barButtonText {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.trayContainer {
  margin-bottom: 24px;
}

.allButtons {
  display: flex;
  flex-flow: row wrap;
  gap: 11px;
  justify-content: center;
  text-align: center;
}

.diceButtonsContainer {
  padding: 15px 20px;

  h4 {
    margin: 20px 0 10px;
    text-align: center;
  }
}

.colorPicker {
  margin-bottom: 18px;
}

.colorSwatch {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  border: none;

  input {
    appearance: none;
    cursor: pointer;
    border: 2px solid rgb(var(--color-theme-button));
    height: 24px;
    width: 24px;
    background-color: attr(value);
    border-radius: 100%;
    transition-property: transform, border, opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    opacity: 0.5;

    &:checked,
    &:hover {
      transform: scale(1.1);
      opacity: 1;
      border: 2px solid rgb(var(--color-theme-accent));
      box-shadow: var(--box-shadow);
    }
  }
}

.settingsButton {
  height: 18px;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    fill: rgb(var(--color-theme-text));

    &:first-child {
      height: 100%;
    }

    &:last-child {
      height: 8px;
      transition: transform 0.15s ease-in-out;

      &.isShowingSettings {
        transform: rotate(180deg);
      }
    }
  }
}

.settingsContainer {
  position: absolute;
  inset: 58px 0 0;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;

  &.isShowingSettings {
    opacity: 1;
    pointer-events: all;
  }

  hr {
    width: calc(100% - 14px);
    margin: 8px auto;
    border: none;
    border-top: 1px solid rgb(var(--color-theme-text));
  }
}

.dieCards {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  inset: 0 0 70px;
  overflow-y: auto;
}

.dieCard {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  position: relative;
  background: rgb(0 0 0 / 0.6);
  border-radius: 12px;
  transition: background-color 0.15s ease-in-out;

  &.isDisabled {
    background: rgb(0 0 0 / 0.3);
  }
}

.dieColorPicker {
  gap: 5px;
}

.addCustomButton {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
}

.deleteButton {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
  background: rgb(var(--color-theme-base));
  border: 1px solid rgb(var(--color-theme-text));
  border-radius: 50%;
  opacity: 0;
  transition-property: background-color, border-color, opacity;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  svg {
    width: 9px;
    height: 9px;
    fill: rgb(var(--color-theme-text));
    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    background-color: rgb(var(--color-theme-accent));
    border-color: var(--color-white);

    svg {
      fill: var(--color-white);
    }
  }

  .dieCard:hover & {
    opacity: 1;
  }
}
