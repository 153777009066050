.container {
  height: var(--layout-navbar-height);
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--color-white);
  line-height: 1;
}

.logo {
  flex-shrink: 0;

  &:not(.persistOnMobile) {
    &.link {
      display: none;

      @media (--lg) {
        display: initial;
        margin-right: unset;
      }
    }
  }

  &.menuButton {
    padding: 0;
    @media (--lg) {
      display: none;
    }
  }
}

.roleLogo {
  width: 108px;
  height: 36px;

  :global(.is-searching) &.mobileLogo {
    display: none;
    @media (--sm) {
      display: block;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;

  @media (--sm) {
    padding-left: 20px;
    padding-right: 30px;
  }
}

.menu {
  display: none;

  @media (--lg) {
    display: flex;
  }
}

.mobileMenu {
  position: absolute !important;
  left: 10px;
  top: calc(var(--layout-navbar-height) + var(--layout-topbar-height));

  @media (--lg) {
    display: none !important;
  }
}

.arrow {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--color-dashboard-white);
  border-radius: 50%;
  transition: border 0.15s ease-in-out, background 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.arrow:hover {
  background: var(--color-dashboard-white);
}

.arrow svg {
  width: 10px;
  height: 6px;
  fill: currentColor;
}

.arrow-icon path {
  fill: var(--color-dashboard-main);
  stroke-opacity: 0;
}

.menuIsActive .arrowIcon {
  transform: rotate(180deg);
}

.unauthLinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  height: 100%;
  gap: 12px;
}

.unauthButton {
  height: 34px;
  padding: 0 22px;
  display: block;
  border-radius: 10px;
  border: none;
  outline: none;
  text-decoration: none;
  color: var(--color-white);
  line-height: 34px;
}
