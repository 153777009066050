.container {
}

.content {
  padding-top: var(--page-header-height);
  position: relative;
  z-index: var(--page-content-index);
}

.has-padding {
  padding: 18px;
  padding-top: calc(var(--page-header-height) + 18px);
}

@media only screen and (max-width: 667px) {
  .has-padding {
    padding: 9px;
  }
}
