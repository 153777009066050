.tableCardWrapper {
  color: var(--color-white);
}

.tableCardWrapper:hover .header {
  background-size: 110%;
}

.ownerLabel {
  text-transform: uppercase;
  background: linear-gradient(135deg, var(--color-red), var(--color-purple));
  position: absolute;
  top: -8px;
  left: -3px;
  padding: 2px 6px;
  font-size: 12px;
  letter-spacing: 2px;
  border-radius: 6px;
  line-height: 1.4;

  @media (--sm) {
    top: -3px;
  }
}

.header {
  padding: 10px;
  background: center / 100% no-repeat var(--color-primary-dark);
  background-position: 50% 20%;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: flex-end;
  min-height: 100px;
  justify-content: space-between;
  transition: background-size 0.15s ease-in-out;

  @media (--sm) {
    padding: 20px;
  }
}

.game {
  color: var(--color-light-light);
}

.tableInfo {
  flex-grow: 1;
}

.meta {
  font-size: 14px;
  margin: 0 40px;
}

.tableCardWrapper .title {
  font-size: 16px;
  margin-bottom: 4px;
  text-align: initial;

  @media (--sm) {
    font-size: 20px;
  }
}

.tableDate {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tablePlayedLabel {
  color: var(--color-dashboard-light);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.party {
  font-weight: bold;
  color: var(--color-light-dark);
  padding: 8px 10px;
  border-radius: 0 0 12px 12px;

  @media (--sm) {
    padding: 16px 20px;
  }
}

.party-heading {
  color: var(--color-dashboard-light);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.party .label {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-dashboard-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hostLabel {
  color: var(--color-dashboard-light);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1px;
}

.partyList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 0 46px;
  margin-top: 6px;
  padding: 0;
}

.partyMember {
  display: flex;
  margin: 6px auto;
  width: 100%;
  align-items: center;
}

.partyMember .avatar {
  margin-right: 8px;
  flex-shrink: 0;
}
