.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.spacer {
  width: calc(100% - 87px) !important;
  position: absolute;
  left: 25px;
}

.buff {
  width: 54px;
  height: 54px;
  position: relative;
}

.gradient,
.background {
  position: absolute;
}

.gradient {
  inset: 0;
  z-index: 1;
}

.background {
  inset: 2px;
  z-index: 2;
  transition: opacity 0.15s;
}

.is-selected .background {
  opacity: 0.3;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 3;
}

.value,
.input {
  width: 100%;
  height: 100%;
  padding: 0 6px;
  position: relative;
  top: -1px;
  color: var(--color-white);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.input {
  background: none;
  border: none;
  outline: none;
}

.edit-button {
  position: absolute;
  top: -5px;
  right: -10px;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
}

.is-hovering .edit-button,
.is-editing .edit-button,
.content:hover + .edit-button {
  opacity: 1;
  pointer-events: auto;
}

.locked-icon {
  position: absolute;
  bottom: -5px;
  right: -10px;
}
