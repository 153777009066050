.form {
  text-align: center;
}

.form p {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1;
}

.asset {
  margin-bottom: 18px;
  display: inline-block;
}

.input,
.button {
  width: 294px;
}

.input {
  margin: 0 auto 18px;
  display: block;
}
