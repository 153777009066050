.container {
  h4 {
    color: var(--color-dashboard-dark);
  }

  p {
    margin: 5px 0 20px;
    color: var(--color-dashboard-main);
  }

  hr {
    width: 140px;
    height: 3px;
    margin: 25px auto;
    background: var(--color-light-light);
    border: none;
    border-radius: 2px;
  }

  section {
    padding: 0 16px;

    @media (--md) {
      padding: 0;
    }
  }
}

.passwordButton {
  font-size: 18px;
  color: var(--color-red);
}

.inputRow {
  width: 100%;
  display: flex;
}

.input {
  flex: 1;
}

.textarea {
  height: 100px;
  padding: 14px;
  background: var(--color-dashboard-white);
  color: var(--color-dashboard-main);
  border: 2px solid var(--color-light-grey);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  resize: none;
}

.textarea::placeholder {
  color: var(--color-text-light-grey);
}

.button {
  height: 48px;
  margin-left: 5px;

  svg {
    width: 16px;
  }

  path {
    fill: var(--color-white);
    stroke-opacity: 0;
  }
}

p.error {
  padding-left: 18px;
  color: var(--color-red);
  font-size: 14px;
  font-weight: bold;
}
