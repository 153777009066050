.container {
  color: rgb(var(--color-sheet-text));
  font-size: 14px;
  line-height: 1.4;
  transition: color ease-in-out 0.2s;
}

.container *:last-child {
  margin-bottom: 0;
}

.heading1,
.heading2 {
  font-size: 14px;
}

.heading3 {
  font-size: 12px;
}

.heading1,
.heading2,
.heading3 {
  margin-bottom: 14px;
}

.heading1 + .heading1,
.heading1 + .heading2,
.heading1 + .heading3,
.heading2 + .heading2,
.heading2 + .heading3,
.heading3 + .heading3 {
  margin-top: -10px;
}

.container a {
  color: var(--color-white);
  text-decoration: none;
}

.container a:hover,
.container a:focus {
  text-decoration: underline;
}

.container p {
  margin-bottom: 18px;
}

.container ul,
.container ol {
  margin: 0 0 18px 0;
  padding: 0 0 0 18px;
}

.container ul ul,
.container ol ol {
  margin-top: 4px;
}

.container ul li,
.container ol li {
  margin-bottom: 4px;
}

.container blockquote {
  margin: 0 0 18px 0;
  padding: 4px 18px;
  border-left: solid 4px rgb(var(--color-sheet-text) / 0.3);
}

.container blockquote p {
  margin: 0;
}

.container img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.container code {
  margin: 0;
  padding: 2px 4px;
  background: rgb(0 0 0 / 0.3);
  border: 1px solid rgb(var(--color-sheet-button));
  border-radius: 4px;
  white-space: nowrap;
}

.container pre {
  margin: 0 0 18px 0;
  padding: 4px 9px;
  background: rgb(0 0 0 / 0.3);
  border: 1px solid rgb(var(--color-sheet-button));
  border-radius: 4px;
  overflow: auto;
}

.container pre > code {
  padding: 0;
  background: none;
  border: none;
  white-space: pre;
}

.container table {
  width: 100%;
  margin-bottom: 18px;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.container thead {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.24;
  text-indent: 2.24;
}

.container thead tr,
.container tr:nth-child(even) {
  background: rgb(0 0 0 / 0.4);
}

.container tr {
  background: rgb(0 0 0 / 0.2);
}

.container th,
.container td {
  padding: 4px 9px;
  border: 1px solid rgb(var(--color-sheet-button));
  word-wrap: break-word;
}

.container th {
  font-size: 12px;
}
