.wrapper {
  max-width: 800px;
  margin: 20px auto 0;
  color: var(--color-dashboard-dark);

  h4 {
    font-size: 24px;
  }
}

.text {
  margin: 24px 0;
  font-size: 18px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.success {
    color: var(--color-dashboard-mid);
  }

  &.error {
    color: var(--color-red);
  }

  &.isNote {
    color: var(--color-primary-main);
    font-size: 14px;
  }

  .emphatic {
    color: var(--color-red);
    font-weight: bold;
  }
}

.productSelection {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  justify-content: center;

  h3 {
    flex: 1 1 100%;
    font-size: 22px;
    text-align: center;
  }

  &.isAll {
    align-items: center;
    flex-flow: column;
  }
}

.allArt {
  max-width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.coverArt {
  aspect-ratio: 8.5/11;
  background: var(--color-dashboard-light);
  border-radius: 20px;
  flex: none;
  float: left;
  overflow: hidden;
  width: 200px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.rightContent {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.productInfo {
  display: flex;
  gap: 48px;
  justify-content: center;

  h4 {
    font-size: 18px;
  }

  ul {
    font-size: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 200px;
  }

  li {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }

  .bundleIcon {
    display: inline-block;
    height: 16px;
    width: 16px;

    > * {
      fill: var(--color-dashboard-mid);
      stroke: var(--color-dashboard-mid);
    }
  }
}

.dropdown {
  max-width: 300px;

  select {
    background: var(--color-dashboard-light-grey);
    color: var(--color-light-dark);
  }

  &.isAll {
    display: none;
  }
}

.form {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.extras {
  margin-top: 24px;

  p {
    margin-bottom: 24px;
    font-size: 18px;
  }

  table {
    width: 100%;
    font-size: 14px;
    background: var(--color-white);
  }

  thead {
    text-align: left;
    background: var(--color-dashboard-light-grey);
  }

  th,
  td {
    padding: 4px;
  }

  .isRedeemed {
    td:nth-child(3) {
      color: var(--color-teal);
    }
  }
}
