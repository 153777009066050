.content {
  line-height: 1.4;

  .text {
    margin: 0 40px;
    max-width: 500px;
    text-align: center;
  }

  .body {
    display: flex;
    flex-flow: column;
    gap: 32px;
    justify-content: center;
    margin: 30px 0;

    .info {
      margin: 0 auto;
      width: auto;
    }

    ul {
      list-style: none;
      margin: 12px auto 0;
      padding: 0;
    }

    li {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: 4px;
    }

    .image {
      align-self: flex-start;
      aspect-ratio: 8.5/11;
      border-radius: 20px;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
      width: 200px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  @media (--sm) {
    .body {
      flex-flow: row;
  
      .image, .info {
        margin: 0;
      }
  
      ul {
        min-width: 200px;
      }
    }

    .text {
      margin: 0 auto;
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 30px auto 0;
  }

  button,
  a {
    display: inline-block;
    max-width: 200px;
    min-width: 170px;
  }
}
