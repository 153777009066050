.container {
  --tableview-mode-index: 1;
  --tableview-modal-index: calc(var(--tableview-mode-index) + 1);
  --tableview-ping-index: calc(var(--tableview-modal-index) + 1);

  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .smallLobby {
    position: absolute;
    height: min-content;
    background: none;
    bottom: 0;
    padding-bottom: 4px;
    margin-bottom: 16px;
    z-index: calc(var(--tableview-mode-index) + 1);
    pointer-events: none;

    button {
      margin-top: -8px;
      pointer-events: auto;
    }
  }
}

.viewMode {
  height: 100%;
  flex-grow: 1;
  position: relative;
  z-index: var(--tableview-mode-index);
}

.viewModeContent {
  z-index: 1;
}

.rolling-users {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;
  z-index: 2;
  overflow-y: auto;
  pointer-events: none;
}

.rolling-users.speakerView {
  left: 216px;
}

.rolling-users > div {
  margin-top: 9px;
}

.rolling-users > div:first-child {
  margin-top: 18px;
}

.chat {
  z-index: var(--tableview-chat-index);
}

.modal {
  z-index: var(--tableview-modal-index);
}

.ping {
  z-index: var(--tableview-ping-index);
}

.controls {
  pointer-events: none;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 40px;
  margin: 16px auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
