:root {
  --dicepool-die-size: 74px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.count {
  min-width: 48px;
  max-width: 92px;
  height: 42px;
  position: relative;
  z-index: 1;
  left: -4px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.count-background {
  position: absolute;
  inset: 0;
  z-index: 2;
  background: rgb(var(--color-sheet-base));
  border-radius: inherit;
}

.count-content {
  height: 100%;
  position: relative;
  z-index: 3;
  border-radius: inherit;
}

.count-button,
.count-input {
  width: 100%;
  height: 100%;
  padding: 0 18px;
  border-radius: inherit;
  color: var(--color-white);
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  line-height: 1;
}

.is-editing-count .count-button {
  opacity: 0;
}

.count-input {
  position: absolute;
  inset: 0;
  top: -1px;
  background: none;
  border: none;
  outline: none;
}

.locked-count-icon {
  position: absolute;
  top: 50%;
  right: -13px;
  transform: translateY(-50%);
}

.dice {
  width: var(--dicepool-die-size);
  height: var(--dicepool-die-size);
  position: relative;
  z-index: 2;
}

.dice-gradient,
.dice-background,
.spacer {
  position: absolute;
}

.spacer {
  width: calc(100% - (var(--dicepool-die-size) - 10px));
  top: 50%;
  left: calc(var(--dicepool-die-size) - 10px);
  transform: translateY(-50%);
}

.dice-gradient {
  inset: 0;
  z-index: 1;
}

.dice-background {
  inset: 2px;
  z-index: 2;
  transition: opacity 0.15s;
}

.is-selected .dice-background {
  opacity: 0.3;
}

.dice-content {
  width: var(--dicepool-die-size);
  height: var(--dicepool-die-size);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.die-button {
  --die-color: rgb(var(--color-theme-text));
  width: var(--dicepool-die-size);
  height: var(--dicepool-die-size);
  position: absolute;
  top: 0;
  left: 0;
  color: var(--die-color);
  opacity: 0;
}

.die-button.is-selected,
.is-editing-die .die-button:hover {
  opacity: 1 !important;
}

.is-editing-die .die-button {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.die-icon {
  width: 30px;
  height: 30px;
  margin: 0 auto 3px;
  display: block;
}

.die-icon path {
  fill: var(--die-color);
  stroke-opacity: 0;
}

.die-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-white);
}

.edit-button {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
}

.is-hovering .edit-button,
.is-editing .edit-button,
.content:hover + .edit-button {
  opacity: 1;
  pointer-events: auto;
}

.locked-die-icon {
  position: absolute;
  bottom: -5px;
  left: -5px;
  z-index: 4;
}

.diceSelector {
  max-width: calc(var(--dicepool-die-size) * 3);
  background: var(--color-black);
  border-radius: 12px;
  z-index: 9999;

  hr {
    width: calc(100% - 14px);
    margin: 8px auto;
    border: none;
    border-top: 1px solid rgb(255 255 255 / 0.3);
  }

  &:not(.isBuilding) {
    .notEditable {
      opacity: 0.25;
      cursor: not-allowed;

      &:hover {
        opacity: 0.25;
      }
    }
  }
}

.dieOptionButton {
  --die-color: rgb(var(--color-theme-text));
  width: var(--dicepool-die-size);
  height: var(--dicepool-die-size);
  color: var(--die-color);
  opacity: 0.5;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.diceColorPicker {
  max-width: calc(var(--dicepool-die-size) * 3 - 16px);
  margin: 8px auto 0 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 8px;
  background-color: rgb(var(--color-theme-base) / 0.8);

  input {
    width: 20px !important;
    height: 20px !important;
  }
}

.addButton {
  width: calc(100% - 20px);
  margin: 0 10px 10px;
  display: block;
}
