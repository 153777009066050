.container {
  height: 100%;
  padding: 20px;
  position: relative;
  font-size: 18px;
  transition: opacity 0.3s ease-in-out;
}

.sheetSelectorSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.content {
  padding: 20px;
  position: absolute;
  inset: 0;
  top: 0;
  overflow-y: auto;

  &.isActive {
    top: 52px;

    @media (--md) {
      &:after,
      &:before {
        background: linear-gradient(rgb(var(--color-theme-base)), transparent);
        content: '';
        height: 40px;
        left: 0;
        pointer-events: none;
        position: fixed;
        top: 119px;
        width: calc(var(--sheet-width) - var(--layout-scrollbar-width));
        z-index: 4;
      }

      &:after {
        background: url('../../images/Noise.png'), rgb(var(--color-theme-base));
        background-blend-mode: soft-light;
        mask: linear-gradient(black, transparent);
        opacity: 0.3;
        z-index: 3;
      }
    }
  }
}

.heading {
  font-size: 24px;
  text-align: center;
}

.subtitle {
  color: rgb(var(--color-theme-text));
  margin-bottom: 20px;
  text-align: center;
}

.ddbTooltipContainer {
  position: relative;
}

.ddbTooltip {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-white);

  @media (--md) {
    right: 40px;
  }
}

.infoTooltip > div:first-child {
  z-index: 4;
}

.divider {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
}

.divider span {
  padding: 0 10px;
  text-transform: uppercase;
}

.hr {
  height: 3px;
  flex: 0 1 100%;
  background: rgb(var(--color-theme-button));
  border-radius: 2px;
  border: none;
}

.selectorForm {
  padding: 0 40px;
}

.dropdown,
.textInput {
  margin-bottom: 10px;
}

.textInput {
  background: rgb(0 0 0 / 0.3);
  color: var(--color-white);
  border: none;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  padding: 0 18px;
  outline: none;
}

.textInputError {
  border: 1px solid var(--color-red);
}

.ddbError {
  text-align: center;
  margin: 0 20px;
  color: rgb(var(--color-theme-text));
}

.ctaButton {
  width: 100%;
  margin-bottom: 20px;
}

.premadeSheets {
  margin-bottom: 40px;
}

.premadeSheet {
  display: flex;
  align-items: center;
  position: relative;
  background-color: rgb(var(--color-theme-button));
  height: 60px;
  margin-bottom: 10px;
  border-radius: 18px 10px 10px 18px;
  border: 2px solid rgb(var(--color-theme-button));
  transition: border-color 0.15s ease-in-out;
}

.premadeSheet:hover,
.premadeSheet:focus {
  border-color: rgb(var(--color-theme-text) / 0.5);
}

.avatar {
  position: absolute;
  left: -2px;
}

.premadeName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  text-align: start;
  margin-left: 70px;
  font-weight: bold;
}

.playButton {
  margin: 0 10px;
}

.cancelButton {
  margin-top: 10px;
  text-align: center;
}

.info {
  max-width: 60%;
  margin: 20px auto 0;
  color: rgb(var(--color-theme-text));
  text-align: center;

  a {
    color: rgb(var(--color-theme-text));

    &:hover {
      color: rgb(var(--color-theme-accent));
    }
  }
}
