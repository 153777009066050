.info-icon {
  width: 18px;
  height: 18px;
  margin-left: 14px;
  background: var(--color-white);
  position: relative;
  border-radius: 50%;
}

.info-icon svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-icon path {
  fill: rgb(var(--color-theme-accent));
  stroke-opacity: 0;
}

.info-tooltip {
  padding: 4px 14px;
  position: absolute;
  bottom: 29px;
  right: -10px;
  background: var(--color-black);
  border-radius: 6px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.66px;
  line-height: 1.5;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.info-tooltip::after {
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  right: 14px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid var(--color-black);
  content: '';
}

.info-icon:hover .info-tooltip {
  opacity: 1;
}
