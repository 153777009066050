.container {
  --asset-image-index: 1;
  --asset-overlay-index: calc(var(--asset-image-index) + 1);
  --asset-menu-index: calc(var(--asset-overlay-index) + 1);

  margin: 9px;
  position: relative;
  color: var(--color-dark-text);
  text-align: center;
  cursor: pointer;
  transition: color 0.2s;
  outline: none; /* TODO: Make accessible */
}

.container.is-shared {
  color: var(--color-text-light-grey);
}

.container:hover {
  color: var(--color-red);
}

.container.is-image {
}

.container.is-app {
}

.container.is-pdf {
}

.container.is-map {
}

.container.is-token {
}

.externalUrlIndicator {
  position: absolute;
  z-index: var(--asset-overlay-index);
  color: var(--color-dashboard-mid);
  background-color: var(--color-dashboard-white);
  border-radius: 8px;
  padding: 6px;
  right: 6px;
  top: 6px;
}

.externalUrlIndicator svg {
  height: 14px;
  width: 14px;
  fill: currentColor;
}

.thumbnail {
  height: 144px;
  margin-bottom: 9px;
  display: inline-block;
  position: relative;
  border: 1px solid var(--color-light-grey);
}

.thumbnail > img {
  height: 100%;
  position: relative;
  z-index: var(--asset-image-index);
  max-width: 100%;
  object-fit: contain;
}

.container.is-token .thumbnail {
  height: 72px;
  border: none;
  box-shadow: none;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--asset-overlay-index);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.container.is-token .overlay {
  display: none;
}

.title {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}

.type,
.ownership {
  font-size: 10px;
}

.ownership {
  margin-top: 2px;
  font-weight: bold;
}

.menu {
  position: absolute;
  top: -10px;
  left: 50%;
  z-index: var(--asset-menu-index);
  background: var(--color-black);
  border-radius: 9px;
  border: 1px solid var(--color-text-dark-grey);
  text-align: left;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
}

.menu-item {
  height: 30px;
}

.menu-border {
  margin: 0 9px;
  border-bottom: 1px solid var(--color-text-dark-grey);
}

.menu-item:last-child .menu-border {
  border-bottom: none;
}

.menu-link {
  width: 100%;
  padding: 9px;
  display: block;
  color: var(--color-text-light-grey);
  font-size: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.menu-link:hover,
.menu-link:focus {
  color: var(--color-light-text);
}

.menu-link span {
  text-transform: none;
  letter-spacing: normal;
}
