@import url('https://use.typekit.net/srb2cwc.css');

@custom-media --sm (min-width: 640px);
@custom-media --md (min-width: 768px);
@custom-media --lg (min-width: 1024px);
@custom-media --xl (min-width: 1280px);

:root {
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;

  /* Colors are declared in models/Color.js and set in App.js */
  --color-theme-base: 26 26 38; /* #1a1a26 (dark.mid) */
  --color-theme-button: 39 39 66; /* #272742 (primary.dark) */
  --color-theme-text: 124 121 152; /* #7c7998 (dark.accent) */
  --color-theme-accent: 143 129 241; /* #8f81f1 (purple) */

  --color-sheet-base: var(--color-theme-base);
  --color-sheet-button: var(--color-theme-button);
  --color-sheet-text: var(--color-theme-text);
  --color-sheet-accent: var(--color-theme-accent);

  --page-logo-bar-height: 18px;
  --page-header-bar-height: 54px;
  --page-header-height: calc(var(--page-logo-bar-height) + var(--page-header-bar-height));
  --sheet-builder-width: 500px;
  --sheet-width: 480px;
  --sheet-toc-width: 240px;
  --sheet-collapsed-offset: 60px;
  --box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.35);
  --dark-panel-gradient: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
  --light-panel-gradient: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  --play-panel-width: 360px;

  --layout-topbar-height: 26px;
  --layout-rainbowbar-height: 3px;
  --layout-navbar-height: 74px;
  --layout-border-height: 2px;
  --layout-header-height: calc(
    var(--layout-topbar-height) + var(--layout-rainbowbar-height) + var(--layout-navbar-height) +
      var(--layout-border-height)
  );
  --layout-room-controls-height: 64px;
  --layout-sidebar-width: 240px;
  --layout-room-tabs-height: 60px;
  --layout-scrollbar-width: 6px;

  --font-alphapipe: 'bc-alphapipe', 'Helvetica', 'Arial', 'sans-serif';
  --font-europa: 'europa', 'Helvetica', 'Arial', 'sans-serif';
  --heading-letter-spacing: 5px;

  --page-content-index: 1;
  --page-header-index: 2;

  /* Transformations and Transitions */
  --transition-ease-in-out: 0.15s ease-in-out;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.page-wrapper {
  height: 100vh;
  height: 100dvh;
}

html {
  background: var(--color-black) url(../images/RoleBackground.jpg) center top no-repeat fixed;
  background-size: cover;
}

body {
  font-size: 12px;
  color: var(--color-white);

  &.is-showing-fte,
  &.is-room-page.is-touching {
    overflow: hidden;
  }
}

body,
input {
  font-family: var(--font-europa);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.heading1,
.heading2,
.heading3 {
  text-transform: uppercase;
  letter-spacing: var(--heading-letter-spacing);
}

.heading1.is-centered,
.heading2.is-centered,
.heading3.is-centered {
  text-indent: var(--heading-letter-spacing);
}

.heading1 {
  font-family: var(--font-alphapipe);
  font-weight: bold;
}

.heading2 {
  font-family: var(--font-europa);
  font-weight: bold;
}

.heading3 {
  font-family: var(--font-europa);
  font-weight: normal;
}

.text-overflow {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.clearfix:after {
  display: table;
  content: '';
  clear: both;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.box-shadow {
  box-shadow: var(--box-shadow);
}

.button-reset {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  outline: none; /* TODO: make this accessible */
}

/* remove this in favor of the Button component */
.button {
  height: 36px;
  padding: 0 36px;
  background: var(--color-red);
  border: none;
  border-radius: 18px;
  box-shadow: var(--box-shadow);
  color: var(--color-light-text);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.button.secondary {
  background: var(--color-teal);
}

.input {
  height: 36px;
  padding: 0 18px;
  background: var(--color-dark-grey);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 18px;
  box-shadow: var(--box-shadow);
  color: var(--color-light-text);
  font-size: 14px;
  font-weight: bold;
}

.input.is-light {
  background: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: var(--color-dark-text);
}

.select-input {
  max-width: 200px;
  height: 36px;
  padding: 0 18px;
  background: var(--color-dark-grey);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var(--box-shadow);
  color: white;
  font-size: 14px;
  font-weight: bold;
  appearance: none;
  outline: none;
}

.scrollbars-dark {
  scrollbar-height: thin;
  scrollbar-width: thin;
  scrollbar-color: rgb(0 0 0 / 0.1);
}

.scrollbars-dark::-webkit-scrollbar {
  height: var(--layout-scrollbar-width);
  width: var(--layout-scrollbar-width);
}

.scrollbars-dark::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
}

.scrollbars-dark::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.scrollbars-dark::-webkit-scrollbar-track:hover {
  background: rgb(var(--color-theme-button));
}

.scrollbars-dark::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(var(--color-theme-text) / 0.3);
}

.scrollbars-dark::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-theme-text));
}

.bundle-icon {
  display: inline-block;
  height: 16px;
  width: 16px;

  > * {
    fill: var(--color-dashboard-mid);
    stroke: var(--color-dashboard-mid);
  }
}
