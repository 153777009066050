.logo {
  width: 107px;
  height: 110px;
  margin: 0 auto 36px;
  background: url(../../images/RoleLogo_White.png) no-repeat 0 0;
  background-size: contain;
}

.subtitle {
  color: var(--color-white);
  font-family: 'bc-alphapipe';
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 36px;
}

.subtitle-public-beta {
  margin-bottom: 18px;
  font-size: 14px;
  text-align: center;
}

.content {
  max-width: 580px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  padding: 20px 40px;
  border-radius: 36px;
  position: relative;
  background: var(--color-white);
  color: var(--color-dark-text);
}

.intro,
.message,
.form {
  margin-bottom: 18px;
}

.intro {
  color: var(--color-dashboard-mid);
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}

.signUpForm {
  margin: 0 auto;
  max-width: 360px;
  text-align: left;
}

.signUpRule {
  color: var(--color-dashboard-mid);
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
  margin-top: 4px;
  height: 0;
  opacity: 0;
  transition: height var(--transition-ease-in-out), opacity var(--transition-ease-in-out);
}

.signUpRule.activeRule {
  height: auto;
  opacity: 1;
}

.intro-weekend {
  margin-bottom: 9px;
}

.form:last-child {
  margin-bottom: 0;
}

.input {
  margin-bottom: 18px;
}

.simpleInput:hover,
.simpleInput:active,
.simpleInput:focus,
.simpleInput:focus-visible {
  color: var(--color-dashboard-mid);
  outline: none;
  border: solid 2px var(--color-dashboard-light-gray);
}

.fieldContainer {
  margin-bottom: 30px;
}

.file-input {
  width: 90px;
  margin: 0 auto 18px;
}

.message {
  margin-top: -12px;
  padding: 0 18px;
}

.error {
  margin-bottom: 18px;
  color: var(--color-red);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.footer-link {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
}

.footer-link a,
.footer-link a:visited {
  text-decoration: none;
  color: var(--color-dashboard-main);
}

.footer-link .highlight {
  color: var(--color-dashboard-dark);
  font-weight: 700;
}

.footer-link a:hover .highlight,
.footer-link a:focus .highlight {
  color: var(--color-red);
}

.link {
  text-decoration: none;
  color: var(--color-dashboard-main);
}

.link:visited {
  color: var(--color-dashboard-main);
}

.forgotLink {
  display: block;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-align: right;
}

.forgotLink:hover,
.forgotLink:focus {
  color: var(--color-dashboard-mid);
}

.is-light {
  color: var(--color-light-text);
}

.is-dark {
  color: var(--color-text-grey);
}

.button {
  margin-bottom: 9px;
  display: block !important;
}

.button:last-child {
  margin-bottom: 0;
}

.ctaButton {
  font-size: 16px;
  height: 46px;
  margin-bottom: 40px;
  width: 100%;
}

.striked {
  color: var(--color-text-light-grey);
  text-decoration: line-through;
  font-weight: bold;
}

.price {
  color: var(--color-teal);
  font-weight: bold;
}

.copyright {
  font-size: 14px;
  margin: 18px auto;
  max-width: 300px;
  text-align: center;
  
  .link {
    display: inline-block;
    padding: 4px 6px;

    &:first-of-type:after {
      border-right: 1px solid var(--color-text-light-grey);
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 -2px 12px;
      width: 1px;
    }

    &:not(:last-child) {
      margin-top: 6px;
    }
  }

  @media (--sm) {
    max-width: 100%;

    .link:not(:last-child):after {
      border-right: 1px solid var(--color-text-light-grey);
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 -2px 12px;
      width: 1px;
    }
  }
}

@media only screen and (max-width: 667px) {
  .subtitle {
    font-size: 14px;
  }
}

.signUpHeader {
  text-align: center;
  margin: 10px 20px 40px 20px;
}

.signUpHeader h1 {
  color: var(--color-dashboard-dark);
  font-size: 32px;
}

.signUpHeader span {
  color: var(--color-dashboard-main);
  font-size: 22px;
  line-height: 30px;
}

.authFormContainer {
  margin: 0 auto;

  .fieldContainer {
    width: 100%;
  }
}

.authFormTabs {
  margin-bottom: 20px;
}
