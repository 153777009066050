.advancedOptionsPanel {
  font-size: 18px;
  color: rgb(var(--color-theme-text));
}

.advancedOptionsPanel header {
  margin-bottom: 35px;
  position: relative;
  text-align: center;
}

.headerHeading {
  margin-bottom: 4px;
  color: var(--color-white);
  font-size: 20px;
  text-transform: capitalize;
}

.headerDescription {
  padding: 0 30px;
  line-height: 1.4;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.advancedOption {
  border-radius: 24px;
  width: 100%;
  background-color: rgb(var(--color-theme-base));
  padding: 20px 25px;
  text-align: initial;
  margin-bottom: 20px;
}

.labelBar {
  display: flex;
  margin-bottom: 18px;
}

.advancedOption .description {
  margin-bottom: 30px;
  line-height: 1.4;
}

.advancedOption .description:last-child {
  margin-bottom: 0;
}

.labelBar .switch {
  margin-right: 8px;
}

.labelBar label {
  flex-grow: 1;
}

.optionLabel {
  flex-grow: 1;
  color: var(--color-white);
  font-weight: bold;
}

.labelBar svg {
  width: 14px;
  height: 14px;
  fill: rgb(var(--color-theme-text));
  position: relative;
  top: 4px;
}

.labelBar path {
  stroke-opacity: 0;
  fill: rgb(var(--color-theme-text));
}

.advancedOptionsPanel .link {
  color: var(--color-white);
  background-color: var(--color-black);
  height: 30px;
  font-size: 14px;
  padding: 0px 12px;
  cursor: pointer;
  display: inline-flex;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  position: relative;
}

.advancedOptionsPanel .linkWrapper {
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, var(--color-red), var(--color-purple));
  height: 34px;
  display: inline-block;
  margin-bottom: 10px;
}

.advancedOptionsPanel .link button {
  height: 22px;
  width: 22px;
  display: inline-flex;
  padding: 6px;
  opacity: 0;
  transition: opacity ease-out 0.3s;
  position: absolute;
  left: 8px;
}

.advancedOptionsPanel .link:hover button {
  margin-left: -4px;
  opacity: 1;
}

.advancedOption .linkName {
  padding-left: 0;
  transition: padding ease-out 0.3s;
}

.advancedOption .link:hover .linkName {
  padding-left: 20px;
}

.advancedOption .linkBuffer {
  margin: 0 16px;
  color: var(--color-white);
  font-size: 28px;
}

.iconToggle {
  background: var(--color-black);
  border: 2px solid rgb(var(--color-theme-text) / 0.5);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconToggle:before {
  content: '';
  background: rgb(var(--color-theme-text));
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.iconCounterMax {
  background: rgb(var(--color-theme-text) / 0.3);
  width: 30px;
  height: 10px;
  border-radius: 2px;
  padding-left: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.iconCounterMax:before {
  content: '';
  background: rgb(var(--color-theme-text));
  width: 20px;
  height: 6px;
  border-radius: 2px;
}
