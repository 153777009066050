.form {
  width: 350px;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 18px;
  display: block;
}

.label {
  margin-bottom: 9px;
  display: block;
  font-size: 14px;
  text-align: center;
}

.description {
  margin-bottom: 18px;
  display: block;
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
}

.button {
  width: 100%;
}

.label {
  font-weight: bolder;
  font-size: 14px;
  display: block;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 18px;
}

.colorPicker {
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;

  .colorExampleContainer {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .colorSpread {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .colorRow {
    display: flex;
    gap: 10px;
  }

  .color {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  .selectedColorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selectedColor {
    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
    border-radius: 50%;
    height: 14px;
    width: 14px;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  @media (--md) {
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .colorExampleContainer {
      margin-bottom: 0;
      margin-left: 25px;
    }
  }
}
