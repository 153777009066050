.templateCardWrapper {
  border-radius: 40px 12px 12px 40px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0px 0 rgb(0 0 0 / 5%);
  position: relative;
  color: var(--color-light-main);
  transition: box-shadow 0.15s ease-in-out;
  background-color: var(--color-white);
}

.templateCardWrapper.has-border,
.templateCardWrapper:hover {
  box-shadow: 0px 0px 0 2px var(--color-light-main);
}

.templateCardWrapper .avatar {
  position: absolute;
  left: -2px;
  top: -2px;
}

.templateBody {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 16px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tags strong {
  font-weight: bold;
}

.infoContainer {
  min-width: min-content;
  text-align: left;
}

.infoContainer label {
  font-weight: bold;
  display: block;
}

.infoContainer span {
  color: var(--color-dashboard-dark);
}

.userAvatarContainer {
  display: flex;
  align-items: center;
  z-index: 2;
  gap: 0 4px;
  margin-bottom: 12px;
}

.userAvatar {
  flex-shrink: 0;
}

.templateUsername {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.templateCardWrapper .name {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 4px;
  text-align: initial;
  color: var(--color-light-dark);
  word-wrap: break-word;
}

.templateCard {
  display: flex;
  background-color: var(--color-white);
  border-radius: 40px 12px 12px 40px;
  min-height: 136px;
  position: relative;
}

.templateCardInfo {
  margin: 14px 18px 8px 150px;
  flex-grow: 1;

  .isSmall & {
    margin-left: 110px;
  }
}

.header {
  background-color: var(--color-white);

  @media (--md) {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 15px;
  }
}

.templateInfo {
  min-width: 0;
  font-size: 15px;
  color: var(--color-dashboard-main);
  line-height: 20px;
}

.templateInfo {
  color: var(--color-dashboard-main);
}

.templateActions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media (--md) {
    margin-top: 0;
    align-items: stretch;
    flex-direction: column;
  }
}

.editButton {
  margin-bottom: auto;
  border: 2px solid var(--color-light-light);
}

.editButton:hover {
  border: 2px solid var(--color-accent-main);
}

.templateActionButton {
  padding: 0 12px 0 6px;
}

.deleteButton {
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 50%;
  opacity: 0;
  border: 2px solid var(--color-white);
  padding: 5px;
}

.templateCardWrapper:hover .deleteButton,
.templateCardWrapper:focus .deleteButton {
  opacity: 1;
}

.publicContainer {
  margin-left: 8px;
}
