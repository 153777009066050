.container {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.heading {
  font-size: 18px;
  font-weight: bold;
}

.heading a {
  color: var(--color-teal);
  text-decoration: none;
}

.author {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
}

.copy {
  margin-bottom: 18px;
  font-size: 16px;
}

.copy:last-child {
  margin-bottom: 0;
}

.close-button {
  max-width: 300px;
}
