.container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.row {
  margin-bottom: -12px;
  display: flex;
}

.row:last-child {
  margin-bottom: 0;
}

.row.is-odd {
  margin-left: 16px;
}

.slot {
  width: 20px;
  height: 20px;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  border: 2px solid rgb(var(--color-sheet-text) / 0.7);
  border-radius: 5px;
  transform: rotate(45deg);
  cursor: pointer;
  transition: border-color 0.15s;
}

.container.read-only .slot {
  cursor: auto;
}

.container:not(.read-only) .slot:hover,
.container:not(.read-only) .slot:focus {
  border-color: rgb(var(--color-sheet-text));
}

.input {
  width: 0;
  height: 0;
  display: block;
  opacity: 0;
}

.gradient {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.1s;
}

.input:checked + .gradient {
  opacity: 1;
}

.stepper {
  margin-bottom: 12px;
}

.edit-button {
  margin-top: 2px;
  flex-shrink: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.is-hovering .edit-button,
.is-editing .edit-button,
.content:hover + .edit-button {
  opacity: 1;
  pointer-events: auto;
}
