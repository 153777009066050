.container {
  height: 100%;
  display: flex;
}

.left,
.right,
.center {
  height: 100%;
  display: flex;
}

.left,
.right {
  flex: 1;
  align-items: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  flex-direction: column;
  justify-content: center;
}

.title,
.subtitle {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
}

.title {
  background-image: linear-gradient(170deg, var(--color-red), var(--color-purple));
  background-clip: text;
  color: transparent;
  font-family: var(--font-alphapipe);
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.8px;
  text-indent: 2.8px;
}

.subtitle {
  color: var(--color-dashboard-main);
  font-family: var(--font-europa);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.96px;
  text-indent: 1.96px;
}

.logo {
  height: 32px;
  object-fit: contain;
}
