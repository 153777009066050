/* CR keyframes */

@keyframes mattFadeIn {
  0% {
    opacity: 0;
    top: -360px;
  }
  100% {
    opacity: 1;
    top: -260px;
  }
}

@keyframes mattQuote {
  0%,
  50% {
    opacity: 0;
    left: -20px;
  }
  100% {
    opacity: 1;
    left: -120px;
  }
}

@keyframes nottFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes nottQuote {
  0%,
  50% {
    opacity: 0;
    top: -260px;
  }
  100% {
    opacity: 1;
    top: -160px;
  }
}

@keyframes grogFadeIn {
  0% {
    opacity: 0;
    left: -29px;
  }
  100% {
    opacity: 1;
    left: -129px;
  }
}

@keyframes grogQuote {
  0%,
  50% {
    opacity: 0;
    left: 460px;
  }
  100% {
    opacity: 1;
    left: 360px;
  }
}

@keyframes jesterFadeIn {
  0% {
    opacity: 0;
    left: -740px;
  }
  100% {
    opacity: 1;
    left: -840px;
  }
}

@keyframes jesterQuote {
  0%,
  50% {
    opacity: 0;
    top: -220px;
  }
  100% {
    opacity: 1;
    top: -120px;
  }
}

@keyframes caduceusFadeIn {
  0% {
    opacity: 0;
    right: -740px;
  }
  100% {
    opacity: 1;
    right: -640px;
  }
}

@keyframes caduceusQuote {
  0%,
  50% {
    opacity: 0;
    left: -30px;
  }
  100% {
    opacity: 1;
    left: -130px;
  }
}

@keyframes peen {
  0% {
    height: 0px;
    opacity: 1;
  }
  33% {
    height: 103px;
    opacity: 1;
  }
  66% {
    height: 103px;
    opacity: 1;
  }
  67% {
    height: 103px;
    opacity: 1;
  }
  99% {
    height: 103px;
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes pulsar {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    transform: scale(0.8);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
  }
}

.fteContainer {
  border-radius: 8px;
  background: rgb(var(--color-theme-base));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
  z-index: 99;
  transform-style: preserve-3d;

  .pulsar {
    height: 110px;
    width: 110px;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: pulsar;
    border: 6px solid var(--color-purple);
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    pointer-events: none;
  }

  .avatar {
    height: 200px;
    padding: 10px;
  }

  .fteCopy {
    padding: 8px;
    font-size: 16px;
    max-width: 250px;

    &.wideCopy {
      max-width: 350px;
    }
  }

  .titleControls {
    position: relative;
    width: 100%;

    & h3 {
      font-size: 20px;
      text-align: center;
    }

    svg {
      height: 12px;
      width: 12px;
      fill: white;
    }

    .backButton {
      position: absolute;
      left: 2px;
      top: 0;
    }

    .forwardButton {
      transform: rotate(180deg);
      position: absolute;
      right: 2px;
      top: 0;
    }
  }

  [data-popper-arrow],
  [data-popper-arrow]::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  [data-popper-arrow] {
    visibility: hidden;
  }

  [data-popper-arrow]::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: -4px;
  }

  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -4px;
    top: 10px;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -4px;
    top: 10px;
  }
}

.completedMainCopy {
  text-align: center;
}

.nextStepsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  .nextStep {
    text-align: center;
    padding: 0 20px;

    & a {
      color: var(--color-primary-light);

      &:visited {
        color: var(--color-primary-main);
      }
    }

    & h3 {
      margin-bottom: 10px;
    }
  }

  svg {
    height: 50px;
    width: 50px;
    margin: 0 auto 15px;
    fill: var(--color-primary-main);
  }
}

.completedActions {
  text-align: center;
}

/* CR specific */
img.character {
  position: absolute;
  pointer-events: none;
  max-width: 1000px;
  filter: drop-shadow(0 0 30px #1c1c2670) drop-shadow(0 0 25px #1c1c2670) drop-shadow(0 0 20px #1c1c2670)
    drop-shadow(0 0 15px #1c1c2670);

  &.nott {
    animation: nottFadeIn 2s;
    left: -35px;
    top: -90px;
    transform: translateZ(-10px);
  }

  &.jester {
    animation: jesterFadeIn 2s;
    left: -840px;
    top: -70px;
  }

  &.caduceus {
    animation: caduceusFadeIn 2s;
    left: -680px;
    top: -260px;
    transform: scaleX(-1);
  }

  &.matt {
    animation: mattFadeIn 2s;
    top: -260px;
    left: -644px;
    transform: scaleX(-1);
  }

  &.grog {
    animation: grogFadeIn 2s;
    left: -129px;
    top: -90px;
    max-width: 800px;
    transform: scaleX(-1) translateZ(-10px);
  }
}

.penisWrapper {
  position: absolute;
  left: 20px;
  top: 106px;
  max-width: 50px;
  height: 0;
  overflow: hidden;
  opacity: 1;
  animation: peen 6s 2s;
  transform: scaleX(-1);

  > img.penis {
    width: 50px;
    height: 103px;
    position: relative;
    left: 15px;
  }
}

.quote {
  transform-style: preserve-3d;
  position: absolute;
  margin: 0;
  color: var(--color-dark-mid);

  > svg {
    transform: translateZ(-10px);
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .quoteCopy {
    font-size: 16px;
  }

  &.matt {
    transform: translateZ(-10px);
    animation: mattQuote 2.5s;
    top: -380px;
    left: -120px;
    width: 330px;
    height: 210px;

    .quoteCopy {
      padding: 40px 70px;
    }
  }

  &.nott {
    animation: nottQuote 2.5s;
    top: -160px;
    left: 130px;
    width: 230px;
    height: 150px;

    > svg {
      width: 230px;
      height: 150px;
      transform: scaleX(-1) translateZ(-10px);
    }

    .quoteCopy {
      padding: 28px 35px 25px 52px;
    }
  }

  &.grog {
    animation: grogQuote 2.5s;
    top: -84px;
    left: 360px;
    width: 270px;
    height: 206px;

    > svg {
      width: inherit;
      height: inherit;
    }

    .quoteCopy {
      padding: 40px 45px 25px;
    }
  }

  &.jester {
    animation: jesterQuote 2.5s;
    top: -120px;
    left: -300px;
    width: 280px;
    height: 206px;

    > svg {
      width: 280px;
      height: 206px;
    }

    .quoteCopy {
      padding: 36px 39px 25px;
    }
  }

  &.caduceus {
    animation: caduceusQuote 2.5s;
    top: -250px;
    left: -130px;
    width: 240px;
    height: 156px;

    > svg {
      width: inherit;
      height: inherit;
    }

    .quoteCopy {
      padding: 28px 50px 25px;
    }
  }
}

.modalOverflow {
  overflow-y: initial;
}

/* Room FTE Styles */

.newFTE {
  margin: 100px auto 0;
  max-width: 700px;
  padding: 0 10px;
  transform: translateZ(1px);

  > h3 {
    text-align: center;
    text-transform: uppercase;
    color: var(--color-light-200);
    letter-spacing: 2.6px;
    text-indent: 2.6px;
  }

  > h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: var(--font-alphapipe);
    letter-spacing: 1.3px;
    text-indent: 1.3px;
    color: var(--color-white);
  }

  .noGamePicked {
    margin-top: 12px;
    text-align: center;
    font-size: 14px;
    color: var(--color-light-200);
  }

  .skipRoomButton {
    margin: 50px auto 0;
    display: block;
    padding: 0 10px;
    border-color: var(--color-primary-main);
  }
}

.comboInputWrapper {
  overflow: hidden;
  position: relative;
  width: 90%;
  border-radius: 8px;
  z-index: 1;
  margin: 0 auto;

  &.comboInputWrapperOpen {
    border: 1px solid var(--color-primary-light);
  }

  .comboButton {
    width: 36px;
    height: 32px;
    background: var(--color-light-300);
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &.comboButtonToggleOpen {
      background: var(--color-primary-light);
    }

    svg {
      width: 8px;
    }
  }

  .clearButton {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .comboInput {
    height: 42px;
    padding: 5px 45px 5px 16px;
    color: var(--color-light-200);
    font-size: 15px;
    line-height: 1;
    width: 100%;
    border-style: none;
    background: var(--color-primary-dark);

    &::placeholder {
      color: var(--color-dark-accent);
    }

    &:active,
    &:focus {
      outline: none;
    }

    &.styledComboInput {
      background: rgb(var(--color-theme-base));

      &::placeholder {
        color: rgb(var(--color-theme-text));
      }
    }
  }
}

.comboOptionsWrapper {
  background: rgb(var(--color-theme-base));
  position: relative;
  top: -53px;
  padding: 50px 10px 0;
  border-radius: 24px;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 30%);
}

.comboOptions {
  padding: 0;
  background: rgb(var(--color-theme-base));
  max-height: 600px;
  overflow: auto;
  border-radius: 24px;
}

.comboOption {
  list-style: none;
  cursor: pointer;

  &:hover .gameDetailCard {
    box-shadow: inset 0 0 0 1px rgb(var(--color-theme-accent));
  }
}

.gameDetailCard {
  background: rgb(var(--color-theme-base));
  border-radius: 24px;
  padding: 10px;
  display: grid;
  max-height: initial;
  grid-template-columns: 85px 2fr;
  gap: 10px;
  color: rgb(var(--color-theme-text));
  margin: 0 auto 10px;
  max-width: 680px;
  position: relative;
  transition: box-shadow 0.15s ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    background: #ffffff10;
    inset: 0;
    border-radius: 24px;
  }

  &.gameActive {
    box-shadow: inset 0 0 0 1px rgb(var(--color-theme-accent));

    &::before {
      opacity: 1;
    }
  }

  .coverArt {
    aspect-ratio: 8.5 / 11;
    transform: translateZ(1px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .gameMeta {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    margin: 0 4px;
    order: 3;
    grid-column: auto / span 2;
    transform: translateZ(1px);

    &.defaultGameMeta > h2 {
      flex-grow: 1;
    }

    h2 {
      font-size: 16px;
      color: var(--color-white);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      margin-bottom: 5px;
    }

    > span {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      flex-grow: 1;
      letter-spacing: 1.3px;
    }

    .gameMetaTags {
      display: flex;
      gap: 5px;
      margin: 6px 0;
      align-items: flex-start;
      flex-flow: row wrap;

      .gameMetaTag {
        padding: 0px 7px;
        background: black;
        align-items: center;
        display: flex;
        height: 25px;
        border-radius: 8px;
      }
    }

    .playButton {
      height: 22px;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .defaultGameCopy > p {
    color: var(--color-light-200);
    font-size: 16px;
  }

  .previewGameBundle {
    padding: 9px 12px;
    border-radius: 12px;
    background: rgb(var(--color-theme-button));
    text-align: left;
    transform: translateZ(1px);

    > h4 {
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 12px;
      letter-spacing: 1.3px;
    }

    .productContentList {
      line-height: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .productItem {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      font-size: 12px;

      &:nth-child(n + 7) {
        margin-bottom: 0;
      }

      svg {
        margin-right: 11px;
        height: 8px;
        width: 8px;

        > path {
          fill: rgb(var(--color-theme-text));
        }
      }
    }
  }
}

.selectedGame {
  > h3,
  > h1 {
    text-align: center;
    text-transform: uppercase;
  }

  > h3 {
    margin: 10px auto 30px;
  }

  > h1 {
    margin: 34px auto;
    font-family: var(--font-alphapipe);
    letter-spacing: 1.3px;
    text-indent: 1.3px;
  }

  .selectedGameWrapper {
    width: 90%;
    margin: 0 auto;
  }
}

/* Transitions */
.firstEnter {
  transition: opacity 1s ease-in-out;
}

.firstEnterFrom {
  opacity: 0;
}

.firstEnterTo {
  opacity: 1;
}

@media (--md) {
  .gameDetailCard {
    grid-template-columns: 85px 40% 1fr;
    max-height: 133px;

    &.defaultGameCard {
      grid-template-columns: 85px 30% 1fr;
    }

    .gameMeta {
      grid-column: initial;
      order: initial;

      .playButton {
        position: static;
        right: initial;
      }
    }
  }
}
