.form {
  text-align: center;
}

.container {
  --edittokenassetmodal-grid-index: 1;
  --edittokenassetmodal-canvas-index: calc(var(--edittokenassetmodal-grid-index) + 1);
  --edittokenassetmodal-input-index: calc(var(--edittokenassetmodal-canvas-index) + 1);

  width: 293px;
  height: 293px;
  margin: 0 auto 18px;
  position: relative;
  background: var(--color-white);
  border: 2px solid var(--color-light-grey);
  border-radius: 18px;
  overflow: hidden;
}

.grid {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: var(--edittokenassetmodal-grid-index);
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--edittokenassetmodal-canvas-index);
}

.radio-input:last-child {
  margin-left: 18px;
}

.range-input {
  width: 257px;
  height: 9px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  z-index: var(--edittokenassetmodal-input-index);
  background: var(--color-teal);
  border-radius: 5px;
  outline: none;
  transform: translateX(-50%);
  -webkit-appearance: none;
}

.range-input::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.range-input::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  margin-bottom: 18px;
  text-align: center;
}

.options p {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1;
}
