.tokens {
  max-height: 334px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.tokens {
  min-height: 112px;
  padding: 9px;
  background: var(--color-white);
  border: 2px solid var(--color-light-grey);
  border-radius: 18px;
}
