.join-button {
  min-width: 105px;
}

.control-button {
  pointer-events: auto;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
  transition: filter 0.15s ease-out;
}

.control-button:hover span:first-child {
  filter: brightness(1.1);
}

.popper {
  z-index: 9999;
}

.menu {
  display: inline-block;
  pointer-events: auto;
  bottom: 0;
  right: 0;
}
