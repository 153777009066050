.section {
  margin-bottom: 20px;
  padding: 20px 20px 0;
  background-color: rgb(var(--color-theme-base));
  border-radius: 24px;
}

.heading {
  position: relative;
}

.heading h3 {
  cursor: pointer;
}

.edit-button {
  position: absolute;
  right: 17px;
  bottom: 4px;
  background: none;
  border: none;
  opacity: 0;
  transition: opacity 0.15s;
}

.edit-button path {
  fill: var(--color-white);
}

.section-heading.is-editing .edit-button,
.section-heading:hover .edit-button {
  opacity: 1;
}

.section-heading {
  top: -26px;
}

.input {
  width: 100%;
  padding: 6px 43px;
  background: none;
  border: none;
  color: var(--color-white);
  font-family: var(--font-alphapipe);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 1.4;
  outline: none;
}

.content {
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.content.is-collapsed,
.content.is-dragging {
  height: 0;
  overflow: hidden;
}

.content-inner {
  width: 100%;
  min-height: 182px;
  padding: 10px 0;
  display: grid;
  align-items: flex-start;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 18px;
  row-gap: 20px;
}

.is-dragging .content-inner {
  opacity: 0;
}
