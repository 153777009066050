.container {
  --toggle-active-background: var(--color-sheet-text);

  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  border: 2px solid rgb(var(--color-sheet-text) / 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: border-width 0.15s;
}

.container.read-only {
  cursor: auto;
}

.container.is-active {
  border-width: 0;
}

.input {
  width: 0;
  height: 0;
  display: block;
  opacity: 0;
}

.dot {
  width: 6px;
  height: 6px;
  position: relative;
  background: rgb(var(--color-sheet-button));
  border-radius: inherit;
  transition: background-color 0.3s;
}

.dot::after {
  position: absolute;
  inset: 0;
  background: var(--toggle-active-background);
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.15s;
  content: '';
}

.container:not(.read-only):hover .dot,
.container:not(.read-only):focus .dot {
  @media (hover: hover) {
    background-color: rgb(var(--color-sheet-text));
  }
}

.container.is-active .dot {
  width: 8px;
  height: 8px;
}

.container.is-active .dot::after {
  opacity: 1;
}
