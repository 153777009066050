:root {
  --tableuser-tile-index: calc(var(--tablemedia-content-index) + 1);
  --tableuser-pinnedborder-index: calc(var(--tableuser-tile-index) + 1);
  --tableuser-name-index: calc(var(--tableuser-pinnedborder-index) + 1);
  --tableuser-avatar-index: calc(var(--tableuser-name-index) + 1);
  --tableuser-menu-index: calc(var(--tableuser-pinnedborder-index) + 1);
}

.container {
  --tile-width: 480px;
  width: 100%;
  height: 100%;
  position: relative;
}

.scrollContainer {
  width: 100%;
  height: 100%;
  padding: 12px 8px 64px 8px;
  gap: 16px;

  .grid & {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  .speaker & {
    width: min-content;
    height: calc(100% - 64px);
    display: flex;
    gap: 16px 32px;
    flex-direction: column;
    overflow-y: auto;
  }

  @media (--md) {
    padding: 12px 72px 64px 20px;
  }
}

.tile {
  position: relative;
  aspect-ratio: 1;
  z-index: var(--tableuser-tile-index);
  overflow: visible;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
  border-radius: 20px;
  transition: width 0.2s, min-width 0.2s;

  &.isHidden {
    display: none;
  }

  .grid & {
    flex-basis: calc(var(--tile-width) - 16px);
  }

  .speaker &.mainParticipant {
    z-index: calc(var(--tableuser-tile-index) - 1);
    position: absolute;
    inset: 0;
    margin: 0 auto;
    max-width: 1920px;

    aspect-ratio: unset;

    &.isPinned::after {
      position: absolute;
      inset: 0;
      content: '';
      pointer-events: none;
      border: 4px solid;
      border-top-width: 0px;
      border-image-slice: 1;
      border-image-source: linear-gradient(to bottom, transparent 1%, var(--border-from) 10%, var(--border-to));
    }

    .video {
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }

    > .avatar,
      video,
      /* Targets preview media image */
      > div > img {
      border-radius: 0;
    }
  }

  .speaker &:not(.mainParticipant) {
    z-index: var(--tableuser-tile-index);
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .speaker .focusMode &:not(.mainParticipant) {
    display: none;
  }

  > .avatar,
  video,
  /* Targets preview media image */
  > div > img {
    overflow: hidden;
    border-radius: 20px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  display: block;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.userActions {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: var(--tableuser-name-index);
  transition: opacity 0.15s ease-in-out;
  opacity: 0;

  button {
    opacity: 0.75;
  }

  .tile:hover & {
    opacity: 1;
  }
}

.isVideoHidden {
  width: 100%;
  background: rgb(0 0 0 / 0.25);
  border: 2px solid rgb(var(--color-sheet-text) / 0.5);
  border-radius: 14px;  
}

.videoHiddenIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: #ffffff;  
  width: 100%;
  height: 100%;


  svg {
    width: 50%;
    height: 50%;
    fill: white;
  }
}

.hideVideoButton {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 9px;
  right: 15px;
  z-index: 2;
  font-size: 0;
  transition: opacity 0.4s ease-in-out;

  & svg {
    width: 100%;
    height: 100%;
  }

  & path,
  & circle {
    fill: rgb(var(--color-sheet-text));
    stroke-opacity: 0;
    transition: fill 0.15s ease-in-out;
  }
}

.video {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;

  .is-self & {
    transform: scaleX(-1);
  }
}

.buttons {
  z-index: calc(var(--tableuser-tile-index) + 1);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  button {
    opacity: 0.5;
  }

  .tile:hover & {
    opacity: 1;
  }
}

.metadata {
  max-width: calc(100% - 24px);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: var(--tableuser-name-index);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;

  .hasSheet:not(.isSmall) & {
    padding-left: calc(var(--tableuser-avatar-size) / 4 * 3 + 12px);
    left: calc(var(--tableuser-avatar-size) / 4 + 12px);
  }

  .isSmall & {
    max-width: none;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 20px 20px;
  }

  .mainParticipant:not(.isSmallScreen) & {
    max-width: 50%;
    left: 50% !important;
    bottom: calc(var(--layout-room-controls-height) + 20px);
    transform: translateX(-50%);
  }

  .mainParticipant.isSmallScreen & {
    max-width: none;
    padding: 8px 12px 8px calc(var(--tableuser-avatar-size) + 24px);
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.metadataSheet {
  position: relative;

  .hasSheet & {
    position: absolute;
    bottom: 0;
    left: calc(var(--tableuser-avatar-size) / 4 * -1);
  }

  .hasSheet.mainParticipant.isSmallScreen & {
    position: absolute;
    top: 4px;
    left: 12px;
  }
}

.nameButton {
  color: var(--color-white);
  font-size: 16px;
  font-weight: bold;
  text-align: start;

  .isSmall & {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }
}

.devices {
  display: flex;
  align-items: center;
  gap: 8px;

  .isSmall & {
    padding: 12px 8px 8px;
    position: absolute;
    bottom: 100%;
    left: 0;
    flex-direction: column-reverse;
    background: rgba(0, 0, 0, 0.8);
    border-top-right-radius: 20px;
  }
}

.deviceIcon {
  height: 14px;

  path,
  rect {
    fill: var(--color-alert-red);
    stroke-opacity: 0;
  }

  &.isActive path,
  &.isActive rect {
    fill: var(--color-white);
  }
}

.sheetAvatar {
  position: relative;
  z-index: var(--tableuser-avatar-index);
  cursor: pointer;

  .isSmall & {
    position: absolute;
    top: -8px;
    left: -8px;
  }
}

.hostLabel {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: var(--tableuser-avatar-index);
  border-radius: 4px;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  pointer-events: none;

  > div {
    padding: 3px 6px;
    font-weight: bold;
    border-radius: 2px;
    background: rgb(0 0 0 / 0.8);
  }

  .hasSheet & {
    position: absolute;
    top: calc(var(--tableuser-avatar-size) - 6px);
    left: calc(var(--tableuser-avatar-size) / 2 - 22px);
  }

  .isSmall & {
    position: absolute;
    top: -4px;
    left: -4px;
  }

  .isSmall.hasSheet & {
    position: absolute;
    top: calc(var(--tableuser-avatar-size) - 8px - 6px);
    left: calc(var(--tableuser-avatar-size) / 2 - 8px - 22px);
  }
}

.unpinButton {
  position: absolute;
  right: -42px;
  top: 50%;
  transform: translateY(-50%);

  .unpinIcon,
  .pinIcon {
    position: absolute;
  }

  .unpinIcon {
    opacity: 0;
  }

  &:hover {
    .pinIcon {
      opacity: 0;
    }

    .unpinIcon {
      opacity: 1;
    }
  }
}

.menu {
  z-index: var(--tableuser-menu-index);
  background: var(--color-black);
  border-radius: 9px;
  border: 1px solid var(--color-text-dark-grey);
  text-align: left;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
}

.menuItem {
  min-height: 30px;
}

.menuBorder {
  margin: 0 9px;
  border-bottom: 1px solid var(--color-text-dark-grey);

  .menuItem:last-child {
    border-bottom: none;
  }
}

.menuLink {
  width: 100%;
  padding: 9px;
  display: block;
  color: var(--color-text-light-grey);
  font-size: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;

  &:hover,
  &:focus {
    color: var(--color-light-text);
  }

  span {
    text-transform: none;
    letter-spacing: normal;
  }
}

.userDicePopperContainer {
  transition: transform 50ms linear;
  z-index: 9999;
}

.userDice {
  &.speakerMode {
    top: unset;
    right: unset;
    position: unset;
    display: inherit;

    [data-role='sliding-container'] {
      --translate-amount: -100%;
      flex-direction: row-reverse;

      > :first-child {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    [data-role='collapsed-icon'] {
      left: 0;
      right: unset;
    }
  }

  &.isHidden {
    display: none;
  }

  .isSmall & {
    top: 0;
    right: 0;
  }
}

.mainParticipant .userDice {
  margin-right: 86px;
}
