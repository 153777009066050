:root {
  --main-nav-height: 100px;
  --main-nav-height-lg: 140px;
  --rainbow-bar-height: 3px;

  /* Default theme colors */
  --color-theme-base: 26 26 38; /* #1a1a26 (role.dark.300) */
  --color-theme-button: 39 39 66; /* #272742(role.dark.200) */
  --color-theme-text: 193 186 247; /* #c1baf7 (role.light.200) */
  --color-theme-accent: 143 129 241; /* #8f81f1 (role.brand.purple) */
  --color-theme-user-hex-0: #abe14d;
  --color-theme-user-hex-1: #46ccb1;

  --image-theme-background: url('../images/RoleBackground.jpg');

  /* Fallbacks for CRA theme colors */
  --color-red: #ff006b;
  --color-orange: #fcb249;
  --color-green: #abe14d;
  --color-teal: #46ccb1;
  --color-purple: #8f81f1;
  --color-pink: #ff9aeb;
  --color-blue: #8f81f1;
}

/* Resets */

input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

input[type='range']::-moz-range-thumb,
input[type='range']::-webkit-slider-thumb {
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-theme-accent) / var(--tw-border-opacity));
  background-color: currentColor;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.rui-button {
  height: 34px;
  border-radius: 0.625rem;
  text-align: center;
  text-indent: 0.15em;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 34px;
  letter-spacing: 0.15em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-button:disabled {
  opacity: 0.5;
}

.rui-button-md {
  height: 42px;
  line-height: 42px;
}

.rui-button-lg {
  height: 54px;
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 54px;
}

.rui-button-primary {
  background-image: linear-gradient(to bottom, #ff006b, #8f81f1);
}

.rui-button-primary:not(:disabled):hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(73 68 122 / var(--tw-bg-opacity));
}

.rui-button-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(143 129 241 / var(--tw-bg-opacity));
}

.rui-button-theme {
  --tw-gradient-from: #ff006b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 0 107 / 0) var(--tw-gradient-to-position);
  --tw-gradient-to: #8f81f1 var(--tw-gradient-to-position);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
  background-image: linear-gradient(170deg, var(--tw-gradient-stops));
  --tw-gradient-from: rgb(var(--color-theme-accent) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-accent) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(var(--color-theme-button) / 1) var(--tw-gradient-to-position);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-button-theme:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme:disabled:hover {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme-solid {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-button-theme-solid:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme-solid:disabled:hover {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme-user {
  --tw-gradient-from: #ff006b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 0 107 / 0) var(--tw-gradient-to-position);
  --tw-gradient-to: #8f81f1 var(--tw-gradient-to-position);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
  background-image: linear-gradient(170deg, var(--tw-gradient-stops));
  --tw-gradient-from: rgb(var(--color-theme-accent) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-accent) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(var(--color-theme-button) / 1) var(--tw-gradient-to-position);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-button-theme-user:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme-user:disabled:hover {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-button-theme-user {
  --tw-gradient-from: var(--color-theme-user-hex-0) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: var(--color-theme-user-hex-1) var(--tw-gradient-to-position);
}

.rui-tag {
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(73 68 122 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(193 186 247 / var(--tw-text-opacity));
}

.rui-tag-theme {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-button) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(var(--color-theme-text) / var(--tw-text-opacity));
}

a.rui-tag-theme {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

a.rui-tag-theme:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/* Defaults that get overwritten by classes from the utilities layer */

.rui-bg-gradient-role {
  --tw-gradient-from: #ff006b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 0 107 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #8f81f1 var(--tw-gradient-to-position);
}

.rui-font-title {
  text-transform: uppercase;
}

.rui-fixed {
  position: fixed;
}

.rui-absolute {
  position: absolute;
}

.rui-relative {
  position: relative;
}

.rui-inset-0 {
  inset: 0px;
}

.rui-left-0 {
  left: 0px;
}

.rui-right-5 {
  right: 1.25rem;
}

.rui-top-0 {
  top: 0px;
}

.rui-top-5 {
  top: 1.25rem;
}

.rui-z-0 {
  z-index: 0;
}

.rui-z-10 {
  z-index: 10;
}

.rui-z-20 {
  z-index: 20;
}

.rui-z-\[9999\] {
  z-index: 9999;
}

.rui-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.rui-mb-4 {
  margin-bottom: 1rem;
}

.rui-mb-5 {
  margin-bottom: 1.25rem;
}

.rui-mt-12 {
  margin-top: 3rem;
}

.rui-flex {
  display: flex;
}

.rui-hidden {
  display: none;
}

.rui-h-4 {
  height: 1rem;
}

.rui-h-8 {
  height: 2rem;
}

.rui-h-\[10px\] {
  height: 10px;
}

.rui-h-\[200px\] {
  height: 200px;
}

.rui-h-\[34px\] {
  height: 34px;
}

.rui-h-\[42px\] {
  height: 42px;
}

.rui-h-\[50px\] {
  height: 50px;
}

.rui-h-\[54px\] {
  height: 54px;
}

.rui-h-full {
  height: 100%;
}

.rui-max-h-\[90\%\] {
  max-height: 90%;
}

.rui-w-4 {
  width: 1rem;
}

.rui-w-8 {
  width: 2rem;
}

.rui-w-\[10px\] {
  width: 10px;
}

.rui-w-\[50px\] {
  width: 50px;
}

.rui-w-full {
  width: 100%;
}

.rui-flex-col {
  flex-direction: column;
}

.rui-items-center {
  align-items: center;
}

.rui-justify-center {
  justify-content: center;
}

.rui-gap-3 {
  gap: 0.75rem;
}

.rui-overflow-hidden {
  overflow: hidden;
}

.rui-overflow-y-auto {
  overflow-y: auto;
}

.rui-rounded-2xl {
  border-radius: 1rem;
}

.rui-rounded-full {
  border-radius: 9999px;
}

.rui-rounded-inherit {
  border-radius: inherit;
}

.rui-rounded-lg {
  border-radius: 0.625rem;
}

.rui-rounded-md {
  border-radius: 0.375rem;
}

.rui-rounded-tl-inherit {
  border-top-left-radius: inherit;
}

.rui-rounded-tr-inherit {
  border-top-right-radius: inherit;
}

.rui-border {
  border-width: 1px;
}

.rui-border-2 {
  border-width: 2px;
}

.rui-border-black\/5 {
  border-color: rgb(0 0 0 / 0.05);
}

.rui-border-theme-accent {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-theme-accent) / var(--tw-border-opacity));
}

.rui-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8);
}

.rui-bg-current {
  background-color: currentColor;
}

.rui-bg-role-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 66 / var(--tw-bg-opacity));
}

.rui-bg-role-dark-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 38 / var(--tw-bg-opacity));
}

.rui-bg-role-dark-300\/30 {
  background-color: rgb(26 26 38 / 0.3);
}

.rui-bg-role-light-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(73 68 122 / var(--tw-bg-opacity));
}

.rui-bg-theme-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
}

.rui-bg-theme-button {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-button) / var(--tw-bg-opacity));
}

.rui-bg-gradient-role {
  background-image: linear-gradient(170deg, var(--tw-gradient-stops));
}

.rui-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.rui-bg-role-green-city {
  background-image: url('../images/RoleGreenCity.jpg');
}

.rui-from-role-brand-magenta {
  --tw-gradient-from: #ff006b var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 0 107 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.rui-from-role-dark-200\/70 {
  --tw-gradient-from: rgb(39 39 66 / 0.7) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(39 39 66 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.rui-from-theme-accent {
  --tw-gradient-from: rgb(var(--color-theme-accent) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-accent) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.rui-from-theme-user-0 {
  --tw-gradient-from: var(--color-theme-user-hex-0) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.rui-to-role-dark-200 {
  --tw-gradient-to: #272742 var(--tw-gradient-to-position);
}

.rui-bg-cover {
  background-size: cover;
}

.rui-bg-\[center_75\%\] {
  background-position: center 75%;
}

.rui-fill-role-light-100 {
  fill: #ffffff;
}

.rui-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.rui-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.rui-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.rui-pb-8 {
  padding-bottom: 2rem;
}

.rui-pt-10 {
  padding-top: 2.5rem;
}

.rui-text-center {
  text-align: center;
}

.rui-indent-tracking-widest {
  text-indent: 0.15em;
}

.rui-font-title {
  font-family: bc-alphapipe, ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
}

.rui-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.rui-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.rui-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.rui-font-bold {
  font-weight: 700;
}

.rui-uppercase {
  text-transform: uppercase;
}

.rui-leading-\[34px\] {
  line-height: 34px;
}

.rui-leading-\[42px\] {
  line-height: 42px;
}

.rui-leading-\[54px\] {
  line-height: 54px;
}

.rui-tracking-widest {
  letter-spacing: 0.15em;
}

.rui-text-role-light-200 {
  --tw-text-opacity: 1;
  color: rgb(193 186 247 / var(--tw-text-opacity));
}

.rui-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.rui-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.rui-brightness-100 {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.rui-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.rui-bg-rainbow {
  background-image: linear-gradient(to right, #ff006b, #fcb249, #abe14d, #46ccb1, #8f81f1, #ff9aeb);
}

.rui-bg-button {
  background-image: linear-gradient(to bottom, #ff006b, #8f81f1);
}

.rui-bg-radial-blur {
  background-image: radial-gradient(
    ellipse,
    #1a1a26 0%,
    rgb(26 26 38 / 92%) 40%,
    rgb(26 26 38 / 38%) 65%,
    rgb(26 26 38 / 0%) 80%
  );
}

.rui-shadow-inner-outer {
  box-shadow: inset 0 0 10px rgb(0 0 0 / 0.25), 0 0 25px rgb(0 0 0 / 0.16);
}

.rui-social-mask {
  -webkit-mask-image: radial-gradient(circle at left, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 1) 50%);
  mask-image: radial-gradient(circle at left, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 1) 50%);
  -webkit-mask-size: 110px 70px;
  mask-size: 110px 70px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: -60px 100%;
  mask-position: -60px 100%;
  -webkit-mask-origin: content-box;
  mask-origin: content-box;
}

li:nth-child(even) .rui-social-mask {
  -webkit-mask-image: radial-gradient(circle at right, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 1) 50%);
  mask-image: radial-gradient(circle at right, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 1) 50%);
  -webkit-mask-position: -50px 100%;
  mask-position: -50px 100%;
}

.rui-bottom-fade {
  -webkit-mask-image: linear-gradient(to bottom, black 90%, rgb(0 0 0 / 0%));
  mask-image: linear-gradient(to bottom, black 90%, rgb(0 0 0 / 0%));
}

.rui-border-dashed-role-light-200-20 {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23C1BAF733' stroke-width='4' stroke-dasharray='2%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.rui-scrollbars-dark {
  scrollbar-height: thin;
  scrollbar-width: thin;
  scrollbar-color: rgb(0 0 0 / 0.1);
}

.rui-scrollbars-dark::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.rui-scrollbars-dark::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
}

.rui-scrollbars-dark::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.rui-scrollbars-dark::-webkit-scrollbar-track:hover {
  background: rgb(var(--color-theme-button));
}

.rui-scrollbars-dark::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(var(--color-theme-text) / 0.3);
}

.rui-scrollbars-dark::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-theme-text));
}

.hover\:rui-bg-role-dark-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 38 / var(--tw-bg-opacity));
}

.hover\:rui-bg-theme-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-accent) / var(--tw-bg-opacity));
}

.hover\:rui-brightness-110:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 640px) {
  .sm\:rui-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.\[\&_path\]\:rui-fill-role-light-200 path {
  fill: #c1baf7;
}

.\[\&_svg\]\:rui-h-full svg {
  height: 100%;
}

.\[\&_svg\]\:rui-w-full svg {
  width: 100%;
}
