.container {
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  margin-bottom: 20px;
  font-size: 36px;
}

.users {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.videoRoomUsers {
  margin-bottom: 10px;
  color: var(--color-white);
  font-size: 12px;
  font-weight: 700;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.avatar {
  pointer-events: auto;
}
