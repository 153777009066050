.container {
  position: relative;
  width: 320px;
  transform: scale(0.994);
  transition: all 0.15s ease-in-out;

  &:not(.detailView) {
    &:active,
    &:focus,
    &:hover {
      transform: scale(1);
      box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
      border-radius: 20px;

      .image img {
        filter: brightness(1.1);
      }
    }
  }
}

.content {
  border-radius: 20px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 30%), 0 1px 0px 0 rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  isolation: isolate;
  overflow: hidden;
  position: relative;
}

.image {
  display: block;
  flex: none;
  overflow: hidden;
  text-align: center;
  aspect-ratio: 8.5/11;
  min-width: 320px;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.15s ease-in-out;
}

.body,
.details {
  background: rgb(var(--color-theme-base));
  color: rgb(var(--color-theme-text));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-height: 160px;
  padding: 12px 16px 16px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.body::after {
  background: linear-gradient(145deg, rgb(var(--color-theme-base)) 75%, rgb(var(--color-theme-accent) / 0.4) 120%);
  content: '';
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.15s ease-in-out;
  width: 100%;
  z-index: 0;
}

.details {
  display: block;
  padding: 24px;
}

.container:active,
.container:focus,
.container:hover {
  .body::after {
    opacity: 1;
  }
}

.title {
  color: rgb(var(--color-theme-text));
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  z-index: 1;

  &:active,
  &:focus,
  &:hover {
    color: var(--color-red);
  }
}

.organization {
  align-self: flex-start;
  display: inline-block;
  text-decoration: none;
  z-index: 1;

  span {
    &:first-child {
      background-color: var(--color-dashboard-white);
      transition: all 100ms ease-in-out;
    }
  }
}

.organization:active,
.organization:focus,
.organization:hover {
  span {
    &:first-child {
      background-color: var(--color-primary-main);
      color: var(--color-primary-accent);
      transition: all 100ms ease-in-out;
    }
    &:last-child {
      color: var(--color-primary-accent);
      transition: all 100ms ease-in-out;
    }
  }
}

.description {
  padding-top: 12px;
  width: 100%;
  z-index: 1;
}

.detailsTitle {
  font-size: 14px;
  letter-spacing: 1.4px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.bundleContentList {
  font-size: 16px;
  list-style: none;
  margin-bottom: 6px;
  padding-left: 0;

  li {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
  }

  svg > * {
    fill: var(--color-dashboard-main);
    stroke: var(--color-dashboard-main);
  }
}

.productBundle {
  background: var(--color-dashboard-white);
  border-radius: 10px;
  margin-top: 24px;
  padding: 16px;

  .productContentList {
    margin-bottom: 6px;

    li:last-child {
      margin-bottom: 0;
    }
  }

  .downloadButton {
    display: block;
    margin-top: 24px;
    padding: 14px 0;

    .icon path:last-child {
      transform: rotate(180deg) translateY(40%);
      transform-origin: center;
    }
  }
}

.button-actions,
.external-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  justify-self: end;
  margin-top: 10px;
  padding: 0;
  width: 100%;
  z-index: 1;

  @media (--sm) {
    margin-top: auto;
    flex-direction: row;
  }
}

.button-actions a,
.button-actions button {
  width: 100%;

  @media (--sm) {
    width: 50%;
  }
}

.exclusive {
  background: linear-gradient(135deg, var(--color-red), var(--color-purple));
  border-radius: 6px;
  color: var(--color-white);
  font-size: 12px;
  left: 50%;
  letter-spacing: 2px;
  line-height: 1.4;
  padding: 2px 6px;
  position: absolute;
  text-transform: uppercase;
  top: -10px;
  transform: translate(-50%);
  z-index: 2;
}

.isSmall,
.panelStore:not(:first-child) {
  &.container {
    width: 100%;
  }

  .content {
    border-radius: 10px;
    flex-direction: row;
  }

  .image {
    max-height: 160px;
    min-width: initial;
  }

  .body {
    padding: 12px;
  }

  .button-actions {
    gap: 10px;
  }

  .exclusive {
    left: initial;
    right: -6px;
    transform: none;
  }
}
