.container {
  height: var(--layout-topbar-height);
  margin-bottom: var(--layout-rainbowbar-height);
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: var(--color-dark-mid);
  color: var(--color-dashboard-light);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1;

  &.rainbowOnly {
    height: 0;
  }
}

.container::after {
  width: 100%;
  height: var(--layout-rainbowbar-height);
  position: absolute;
  bottom: calc(var(--layout-rainbowbar-height) * -1);
  left: 0;
  background: linear-gradient(
    90deg,
    var(--color-red),
    var(--color-orange),
    var(--color-green),
    var(--color-teal),
    var(--color-purple),
    var(--color-pink),
    var(--color-red)
  );
  content: '';
}

.section {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 10px;
  text-transform: uppercase;
}

.subtitle {
  text-decoration: none;
}

.subtitle,
.greeting {
  color: var(--color-dashboard-mid);
}

.greeting {
  margin-right: 4px;
  letter-spacing: 1px;
}

.username {
  text-transform: uppercase;
}
