.container {
  --previewpage-upsell-index: 1;
  --previewpage-sheet-index: calc(var(--previewpage-upsell-index) + 1);

  height: 100%;
  padding-top: calc(var(--layout-rainbowbar-height) + var(--layout-room-controls-height));
  padding-bottom: var(--layout-room-tabs-height);
  display: flex;
  overflow: hidden;
  flex-flow: column;

  @media (--md) {
    padding-top: var(--layout-rainbowbar-height);
    padding-bottom: 0;
  }

  .room {
    display: flex;
    flex-grow: 1;
    position: relative;

    .roomColumn {
      display: none;
      flex-direction: column;
      position: relative;

      &.isActive {
        display: flex;
      }

      > div {
        flex-grow: 1;
      }

      @media (--md) {
        display: flex;
      }
    }

    .sheet {
      max-width: var(--sheet-width);
      position: absolute;
      inset: 0;
      z-index: 10;
      background: rgb(var(--color-theme-base));
      transition: transform 0.3s ease-in-out;
    }
    .upsell {
      flex-grow: 1;
      transition: margin 0.3s ease-in-out;
      position: absolute;
      inset: 0;
      z-index: var(--previewpage-upsell-index);

      @media (--md) {
        position: static;
        margin-left: var(--sheet-width);
      }
    }
    .panel {
      position: absolute;
      inset: 0;
      z-index: 10;
      transition: transform 0.3s ease-in-out;

      @media (--md) {
        width: var(--play-panel-width);
        left: auto;
        transform: translateX(calc(var(--play-panel-width)));
      }
    }
  }
  .hideSheet .sheet {
    transform: translateX(calc(var(--sheet-width) * -1 + var(--sheet-collapsed-offset)));
  }
  .hideSheet .upsell {
    @media (--md) {
      margin-left: var(--sheet-collapsed-offset);
    }
  }

  .tocOpen:not(.hideSheet) .upsell {
    @media (--md) {
      margin-left: calc(var(--sheet-width) + var(--sheet-toc-width));
    }
  }

  .showPanel {
    .panel {
      transform: translateX(0);
    }
    .upsell {
      @media (--md) {
        margin-right: var(--play-panel-width);
      }
    }
  }
}

.roomControls {
  padding-right: 0 !important;
  position: fixed;
  top: var(--layout-rainbowbar-height);
  left: 0;
  right: 0;
  z-index: 10 !important;

  @media (--md) {
    display: none;
  }
}

.navTabs {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(var(--color-theme-button));

  button {
    flex: 1;
  }

  @media (--md) {
    display: none;
  }
}

.navButton {
  height: var(--layout-room-tabs-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(var(--color-theme-button));
  color: rgb(var(--color-theme-text));
  font-size: 12px;

  svg {
    height: 18px;
    margin-bottom: 6px;

    * {
      fill: rgb(var(--color-theme-text));
    }
  }

  &.isActive {
    background: rgb(var(--color-theme-accent));
    color: white;

    svg * {
      fill: white;
    }
  }
}
