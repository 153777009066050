.tag {
  position: relative;
  border-radius: 8px;
  max-width: 100%;
}

.tagTitle {
  padding: 0 10px;
  inset: 0;
  z-index: 0;
  background: var(--color-dashboard-white);
  border-radius: inherit;
  color: var(--color-dashboard-mid);
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  display: block;
}

.link {
  text-decoration: none;
}

.gameTag {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 22px;

  .complexity {
    height: 10px;
    display: flex;
    align-items: flex-end;
    gap: 2px;

    .bar {
      width: 3px;
      background: var(--color-dashboard-light);
      border-radius: 2px;

      &:first-child {
        height: 6px;
        background: var(--color-dashboard-mid);
      }

      &:nth-child(2) {
        height: 8px;
      }

      &:nth-child(3) {
        height: 10px;
        margin-right: 0;
      }

      &:is(.complexity.moderate .bar) {
        &:nth-child(2) {
          background: var(--color-dashboard-mid);
        }
      }

      &:is(.complexity.complex .bar) {
        &:nth-child(2),
        &:nth-child(3) {
          background: var(--color-dashboard-mid);
        }
      }
    }
  }

  .icon {
    height: 14px;
    width: 14px;
    fill: var(--color-dashboard-mid);

    circle,
    path {
      stroke: transparent;
      stroke: var(--color-dashboard-mid);
    }
  }
}
