.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.top-border {
  width: 100%;
  height: var(--layout-border-height);
  background: var(--color-dashboard-white);
}
