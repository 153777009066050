.users {
  max-height: 250px;
  margin-bottom: 36px;
  overflow-y: auto;
  line-height: normal;
}

.user-container {
  margin-bottom: 9px;
  padding-bottom: 9px;
  display: flex;
  border-bottom: 1px solid var(--color-light-grey);
}

.user-container:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.user {
  white-space: nowrap;
}

.user-dot {
  margin-right: 3px;
}

.remove-button {
  margin-left: auto;
  color: var(--color-red);
  font-size: 10px;
  font-weight: bold;
}

.remove-button svg {
  width: 6px;
  margin-right: 4px;
  overflow: visible;
}

.remove-button path {
  fill: var(--color-red);
  stroke-opacity: 0;
}

.buttons {
  margin-top: 18px;
  text-align: center;
}
