.sheetCardWrapper {
  min-height: 136px;
  display: flex;
  position: relative;
  border-radius: 40px 12px 12px 40px;
}

.sheetCardWrapper .avatar {
  position: absolute;
  left: -2px;
  top: -2px;
}

.sheetCardWrapper .name {
  font-size: 20px;
  text-align: initial;
  color: var(--color-light-dark);
  line-height: 1.5;
}

.sheetCardInfo {
  margin: 14px 18px 8px 150px;
  flex-grow: 1;

  .isSmall & {
    margin-left: 110px;
  }
}

.header {
  padding: 0 0 12px 0;
  margin-bottom: 8px;
  background-color: var(--color-white);
  border-bottom: 2px solid rgb(0 0 0 / 5%);

  @media (--md) {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 15px;
  }
}

.sheetInfo {
  min-width: 0;
}

.tableActions {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 100px 100px;
  gap: 10px;

  @media (--md) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
}

.viewButton {
  min-width: 100px;
}

.viewButton,
.updateButton {
  border: 2px solid var(--color-light-main);
}

.is-viewing .viewButton,
.viewButton:hover,
.updateButton:hover {
  border: 2px solid var(--color-accent-main);
}

/* We need to break into :global scope for this rule, but it simplifies our JS so it's a compromise... */
:global(.dashboard_has-sheet) .sheetCardWrapper:not(.is-viewing) {
  opacity: 0.6;
}

.meta {
  font-size: 14px;
  margin: 0 40px;
}

.tableDate {
  text-transform: uppercase;
}

.sheetTables {
  font-weight: bold;
  color: var(--color-light-dark);
  background: var(--color-white);
  border-radius: 0 0 12px 12px;
}

.sheetTables h4 {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-dashboard-light);
  letter-spacing: 2px;
}

.tableList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  grid-gap: 6px 8px;
  margin: 6px 0 0;
  padding: 0;
  list-style: none;
}

.tablePill {
  height: 24px;
  border-radius: 8px;
  color: var(--color-light-dark);
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  line-height: 19px;
  position: relative;
}

.tablePillBefore {
  width: 6px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px 0 0 8px;
}

.tableLink {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 14px;
  display: block;
  background: var(--color-light-light);
  border-radius: 8px;
  color: var(--color-dashboard-mid);
  line-height: 24px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}

.tableLink:hover {
  background-color: var(--color-dashboard-light);
  color: var(--color-white);
}
