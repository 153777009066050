.container {
  --iconbutton-active-background: var(--color-dark-dark);
  --iconbutton-active-color: var(--color-white);
  --iconbutton-active-size: var(--iconbutton-icon-size);
  --iconbutton-background: var(--color-primary-dark);
  --iconbutton-border-radius: 10px;
  --iconbutton-color: var(--color-dark-accent);
  --iconbutton-button-size: 32px;
  --iconbutton-icon-size: 14px;
  --iconbutton-speed: 0.15s;
  --iconbutton-hover-background: var(--iconbutton-background);
  --iconbutton-active-hover-background: var(--iconbutton-hover-background);

  width: var(--iconbutton-button-size);
  height: var(--iconbutton-button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--iconbutton-border-radius);
}

.background {
  position: absolute;
  inset: 0;
  z-index: 2;
  background: var(--iconbutton-background);
  border-radius: inherit;
  transition: background-color var(--iconbutton-speed), filter var(--iconbutton-speed);

  .is-active & {
    background: var(--iconbutton-active-background);
  }

  @media (hover: hover) {
    .container:hover & {
      background: var(--iconbutton-hover-background);
    }

    .is-active:hover & {
      background: var(--iconbutton-active-hover-background);
    }
  }
}

.icon {
  width: var(--iconbutton-icon-size);
  height: var(--iconbutton-icon-size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: var(--iconbutton-color);
  transition: color var(--iconbutton-speed);

  .is-active & {
    width: var(--iconbutton-active-size);
    height: var(--iconbutton-active-size);
    color: var(--iconbutton-active-color);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path,
  ellipse,
  polygon,
  circle {
    fill: var(--iconbutton-color);
    stroke-opacity: 0;
    transition: fill var(--iconbutton-speed);
  }

  .is-active & path,
  .is-active & ellipse,
  .is-active & polygon,
  .is-active & circle {
    fill: var(--iconbutton-active-color);
  }

  @media (hover: hover) {
    .container:hover & {
      color: var(--iconbutton-active-color);
    }

    .container:hover & path,
    .container:hover & ellipse,
    .container:hover & polygon,
    .container:hover & circle {
      fill: var(--iconbutton-active-color);
    }
  }
}

.label {
  padding: 5px 14px 5px 16px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  background: var(--color-black);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: var(--iconbutton-active-color);
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  line-height: 1;
  opacity: 0;
  transform: translate(0, -50%);
  transition: opacity var(--iconbutton-speed), transform var(--iconbutton-speed);
  pointer-events: none;

  @media (hover: hover) {
    .container:hover & {
      opacity: 1;
      transform: translate(30px, -50%);
    }
  }
}

.tooltip {
  padding: 0 14px;
  position: absolute;
  bottom: calc(var(--iconbutton-button-size) + 9px);
  right: -3px;
  background: var(--color-black);
  border-radius: 6px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.66px;
  line-height: 24px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: var(--color-white);

  &::after {
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    right: calc(var(--iconbutton-button-size) / 2 - 2px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid var(--color-black);
    content: '';
  }

  @media (hover: hover) {
    .container:hover & {
      opacity: 1;
    }
  }
}

.disabled {
  pointer-events: none;
}

.arrowIcon {
  width: 10px;
  height: 7px;
}

.upArrow {
  transform: rotate(180deg);
}
