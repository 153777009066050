.button {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 auto;
  padding: 6px 0;
  text-decoration: none;
}

.iconTitleButton {
  align-items: center;
  display: flex;
  gap: 4px;

  svg {
    flex-shrink: 0;
    fill: var(--color-white);
    height: 20px;
    margin-right: 4px;
    width: 20px;
  }

  > span {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1px;

    span:first-of-type {
      color: var(--color-white);
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    span:last-of-type {
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
}
