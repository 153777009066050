.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.externalUrlIndicator {
  position: absolute;
  z-index: var(--asset-overlay-index);
  color: var(--color-dashboard-mid);
  background-color: var(--color-dashboard-white);
  border-radius: 8px;
  padding: 6px;
  right: 8px;
  top: 8px;
}

.externalUrlIndicator svg {
  height: 14px;
  width: 14px;
  fill: currentColor;
}

.preview {
  display: flex;
  height: 200px;
  justify-content: center;
  min-width: 150px;
  max-width: 320px;

  @media (--md) {
    max-width: none;
  }
}

.isMap .preview {
  width: 100%;
}

.isToken .preview {
  border-radius: 0;
  max-height: 120px;
  padding: 12px;
}

.preview img {
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  min-width: 100%;
  padding: 6px 14px 12px;
  position: relative;
  width: 0;
}

.name {
  color: var(--color-light-dark);
  font-size: 14px;
  line-height: 1.2;
  overflow-wrap: break-word;
}

.menuButton {
  opacity: 0;
  position: absolute !important;
  top: 10px;
  transition: opacity 0.15s ease-out;
  right: 10px;

  .isMenuActive & {
    opacity: 1;
    z-index: 10;
  }

  @media (hover: hover) {
    .container:active &,
    .container:focus &,
    .container:hover & {
      opacity: 1;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

/* popper styles */
.popper {
  margin-top: 4px;
  z-index: 9999;
}

.popperMenu {
  display: block;
  position: static;
  pointer-events: auto;
}

.ownerTag {
  font-style: italic;
  font-weight: normal;
  margin: 0 14px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audioWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
