.elementLibrary {
  padding: 20px;
  background-color: rgb(var(--color-theme-base));
  border-radius: 24px;
  color: rgb(var(--color-theme-text));
  font-size: 18px;
}

.elementLibrary header {
  margin-bottom: 30px;
  text-align: center;
}

.elementLibrary h4 {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-white);
}

.elementLibrary .instruction {
  margin-bottom: 25px;
}

.builderRep {
  height: 50px;
  margin-bottom: 10px;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  position: relative;
  background: rgb(var(--color-theme-button));
  border: 2px solid rgb(var(--color-theme-button));
  border-radius: 15px;
  font-size: 18px;
  font-family: var(--font-europa);
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1;
  transition: padding-left 0.25s ease-in-out, color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}

.builderRep:hover {
  padding-left: 48px;
  color: var(--color-white);
  border-color: rgb(var(--color-theme-text));
}

.builderRep.isDragging {
  background-color: rgb(var(--color-theme-accent));
  border-color: rgb(var(--color-theme-accent));
  color: var(--color-white);
  box-shadow: 0 8px 10px 8px rgb(0 0 0 / 30%);
  text-align: center;
  opacity: 0.9;
}

.builderName {
  flex-grow: 1;
}

.drag-icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in-out;
}

.drag-icon path {
  fill: rgb(var(--color-theme-text));
  stroke-opacity: 0;
}

.drag-icon > g > g:last-child > g:first-child path {
  fill: none;
}

.info-icon {
  width: 18px;
  height: 18px;
  margin-left: 14px;
  background: var(--color-white);
  position: relative;
  border-radius: 50%;
}

.info-icon svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-icon path {
  fill: rgb(var(--color-theme-accent));
  stroke-opacity: 0;
}

.info-tooltip {
  padding: 0 14px;
  position: absolute;
  bottom: 29px;
  right: -10px;
  background: var(--color-black);
  border-radius: 6px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.66px;
  line-height: 24px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.info-tooltip::after {
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  right: 14px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid var(--color-black);
  content: '';
}

.info-icon:hover .info-tooltip {
  opacity: 1;
}

.add-button,
.drag-icon,
.info-icon {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.builderRep:hover .add-button,
.builderRep:hover .drag-icon,
.builderRep:hover .info-icon {
  opacity: 1;
}
