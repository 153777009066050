.container {
  text-align: center;
}

.title {
  margin-bottom: 18px;
  text-align: center;
}

.tray {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-height: calc(18px * 2 + 100px * 2); /* 18px top margin + 82px height dice + 18px dice padding */
  overflow-y: auto;
  position: relative;
}

.tray::before {
  background: linear-gradient(rgb(var(--color-theme-base)), transparent);
  content: '';
  height: 18px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

.tray::after {
  background: linear-gradient(transparent, rgb(var(--color-theme-base)));
  bottom: 0;
  content: '';
  height: 18px;
  position: sticky;
  width: 100%;
  z-index: 10;
}

.tray > div {
  margin: 9px;
}

.empty {
  height: 136px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--color-theme-text));
  font-size: 14px;
}

.modifier-container {
  margin: 10px 0;
  text-align: center;
}

.modifier {
  display: inline-block;
}

.targets-vs,
.modifier-title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 9px;
  text-transform: uppercase;
}

.modifier-total {
  align-items: center;
  background: none;
  border: none;
  color: var(--color-white);
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  outline: none;
  text-align: center;
  width: 100%;
}

.add-manual-modifier-button {
  color: rgb(var(--color-theme-text));
  font-size: 14px;
  font-weight: bold;
  text-indent: 0 !important;
  letter-spacing: 1px;
  line-height: 1.5;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out;
  margin: 10px 0;
}

.add-manual-modifier-button:hover {
  color: var(--color-white);
}

.total-container {
}

.total-content {
  height: 1px;
  overflow: hidden;
}

.is-showing-total .total-content {
  height: 74px;
}

.total-border {
  width: 216px;
  height: 1px;
  margin: 0 auto;
  background: rgb(var(--color-theme-text) / 0.3);
  transition: all 0.2s;
}

.is-showing-total .total-border {
  opacity: 0;
}

.total {
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  transition: all 0.2s;
}

.is-showing-total .total {
  opacity: 1;
}

.total > div {
  background: rgb(0 0 0 / 0.7);
}

.total.has-rolled > div {
  background-color: var(--color-black);
}

.total-num {
  font-size: 36px;
  line-height: 69px;
}

.toggle-total-button {
  margin: 9px auto 0;
  display: flex;
  align-items: center;
  color: rgb(var(--color-theme-text));
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  transition: color 0.2s;
}

.toggle-total-button:hover {
  color: var(--color-white);
}

.toggle-total-button svg {
  height: 16px;
  margin-right: 12px;
}

.toggle-total-button path {
  fill: rgb(var(--color-theme-text));
  stroke-opacity: 0;
  transition: fill 0.2s;
}

.toggle-total-button:hover path {
  fill: var(--color-white);
}

.settings {
  text-align: left;
  padding-inline: 16px;
  padding-top: 24px;
  margin-bottom: -8px; /* to offset TableBar margin bottom */

  display: flex;
  width: 100%;
  justify-content: center;
  gap: 16px;

  > label {
    display: flex;
    align-items: center;
    gap: 4px;

    > span:first-child {
      margin: 0;
    }
  }
}

.actions {
  min-height: 36px;
  margin-top: 18px;
  text-align: center;
}

.action-button {
  display: inline-block;
}

.clear-button {
  margin-right: 9px;
  display: inline-block;
}

.modal {
}

.modalHeader {
  text-align: center;
  margin: 24px 0;
}

.modalTitle {
  max-width: 520px;
  margin: 0 auto;
  color: var(--color-red);
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.modalContent {
  min-height: 400px;
  max-height: 800px;
  margin: 0 -20px;
  padding: 24px 16px;
  border-top: solid 2px var(--color-dashboard-light-grey);
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: var(--color-dashboard-white);
  overflow-y: auto;

  @media (--md) {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.modal-total {
  display: block;
  margin-bottom: 18px;
}

.modal-column {
  width: 50%;
  margin-bottom: 36px;
  display: inline-block;
  vertical-align: top;
}

.modal-modifiers {
  margin-top: 9px;
}

.modal-modifiers > div {
  margin-bottom: 5px;
}

.modal-modifiers > div:last-child {
  margin-bottom: 0;
}
