.form {
  text-align: center;
}

.form p {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1;
}

.asset {
  margin-bottom: 18px;
  display: inline-block;
}

.inputs,
.button {
  width: 294px;
}

.inputs {
  margin: 0 auto 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  width: 126px;
}

.by {
  padding-left: 5px;
}
