.form {
  width: 290px;
  margin: 0 auto;
}

.form p {
  margin-bottom: 18px;
  text-align: center;
}

.input-container {
  margin-bottom: 18px;
  display: block;
}

.label {
  margin-bottom: 18px;
  display: block;
  font-size: 14px;
  text-align: center;
}

.input > input {
  font-size: 16px;
}

.input > input[data-type='password'] {
  padding-left: 14px;
  padding-right: 36px;
}

.invite-link .input input {
  color: var(--color-teal);
  font-weight: bold;
}

.button {
  width: 100%;
}

.button:last-child {
  margin-top: 18px;
}

.remove-password {
  margin: -9px auto 18px;
  display: block;
  color: var(--color-red);
  text-align: center;
}

.remove-password:hover,
.remove-password:focus {
  text-decoration: underline;
}

.obrHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    margin-bottom: 0;
    text-align: left;
  }
}
