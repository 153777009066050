.container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .modal {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.2);
  }

  .viewMode {
    flex-grow: 1;
    position: relative;
    z-index: 1;

    .viewModeContent {
      z-index: 1;
    }

    .assetContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .asset {
      width: 100%;
      flex-grow: 1;
      position: relative;
      background: var(--color-black);
    }
  }
}
