.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.clock-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.spacer {
  width: calc(100% - 89px);
  position: absolute;
  left: 25px;
  top: 5px;
}

.clock {
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: var(--color-black);
  border-radius: 50%;
}

.content {
  width: 100%;
  height: 100%;
}

.content::after {
  width: 50px;
  height: 50px;
  margin: 0px 0 0 -56px;
  position: absolute;
  border: solid 3px var(--color-black);
  border-radius: 50%;
  content: '';
  pointer-events: none;
}

.container:not(.read-only) .content path {
  cursor: pointer;
}

.stepper {
  margin-bottom: 12px;
}

.edit-button {
  position: absolute;
  top: 50%;
  right: -28px;
  opacity: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.is-hovering .edit-button,
.is-editing .edit-button,
.container:hover + .edit-button {
  opacity: 1;
  pointer-events: auto;
}
