.error {
  margin: 0 auto 18px;
  color: var(--color-red);
  font-size: 14px;
  text-align: center;
}

.errorPopover {
  background-color: var(--color-alert-red);
  border-radius: 8px;
  border: 1px solid var(--color-red);
  box-shadow: var(--box-shadow);
  margin-bottom: 8px;
  max-width: var(--screen-sm);
  padding: 8px 16px;
  z-index: 1;

  .error {
    margin-bottom: 0;
    color: var(--color-white);
  }
}
