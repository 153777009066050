.container {
  position: relative;
  background: var(--color-dark-grey);
  border-radius: 50%;
}

.container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: inherit;
  content: '';
}

.container.is-rainbow:after {
  background: conic-gradient(
    var(--color-red),
    var(--color-orange),
    var(--color-green),
    var(--color-teal),
    var(--color-purple),
    var(--color-pink),
    var(--color-red)
  );
}

@-moz-document url-prefix() {
  .container.is-rainbow:after {
    background: linear-gradient(
      var(--color-red),
      var(--color-orange),
      var(--color-green),
      var(--color-teal),
      var(--color-purple),
      var(--color-pink)
    );
  }
}

.content {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: 2;
  background: var(--color-black);
  border-radius: inherit;
  overflow: hidden;
}

.content img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
