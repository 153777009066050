.modalInputContainer {
  margin-top: 18px;
  display: block;
}

.modalName {
  text-align: center;
  color: var(--color-red);
}

.modalLabel {
  margin-bottom: 9px;
  display: block;
  font-size: 18px;
  color: var(--color-dashboard-main);
}

.shareLink input {
  color: var(--color-teal);
  font-weight: bold;
}
