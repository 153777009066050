.popper {
  align-items: center;
  border-radius: 10px;
  background: rgb(var(--color-theme-base));
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  min-width: 300px;
  padding: 12px 0;
  z-index: 9999;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  margin-bottom: 12px;
  max-height: 70vh;
  overflow: auto;
  min-width: 100%;
}

.select-sheet-button {
  border-radius: 10px 0 0 10px;
  width: 36px;
}

.collection {
  padding: 0 12px;
  width: 100%;

  .title {
    color: rgb(var(--color-theme-text));
    font-family: var(--font-alphapipe);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .list {
    background: rgb(var(--color-theme-button));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    padding: 4px;
  }

  .listItem {
    align-items: center;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 6px;
    transition: background-color 0.15s ease-in-out;

    &:focus,
    &:hover {
      background-color: rgb(var(--color-theme-accent));

      .sheetOwner,
      .sheetStatus {
        color: var(--color-white);
      }
    }
  }

  .sheetInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 12px;
    gap: 2px;
  }

  .sheetName {
    color: var(--color-white);
    font-size: 14px;
    font-weight: bold;
  }

  .sheetOwner,
  .sheetStatus {
    color: rgb(var(--color-theme-text));
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out;
  }

  .sheetStatus {
    margin-right: 6px;
  }

  .selected {
    align-items: center;
    background: rgb(255 255 255 / 0.3);
    border-radius: 50%;
    color: white;
    display: flex;
    height: 20px;
    justify-content: center;
    justify-self: end;
    width: 20px;

    svg {
      fill: var(--color-white);
    }
  }
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 24px;
  justify-content: space-around;
  max-width: 250px;
  padding: 24px 0;
  text-align: center;
}

.label {
  margin-right: 9px;
  display: inline-block;
  color: var(--color-dashboard-mid);
  font-size: 16px;
  font-weight: bold;
}

.select {
  display: inline-block;
}
