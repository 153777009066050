.container {
  transition: width 0.2s, min-width 0.2s;
  overflow: hidden;
}

.preview {
  background: transparent;
}

.preview .video {
  object-fit: cover;
}

.video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.videoWrapper {
  position: absolute;
  inset: 0;
}
